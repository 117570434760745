<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";

import type { PaymentMethod } from "@/entities/payment-method";
import { Caption, Divider } from "@/shared/ui-v2";

interface Props {
  name: PaymentMethod["name"];
}

const props = defineProps<Props>();

const { t, te } = useI18n();

const instruction = computed(() => {
  const specificTranslationKey = `hostToHost.invoiceByMethods.${props.name}.instruction`;

  if (te(specificTranslationKey)) {
    return t(specificTranslationKey);
  }

  return t("hostToHost.invoice.instruction", { wallet: props.name });
});
</script>

<template>
  <div :class="$style.root">
    <Caption color="tertiary">
      {{ instruction }}
    </Caption>
    <Divider />
  </div>
</template>

<style module lang="postcss">
.root {
  display: flex;
  flex-direction: column;
  text-align: start;
  gap: var(--spacing-16);
}
</style>
