import type { NavigationGuardNext, RouteLocationNormalized } from "vue-router";

export const performanceMarksMiddleware = (
  _to: RouteLocationNormalized,
  _from: RouteLocationNormalized,
  next: NavigationGuardNext,
) => {
  const markName = "routeStartLoading";
  performance.clearMarks(markName);

  performance.mark(markName);
  next();
};
