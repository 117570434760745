import { appStorage } from "@/shared/services";

class LoggerService {
  #storage = appStorage;

  log(message: string, ...params: unknown[]) {
    if (this.#storage.getForceLogging() !== "1") {
      return;
    }

    console.warn(message, ...params);
  }
}

export const loggerService = new LoggerService();
