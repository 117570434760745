import { currencies } from "@1win/frontend-currencies";

import type { User } from "@/entities/user";
import { cryptocurrencies } from "@/shared/constants";

const DEFAULT_DECIMALS = 2;

export const getCurrencyFractionDigits = (currency: User["currency"]) =>
  currencies[currency]?.decimals ?? DEFAULT_DECIMALS;

export const isCryptocurrency = (currency: User["currency"]) => cryptocurrencies.includes(currency);
