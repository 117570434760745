<script setup lang="ts">
import { computed } from "vue";
import type { Component } from "vue";

import type { FormField } from "@/entities/payment-method";
import { PhoneCodeSelect } from "@/features/form";
import { Input, Select } from "@/shared/ui";

interface Props {
  field: FormField;
}

const props = defineProps<Props>();

const components: Readonly<Record<FormField["type"], Nullable<Component>>> = {
  dropdown: Select,
  input_with_tooltip: Input,
  text: Input,
  networks: null,
};

const component = computed(() => {
  if (props.field.name === "phone") {
    return PhoneCodeSelect;
  }

  return components[props.field.type];
});
</script>

<template>
  <component
    :is="component"
    v-bind="field.props"
    v-on="field.events"
  />
</template>
