import type { PaymentMethod, PaymentMethodsPayload } from "@/entities/payment-method";
import { client } from "@/shared/api";

export const fetchPaymentMethods = async (payload: PaymentMethodsPayload) => {
  const { data } = await client.send<Dictionary<PaymentMethod>, PaymentMethodsPayload>(
    "BANKING:methods-payment-v3",
    payload,
  );
  return data;
};

export const fetchWithdrawalMethods = async (payload: PaymentMethodsPayload) => {
  const { data } = await client.send<Dictionary<PaymentMethod>, PaymentMethodsPayload>(
    "BANKING:methods-withdrawal",
    payload,
  );
  return data;
};
