<script setup lang="ts">
import type { PaymentGroup, PaymentMethod } from "@/entities/payment-method";
import { PaymentGroupTileV2, PaymentMethodTileV2 } from "@/entities/payment-method";
import { SectionGrid } from "@/entities/section";
import type { Transaction } from "@/entities/transaction";

type Emits = {
  selectPaymentGroup: [paymentGroup: PaymentGroup];
  selectPaymentMethod: [paymentMethod: PaymentMethod];
};

interface Props {
  paymentGroup: Nullable<PaymentGroup>;
  paymentGroups: PaymentGroup[];
  paymentMethod: Nullable<PaymentMethod>;
  paymentMethods: PaymentMethod[];
  transaction: Transaction;
}

const emit = defineEmits<Emits>();

defineProps<Props>();

const onSelectPaymentGroup = (paymentGroup: PaymentGroup) => emit("selectPaymentGroup", paymentGroup);
const onSelectPaymentMethod = (paymentMethod: PaymentMethod) => emit("selectPaymentMethod", paymentMethod);
</script>

<template>
  <SectionGrid>
    <PaymentGroupTileV2
      v-for="group of paymentGroups"
      :key="group.name"
      :class="$style.tile"
      :payment-group="group"
      :transaction="transaction"
      @change="onSelectPaymentGroup"
    />
    <PaymentMethodTileV2
      v-for="method of paymentMethods"
      :key="method.name"
      :class="$style.tile"
      :payment-method="method"
      :transaction="transaction"
      @change="onSelectPaymentMethod"
    />
  </SectionGrid>
</template>

<style module lang="postcss">
.tile {
  margin: var(--spacing-4);
}
</style>
