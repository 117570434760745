<script setup lang="ts">
import { transitions } from "@/shared/constants";
import { Overlay } from "@/shared/ui";

type Emits = {
  close: [];
};

interface Props {
  isOpen?: boolean;
}

interface Slots {
  default(props: object): unknown;
}

const emit = defineEmits<Emits>();

withDefaults(defineProps<Props>(), {
  isOpen: false,
});

defineSlots<Slots>();

const onClose = () => emit("close");
</script>

<template>
  <Teleport to="body">
    <Transition :name="transitions.fade">
      <Overlay
        v-if="isOpen"
        @close="onClose"
      />
    </Transition>
    <Transition :name="transitions.scale">
      <div
        v-if="isOpen"
        class="fixed left-1/2 top-1/2 z-30 -translate-x-1/2 -translate-y-1/2 transform rounded-2xl bg-white dark:bg-black"
      >
        <slot />
      </div>
    </Transition>
  </Teleport>
</template>
