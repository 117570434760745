<script setup lang="ts">
import { storeToRefs } from "pinia";
import { onBeforeMount } from "vue";

import { SectionButton } from "@/entities/section";
import { useFormStore } from "@/features/form";

type Emits = {
  click: [];
};

interface Props {
  isDisabled?: boolean;
  isLoading?: boolean;
}

interface Slots {
  default(props: object): unknown;
}

const emit = defineEmits<Emits>();

defineProps<Props>();

defineSlots<Slots>();

const formStore = useFormStore();
const { isFormValid, isFieldsValid, isSomeFieldTriggered } = storeToRefs(formStore);
const { changeFormValidity } = formStore;

const onClick = () => emit("click");

onBeforeMount(() => changeFormValidity(true));
</script>

<template>
  <SectionButton
    :is-disabled="isDisabled || (!isFieldsValid && isSomeFieldTriggered) || (!isFormValid && !isFieldsValid)"
    :is-loading="isLoading"
    @click="onClick"
  >
    <slot />
  </SectionButton>
</template>
