import { defineStore } from "pinia";
import { ref } from "vue";

import type { Offer } from "@/entities/offer";

const NAMESPACE = "offer";

export const useOfferStore = defineStore(NAMESPACE, () => {
  const offer = ref<Nullable<Offer>>(null);

  const changeOffer = (data: Offer) => {
    offer.value = data;
  };

  return {
    offer,
    changeOffer,
  };
});
