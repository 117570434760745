import type { Ref } from "vue";
import { computed } from "vue";

import { i18n } from "@/app/plugins";
import type { Transaction, TransactionDetailData, TransactionStatusKey } from "@/entities/transaction";
import type { User } from "@/entities/user";
import { formatNumber } from "@/shared/lib";

interface Props {
  amount: Ref<string>;
  isLegacyDesign: Ref<boolean>;
  transaction: Ref<Transaction>;
  transactionStatus: Ref<TransactionStatusKey>;
  user: Ref<User>;
}

export const useTransaction = (props: Props) => {
  const datetime = computed(() =>
    Intl.DateTimeFormat(i18n.global.locale as never, {
      hour12: false,
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    })
      .format(new Date())
      .split(","),
  );

  const details = computed<TransactionDetailData[]>(() => {
    const [date = "", time = ""] = datetime.value;
    return [
      {
        isVisible: true,
        name: i18n.global.t("transaction.details.amount"),
        text: formattedAmount.value,
      },
      {
        isVisible: true,
        name: i18n.global.t("transaction.details.date"),
        text: date,
      },
      {
        isVisible: true,
        name: i18n.global.t("transaction.details.time"),
        text: time,
      },
      {
        isVisible: props.transaction.value === "deposit" && props.transactionStatus.value === "accepted",
        name: i18n.global.t("transaction.details.timeForReplenishment.name"),
        text: i18n.global.t("transaction.details.timeForReplenishment.text"),
      },
    ];
  });

  const formattedAmount = computed(() =>
    formatNumber(
      {
        currency: props.user.value.currency,
      },
      +props.amount.value,
      props.isLegacyDesign.value ? undefined : props.user.value.lang,
    ),
  );

  return {
    datetime,
    details,
    formattedAmount,
  };
};
