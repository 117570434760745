<script setup lang="ts">
import type { CssClass } from "@/shared/ui-v2";
import { Spinner } from "@/shared/ui-v2";

type Emits = {
  click: [];
};

interface Props {
  color?: "accent" | "neutral" | "primary";
  isDisabled?: boolean;
  isLoading?: boolean;
  radius?: "inherit" | "max";
  size?: "s" | "m" | "xl";
  variant?: "plain" | "solid";
}

interface Slots {
  default(props: object): unknown;
}

const emit = defineEmits<Emits>();

withDefaults(defineProps<Props>(), {
  color: "primary",
  isDisabled: false,
  isLoading: false,
  radius: "inherit",
  size: "xl",
  variant: "solid",
});

defineSlots<Slots>();

const colors: Record<NotUndefined<Props["color"]>, CssClass> = {
  accent: "color-accent",
  neutral: "color-neutral",
  primary: "color-primary",
};

const radiuses: Record<NotUndefined<Props["radius"]>, CssClass> = {
  inherit: "",
  max: "rounding-max",
};

const sizes: Record<NotUndefined<Props["size"]>, CssClass> = {
  s: "size-s",
  m: "size-m",
  xl: "size-xl",
};

const onClick = () => emit("click");
</script>

<template>
  <button
    :class="[
      $style.root,
      $style[`${variant}-${colors[color]}`],
      $style[`${variant}-${sizes[size]}`],
      radiuses[radius] && $style[radiuses[radius]],
    ]"
    :disabled="isDisabled || isLoading"
    type="button"
    @click="onClick"
  >
    <Spinner
      v-if="isLoading"
      color="inherit"
      size="full"
    />
    <slot v-else />
  </button>
</template>

<style module lang="postcss">
.root {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-12);
  transition:
    outline-color 0.25s,
    background-color 0.25s,
    color 0.25s;
  outline: transparent solid var(--spacing-2);
  user-select: none;
  -webkit-tap-highlight-color: transparent;

  &:active {
    opacity: var(--opacity-item-active);
  }

  &:disabled {
    opacity: var(--opacity-item-disabled);
  }

  &:focus-visible {
    outline: var(--color-border-focus) solid var(--size-border-focus);
    outline-offset: var(--spacing-2);
  }
}

.plain-color-accent {
  color: var(--color-brand-accent);

  &:hover:not(:disabled) {
    color: var(--color-brand-accent-hover);
  }
}

.plain-color-neutral {
  color: var(--color-control-neutral);

  &:hover:not(:disabled) {
    color: var(--color-control-neutral-hover);
  }
}

.plain-color-primary {
  color: var(--color-brand-primary);

  &:hover:not(:disabled) {
    color: var(--color-brand-primary-hover);
  }
}

.plain-size-s,
.plain-size-m,
.plain-size-xl {
  @add-mixin body-strong;
}

.solid-color-accent {
  background-color: var(--color-brand-accent) !important;
  color: var(--color-fg-brand-accent);

  &:hover:not(:disabled) {
    background-color: var(--color-brand-accent-hover) !important;
  }
}

.solid-color-neutral {
  background-color: var(--color-control-neutral) !important;
  color: var(--color-fg-primary);

  &:hover:not(:disabled) {
    background-color: var(--color-control-neutral-hover) !important;
  }
}

.solid-color-primary {
  background-color: var(--color-brand-primary) !important;
  color: var(--color-fg-brand-primary);

  &:hover:not(:disabled) {
    background-color: var(--color-brand-primary-hover) !important;
  }
}

.solid-size-s {
  @add-mixin subhead-strong;

  height: var(--size-button-s);
  padding: var(--spacing-8) var(--spacing-12);
  border-radius: var(--rounding-button-s);
}

.solid-size-m {
  @add-mixin body-strong;

  height: var(--size-button-m);
  padding: var(--spacing-8) var(--spacing-20);
  border-radius: var(--rounding-button-m);
}

.solid-size-xl {
  @add-mixin body-strong;

  height: var(--size-button-xl);
  padding: var(--spacing-12) var(--spacing-20);
  border-radius: var(--rounding-button-xl);
}

.rounding-max {
  border-radius: var(--rounding-max);
}
</style>
