<script setup lang="ts">
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";

import { usePaymentMethodStore } from "@/entities/payment-method";
import { SectionWrapper } from "@/entities/section";
import { useWithdrawalStore } from "@/entities/withdrawal";
import { SubmitButton } from "@/features/form";
import { ScrollShadow } from "@/shared/ui";
import { WithdrawalForm, WithdrawalInfo } from "@/widgets/withdrawal";

const { t } = useI18n();

const { paymentLabel, paymentMethod } = storeToRefs(usePaymentMethodStore());

const withdrawalStore = useWithdrawalStore();
const { isLoadingWithdrawal } = storeToRefs(withdrawalStore);
const { onSubmit } = withdrawalStore;
</script>

<template>
  <SectionWrapper>
    <ScrollShadow class="flex grow flex-col gap-6">
      <WithdrawalInfo :icon="paymentMethod?.name">
        <template #text>
          {{ paymentLabel }}
        </template>
      </WithdrawalInfo>
      <WithdrawalForm />
    </ScrollShadow>
    <SubmitButton
      class="mt-auto"
      :is-loading="isLoadingWithdrawal"
      @click="onSubmit"
    >
      {{ t("withdrawal.button") }}
    </SubmitButton>
  </SectionWrapper>
</template>
