<script setup lang="ts">
import { storeToRefs } from "pinia";
import { computed } from "vue";
import { useRouter } from "vue-router";

import type { PaymentMethod } from "@/entities/payment-method";
import { usePaymentMethodStore } from "@/entities/payment-method";
import { SectionWrapper } from "@/entities/section";
import type { Snippet } from "@/entities/user";
import { useUserStore } from "@/entities/user";
import { getAmount, useFormStore } from "@/features/form";
import { useSearch } from "@/features/search";
import { routeNames } from "@/shared/constants";
import { ScrollShadow } from "@/shared/ui";
import { DepositInfo, DepositSearch } from "@/widgets/deposit";

const router = useRouter();

const paymentMethodStore = usePaymentMethodStore();
const { paymentGroup, limits, sortedPaymentMethods } = storeToRefs(paymentMethodStore);
const { changePaymentMethod } = paymentMethodStore;

const { country, snippet } = storeToRefs(useUserStore());

const { changeAmount, changeFields } = useFormStore();

const { isSearchActive, search, cancelSearch, changeSearch, setIsSearchActive } = useSearch();

const searchedMethods = computed(() =>
  sortedPaymentMethods.value.filter(({ text }) =>
    (text[country.value] ?? text.en ?? "").toLowerCase().includes(search.value.toLowerCase()),
  ),
);

const selectPaymentMethod = async (paymentMethod: PaymentMethod) => {
  changePaymentMethod(paymentMethod);
  changeFields(paymentMethod.fields);
  const amount = getAmount(snippet.value as Snippet, limits.value);
  changeAmount(amount);
  setIsSearchActive(false);
  await router.push({ name: routeNames.depositCryptoNetworkPage });
};
</script>

<template>
  <SectionWrapper>
    <ScrollShadow class="flex grow flex-col gap-6">
      <DepositInfo
        v-if="!isSearchActive"
        :icon="paymentGroup?.name"
      >
        <template #text>
          {{ paymentGroup?.label ?? "" }}
        </template>
      </DepositInfo>
      <DepositSearch
        :is-search-active="isSearchActive"
        :payment-method="null"
        :search="search"
        :searched-methods="searchedMethods"
        @cancel-search="cancelSearch"
        @change-search="changeSearch"
        @select-payment-method="selectPaymentMethod"
        @set-is-search-active="setIsSearchActive"
      />
    </ScrollShadow>
  </SectionWrapper>
</template>
