import type { User } from "@/entities/user";
import { escapeRegExp } from "@/shared/lib";

export const useIntlSeparators = (lang: User["lang"], currency: User["currency"]) => {
  const STUB_NUMBER_WITH_SEPARATOR = 1000.1;
  const formattedParts = Intl.NumberFormat(lang, {
    currencyDisplay: "narrowSymbol",
    style: "currency",
    currency,
  }).formatToParts(STUB_NUMBER_WITH_SEPARATOR);

  const { currencySeparator, decimalSeparator, groupSeparator } = formattedParts.reduce(
    (acc, { type, value }) => {
      if (type === "decimal") {
        Object.assign(acc, { decimalSeparator: value });
      }

      if (type === "group") {
        Object.assign(acc, { groupSeparator: value });
      }

      if (type === "currency") {
        Object.assign(acc, { currencySeparator: value });
      }

      return acc;
    },
    {
      currencySeparator: "",
      decimalSeparator: "",
      groupSeparator: "",
    },
  );

  const isCurrencyAtEnd = () => formattedParts.at(-1)?.type === "currency";

  const isCorrectAmountWithSeparators = (max: number, amount: string, isFullDecimalPart: boolean) =>
    (isFullDecimalPart
      ? new RegExp(
          `^(${escapeRegExp(currencySeparator)})?(\\s)?(\\d+((${escapeRegExp(groupSeparator)}\\d+)?)+)?(${escapeRegExp(
            decimalSeparator,
          )}\\d{0,${max}})?(\\s${escapeRegExp(currencySeparator)})?$`,
        )
      : new RegExp(
          `^(${escapeRegExp(currencySeparator)})?(\\s)?(\\d+((${escapeRegExp(groupSeparator)}\\d+)?)+)?(${escapeRegExp(
            decimalSeparator,
          )}\\d{0,${max}})?(\\s${escapeRegExp(currencySeparator)})?(${escapeRegExp(decimalSeparator)}?\\d{0,${max}})$`,
        )
    ).test(amount);

  return {
    decimalSeparator,
    groupSeparator,
    currencySeparator,
    isCurrencyAtEnd,
    isCorrectAmountWithSeparators,
  };
};
