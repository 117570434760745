<script setup lang="ts">
import { computed, h } from "vue";
import { useI18n } from "vue-i18n";

import type { HostToHostApiResponse } from "@/entities/deposit";
import { InvoiceData } from "@/entities/invoice";
import type { User } from "@/entities/user";
import { copyToClipboard, formatCardNumber, formatNumber } from "@/shared/lib";
import { IconBox, IconContainer } from "@/shared/ui-v2";

interface Props {
  currency: User["currency"];
  data: HostToHostApiResponse;
  isVirtualAccount: boolean;
  lang: User["lang"];
}

const props = defineProps<Props>();

const { t, te } = useI18n();

const requisites = computed(() => {
  const translationKey = `hostToHost.invoiceByMethods.${props.data.payment_system}.fields.requisites`;

  if (te(translationKey)) {
    return t(translationKey);
  }

  return t("hostToHost.invoice.fields.requisites");
});

const fields = computed(() => [
  {
    icon: {
      component: h(IconBox),
      props: {
        name: `currencies/${(props.data.payment_currency ?? props.currency).toLowerCase()}`,
        size: "l",
        space: false,
      },
    },
    isCopyable: true,
    text: t("hostToHost.invoice.fields.amount"),
    value: formatNumber(
      { currency: props.data.payment_currency ?? props.currency },
      +props.data.payment_amount,
      props.lang,
    ),
    onCopy: () => copyToClipboard(props.data.payment_amount),
  },
  {
    icon: {
      component: h(IconContainer),
      props: {
        color: "secondary",
        name: "withdraw",
        size: "xs",
      },
    },
    isCopyable: true,
    text: requisites.value,
    value:
      (formatCardNumber(props.data.card?.number ?? props.data.card?.account_number ?? "") || props.data.requisites) ??
      "",
    onCopy: () =>
      copyToClipboard(props.data.card?.number ?? props.data.card?.account_number ?? props.data.requisites ?? ""),
  },
  {
    icon: {
      component: h(IconContainer),
      props: {
        color: "secondary",
        name: "user",
        size: "xs",
      },
    },
    isCopyable: false,
    text: t("hostToHost.invoice.fields.fullName"),
    value: props.data.card?.fullName,
  },
  ...(props.isVirtualAccount
    ? [
        {
          icon: {
            component: h(IconContainer),
            props: {
              color: "secondary",
              name: "bank-card",
              size: "xs",
            },
          },
          isCopyable: false,
          text: t("hostToHost.invoice.fields.bankName"),
          value: "Virtual bank",
        },
      ]
    : []),
]);
</script>

<template>
  <template
    v-for="(field, index) of fields"
    :key="field.value"
  >
    <InvoiceData
      v-if="Boolean(field.value)"
      :icon="field.icon"
      :is-copyable="field.isCopyable"
      :text="field.text"
      :value="field.value"
      :with-divider="index !== 0"
      @copy="field.onCopy"
    />
  </template>
</template>
