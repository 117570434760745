<script setup lang="ts">
import { transitions } from "@/shared/constants";
import type { Color, TailwindClass } from "@/shared/ui";

interface Props {
  color?: Color;
  customClass?: TailwindClass;
  isVisible?: boolean;
}

interface Slots {
  content(props: object): unknown;
  default(props: object): unknown;
}

withDefaults(defineProps<Props>(), {
  color: "primary",
  customClass: "",
  isVisible: true,
});

defineSlots<Slots>();

const colors: Record<Color, TailwindClass> = {
  auto: "",
  danger: "bg-orange-525 dark:bg-ruby",
  primary: "bg-mint-700 dark:bg-sage",
  secondary: "bg-blue-cyan-500 dark:bg-sapphire",
  warning: "bg-yellow-amber-500 dark:bg-gold",
};
</script>

<template>
  <div class="relative z-10">
    <Transition :name="transitions.slideDown">
      <span
        v-if="isVisible"
        class="absolute -right-1 -top-1 z-20 flex h-6 select-none items-center justify-center gap-0.5 whitespace-nowrap rounded-5.25xl px-2 py-1 text-2sm font-semibold leading-5.5 text-white shadow-md"
        :class="[colors[color], customClass]"
        data-testid="content"
      >
        <slot name="content" />
      </span>
    </Transition>
    <slot name="default" />
  </div>
</template>
