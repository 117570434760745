<script setup lang="ts">
import type { CssClass, TextVariant } from "@/shared/ui-v2";

interface Props {
  color?: "inherit" | "primary";
  size?: "m" | "l";
}

interface Slots {
  default(props: object): unknown;
}

withDefaults(defineProps<Props>(), {
  color: "inherit",
  size: "m",
});

defineSlots<Slots>();

const colors: Record<NotUndefined<Props["color"]>, CssClass> = {
  inherit: "",
  primary: "color-primary",
};

const sizes: Record<NotUndefined<Props["size"]>, CssClass> = {
  m: "title-m",
  l: "title-l",
};

const variants: Record<NotUndefined<Props["size"]>, TextVariant> = {
  m: "h3",
  l: "h2",
};
</script>

<template>
  <component
    :is="variants[size]"
    :class="[$style[colors[color]], $style[sizes[size]]]"
  >
    <slot />
  </component>
</template>

<style module lang="postcss">
.color-primary {
  color: var(--color-fg-primary);
}

.title-m {
  @add-mixin title-m;
}

.title-l {
  @add-mixin title-l;
}
</style>
