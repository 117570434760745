<script setup lang="ts">
import { storeToRefs } from "pinia";

import { useUserStore } from "@/entities/user";
import { Button } from "@/shared/ui";
import type { TransactionContent } from "@/widgets/transaction";

interface Props {
  content: TransactionContent;
}

defineProps<Props>();

const { user } = storeToRefs(useUserStore());
</script>

<template>
  <div
    class="flex flex-col gap-2"
    :class="[...(user.platform === 'ios' ? ['mb-6'] : [])]"
  >
    <Button
      color="secondary"
      @click="content.mainButton.callback"
    >
      {{ content.mainButton.text }}
    </Button>
    <Button
      v-if="content.additionalButton"
      color="auto"
      variant="bordered"
      @click="content.additionalButton?.callback"
    >
      {{ content.additionalButton?.text }}
    </Button>
  </div>
</template>
