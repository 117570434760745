<script setup lang="ts">
import { storeToRefs } from "pinia";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

import { useDepositStore } from "@/entities/deposit";
import type { PaymentMethodNetwork } from "@/entities/payment-method";
import { usePaymentMethodStore } from "@/entities/payment-method";
import { SectionButton, SectionWrapper } from "@/entities/section";
import { useFormStore } from "@/features/form";
import { transitions, routeNames } from "@/shared/constants";
import { Alert, ScrollShadow } from "@/shared/ui";
import { CryptoNetworks } from "@/widgets/crypto";
import { DepositInfo } from "@/widgets/deposit";

const { t } = useI18n();

const depositStore = useDepositStore();
const { isLoadingDeposit } = storeToRefs(depositStore);
const { onSubmit } = depositStore;

const { paymentLabel, paymentMethod } = storeToRefs(usePaymentMethodStore());

const { validationState } = storeToRefs(useFormStore());

const networks = computed(() => {
  const values = (paymentMethod.value?.fields?.find((field) => field.name === "network")?.values ??
    []) as PaymentMethodNetwork[];

  if (values.length) {
    return values;
  }

  return paymentMethod.value?.network ? [paymentMethod.value.network] : [];
});
</script>

<template>
  <SectionWrapper>
    <ScrollShadow class="flex grow flex-col gap-6">
      <DepositInfo
        :icon="paymentMethod?.name"
        :path="routeNames.depositCryptoCoinPage"
      >
        <template #text>
          {{ paymentLabel }}
        </template>
      </DepositInfo>
      <Alert type="warning">
        <template #title>
          {{ t("deposit.alert.warning") }}
        </template>
        <template #text>
          {{ t("deposit.crypto.network.text") }}
        </template>
      </Alert>
      <CryptoNetworks :networks="networks" />
    </ScrollShadow>
    <Transition :name="transitions.slideDown">
      <SectionButton
        v-if="validationState.network"
        class="mt-auto"
        full-width
        :is-loading="isLoadingDeposit"
        @click="onSubmit({ isNewFlow: true }, routeNames.depositCryptoAddressPage)"
      >
        {{ t("button.continue") }}
      </SectionButton>
    </Transition>
  </SectionWrapper>
</template>
