<script setup lang="ts">
import { storeToRefs } from "pinia";
import { computed, onBeforeMount } from "vue";

import type { FormField } from "@/entities/payment-method";
import { cardViews, usePaymentMethodStore } from "@/entities/payment-method";
import { DynamicFieldV2, useFormStore, NIGERIAN_BANK_FIELD } from "@/features/form";

const { paymentMethod } = storeToRefs(usePaymentMethodStore());

const formStore = useFormStore();
const { fields, errorRefs } = storeToRefs(formStore);
const { setErrorRefs } = formStore;

const addRef = (el: HTMLDivElement, field: FormField) => {
  const isExists = errorRefs.value.some((item) => item.name === field.name);

  if (isExists) {
    return;
  }

  setErrorRefs([
    ...errorRefs.value,
    {
      el,
      hasError: false,
      name: field.name,
    },
  ]);
};

const filteredFields = computed(() =>
  fields.value.filter(
    (field) => !(paymentMethod.value?.cardViewType === cardViews.bankTransfer && field.name === NIGERIAN_BANK_FIELD),
  ),
);

onBeforeMount(() => setErrorRefs([]));
</script>

<template>
  <div :class="$style.root">
    <div
      v-for="field of filteredFields"
      :key="field.name"
      :ref="(el) => addRef(el as HTMLDivElement, field)"
    >
      <DynamicFieldV2 :field="field" />
    </div>
  </div>
</template>

<style module lang="postcss">
.root {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-16);
}
</style>
