<script setup lang="ts">
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

import { useDepositStore } from "@/entities/deposit";
import { PaymentHeader, usePaymentMethodStore, usePaymentInstruction } from "@/entities/payment-method";
import { SectionWrapperV2 } from "@/entities/section";
import { SubmitButtonV2 } from "@/features/form";
import { routeNamesV2 } from "@/shared/constants";
import { ScrollShadow } from "@/shared/ui";
import { Button, IconBox } from "@/shared/ui-v2";
import { DepositFormV2 } from "@/widgets/deposit";

const { t } = useI18n();
const router = useRouter();

const depositStore = useDepositStore();
const { isLoadingDeposit } = storeToRefs(depositStore);
const { onSubmit } = depositStore;

const { paymentLabel, paymentMethod } = storeToRefs(usePaymentMethodStore());

const { instruction } = usePaymentInstruction({ paymentMethod });
</script>

<template>
  <SectionWrapperV2>
    <ScrollShadow :class="$style.container">
      <PaymentHeader
        :class="$style.space"
        :name="paymentMethod?.name"
        transaction="deposit"
      >
        {{ paymentLabel }}
      </PaymentHeader>
      <DepositFormV2 :class="$style.space" />
    </ScrollShadow>
    <div :class="[$style.wrapper, $style.space]">
      <SubmitButtonV2
        color="accent"
        :is-loading="isLoadingDeposit"
        @click="onSubmit({}, routeNamesV2.depositCompletePage)"
      >
        {{ t("deposit.button") }}
      </SubmitButtonV2>
      <Button
        v-if="instruction"
        color="neutral"
        @click="router.push({ name: routeNamesV2.depositRedirectInstructionPage })"
      >
        <IconBox
          color="tertiary"
          name="circle-warning"
        />
        {{ t("paymentInstruction.button") }}
      </Button>
    </div>
  </SectionWrapperV2>
</template>

<style module lang="postcss">
.container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-16);
}

.space {
  margin: var(--spacing-0) var(--spacing-4);
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-8);
}
</style>
