<script setup lang="ts">
type Emits = {
  close: [];
};

interface Slots {
  default(props: object): unknown;
}

const emit = defineEmits<Emits>();

defineSlots<Slots>();

const onClose = () => emit("close");
</script>

<template>
  <div
    class="fixed inset-0 z-20 bg-black/[.6]"
    @click="onClose"
  >
    <slot />
  </div>
</template>
