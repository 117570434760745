<script setup lang="ts">
import type { Color, HexColor, TailwindClass } from "@/shared/ui";

interface Props {
  backgroundColor?: HexColor;
  color?: Color;
}

withDefaults(defineProps<Props>(), {
  backgroundColor: "#EBF4FF",
  color: "primary",
});

const colors: Record<NotUndefined<Props["color"]>, TailwindClass> = {
  auto: "",
  danger: "text-orange-525 dark:text-ruby",
  primary: "text-mint-700 dark:text-sage",
  secondary: "text-blue-cyan-500 dark:text-sapphire",
  warning: "text-yellow-amber-500 dark:text-gold",
};
</script>

<template>
  <svg
    class="max-w-10 h-full max-h-10 w-fit animate-spin"
    :class="[colors[color]]"
    fill="none"
    viewBox="0 0 40 40"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M40 20C40 31.0457 31.0457 40 20 40C8.9543 40 0 31.0457 0 20C0 8.9543 8.9543 0 20 0C31.0457 0 40 8.9543 40 20ZM5.4 20C5.4 28.0634 11.9366 34.6 20 34.6C28.0634 34.6 34.6 28.0634 34.6 20C34.6 11.9366 28.0634 5.4 20 5.4C11.9366 5.4 5.4 11.9366 5.4 20Z"
      :fill="backgroundColor"
    />
    <path
      d="M20 2.7C20 1.20883 21.2143 -0.0187366 22.6919 0.181967C25.6926 0.58956 28.574 1.67514 31.1114 3.37061C34.4004 5.56824 36.9638 8.69181 38.4776 12.3463C39.9913 16.0009 40.3874 20.0222 39.6157 23.9018C38.844 27.7814 36.9392 31.3451 34.1421 34.1421C31.3451 36.9392 27.7814 38.844 23.9018 39.6157C20.0222 40.3874 16.0009 39.9913 12.3463 38.4776C8.69181 36.9638 5.56824 34.4004 3.37061 31.1114C1.67514 28.574 0.58956 25.6926 0.181967 22.6919C-0.0187366 21.2143 1.20883 20 2.7 20C4.19117 20 5.37474 21.2189 5.64895 22.6847C6.01014 24.6154 6.75902 26.4628 7.86054 28.1113C9.46481 30.5123 11.745 32.3836 14.4128 33.4886C17.0806 34.5937 20.0162 34.8828 22.8483 34.3195C25.6804 33.7561 28.2819 32.3656 30.3238 30.3238C32.3656 28.2819 33.7561 25.6804 34.3195 22.8483C34.8828 20.0162 34.5937 17.0806 33.4886 14.4128C32.3836 11.745 30.5123 9.46481 28.1113 7.86054C26.4628 6.75902 24.6154 6.01014 22.6847 5.64895C21.2189 5.37474 20 4.19117 20 2.7Z"
      fill="currentColor"
    />
  </svg>
</template>
