<script setup lang="ts">
import { CheckMark, Paragraph } from "@/shared/ui-v2";

type Emits = {
  change: [];
};

interface Props {
  item: {
    text: string;
    value: string | number;
  };
  value: string | number;
}

const emit = defineEmits<Emits>();

defineProps<Props>();

const onChange = () => emit("change");
</script>

<template>
  <button
    :class="$style.root"
    type="button"
    @click="onChange"
  >
    <span :class="$style.container">
      <slot>
        <Paragraph color="primary">{{ item.text }}</Paragraph>
      </slot>
    </span>
    <CheckMark v-if="item.value === value" />
  </button>
</template>

<style module lang="postcss">
.root {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: var(--spacing-12);
  transition: background-color 0.25s;
  user-select: none;
  -webkit-tap-highlight-color: transparent;

  &:active {
    opacity: var(--opacity-item-active);
  }

  &:hover {
    background-color: var(--color-neutral-ghost-hover);
  }
}

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-4);
}
</style>
