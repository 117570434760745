<script setup lang="ts">
import { storeToRefs } from "pinia";
import { onBeforeMount } from "vue";

import { SectionButtonV2 } from "@/entities/section";
import { useFormStore } from "@/features/form";

type Emits = {
  click: [];
};

interface Props {
  color?: "accent" | "neutral" | "primary";
  isDisabled?: boolean;
  isLoading?: boolean;
}

interface Slots {
  default(props: object): unknown;
}

const emit = defineEmits<Emits>();

withDefaults(defineProps<Props>(), {
  color: "primary",
  isDisabled: false,
  isLoading: false,
});

defineSlots<Slots>();

const formStore = useFormStore();
const { isFormValid, isFieldsValid, isSomeFieldTriggered } = storeToRefs(formStore);
const { changeFormValidity } = formStore;

const onClick = () => emit("click");

onBeforeMount(() => changeFormValidity(true));
</script>

<template>
  <SectionButtonV2
    :color="color"
    :is-disabled="isDisabled || (!isFieldsValid && isSomeFieldTriggered) || (!isFormValid && !isFieldsValid)"
    :is-loading="isLoading"
    @click="onClick"
  >
    <slot />
  </SectionButtonV2>
</template>
