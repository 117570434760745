const modules = import.meta.glob("@/shared/assets/icons/**/*.svg", {
  import: "default",
  query: "?raw",
});

export const fetchIcon = async <T extends string>(name: string): Promise<T> => {
  try {
    const module = modules[`/src/shared/assets/icons/${name}.svg`];
    const promise = await module();
    return promise as Promise<T>;
  } catch (error) {
    throw new Error(`Unable to fetch icon: ${name}`);
  }
};
