import type { User } from "@/entities/user";
import type { LoggingOption } from "@/shared/services";
import { appStorage } from "@/shared/services";

type Command = "force" | "user";

const commands: Readonly<Record<Command, string>> = {
  force: "force",
  user: "user",
};

export default {
  install: () => {
    window.console = {
      ...window.console,
      [commands.force]: (value: LoggingOption) => {
        appStorage.setForceLogging(value);
        window.location.reload();
      },
      [commands.user]: (user: User) => {
        appStorage.setUser(user);
        window.location.reload();
      },
    };
  },
};
