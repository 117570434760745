import { defineStore, storeToRefs } from "pinia";
import { computed } from "vue";

import { countriesWithLegacyDesign, themeKeys } from "@/entities/theme";
import { useUserStore } from "@/entities/user";

const NAMESPACE = "theme";

export const useThemeStore = defineStore(NAMESPACE, () => {
  const { country, isNativePlatform } = storeToRefs(useUserStore());

  const isDarkTheme = computed(() => theme.value === themeKeys.dark);

  const isLegacyDesign = computed(() => countriesWithLegacyDesign.includes(country.value));

  const theme = computed(() => (isNativePlatform.value || isLegacyDesign.value ? themeKeys.dark : themeKeys.light));

  return {
    isDarkTheme,
    isLegacyDesign,
    theme,
  };
});
