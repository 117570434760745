<script setup lang="ts">
import { storeToRefs } from "pinia";
import { computed, onBeforeMount, ref } from "vue";
import { useI18n } from "vue-i18n";

import { useUserStore } from "@/entities/user";
import type { PhoneCountry } from "@/features/form";
import { countries } from "@/features/form";
import { SearchInput, useSearch } from "@/features/search";
import { transitions } from "@/shared/constants";
import { escapeRegExp } from "@/shared/lib";
import type { Validations } from "@/shared/ui";
import { Button, Divider, Icon, Input, Overlay, Typography } from "@/shared/ui";

type Emits = {
  change: [value: string];
  input: [event: Event & { target: HTMLInputElement }];
};

interface Props {
  isDisabled: boolean;
  label: string;
  validations?: Validations[];
  value: string;
}

const emit = defineEmits<Emits>();

const props = withDefaults(defineProps<Props>(), {
  validations: () => [
    {
      isExists: false,
      message: "",
      regex: "",
    },
  ],
});

const { t } = useI18n();

const { country } = storeToRefs(useUserStore());

const { isSearchActive, search, cancelSearch, changeSearch, setIsSearchActive } = useSearch();

const isCountriesListVisible = ref(false);
const phone = ref("");
const selectedCountry = ref(countries.find(({ code }) => code === country.value) ?? countries[0]);

const searchedCountries = computed(() => {
  const regexp = new RegExp(escapeRegExp(search.value.toLowerCase()));
  return search.value
    ? countries.filter(({ name, prefix, code }) => regexp.test(`${name} ${prefix} ${code}`.toLowerCase()))
    : [...countries];
});

const onChange = (value: string) => emit("change", value);

const changeCountry = (country: PhoneCountry) => {
  selectedCountry.value = country;
};

const changeCountriesListVisibility = (value: boolean) => {
  isCountriesListVisible.value = value;
};

const changePhone = (value: string) => {
  phone.value = value;
};

const handleInput = (event: Event) => {
  const {
    target: { value },
  } = event as Event & { target: HTMLInputElement };

  changePhone(value);

  if (!phone.value) {
    onChange("");
    return;
  }

  onChange(`${selectedCountry.value.prefix.slice(1)}${value}`);
};

const selectCountry = (country: PhoneCountry) => {
  changeCountry(country);
  changeCountriesListVisibility(false);

  if (!phone.value) {
    onChange("");
    return;
  }

  onChange(`${country.prefix.slice(1)}${phone.value}`);
};

onBeforeMount(() => {
  const country = countries.find((country) => props.value.includes(country.prefix));

  if (!country) {
    return;
  }

  changeCountry(country);
  changePhone(props.value.replace(country.prefix, ""));
});
</script>

<template>
  <div>
    <div class="flex gap-2">
      <Button
        class="highlight-none flex items-center gap-2 bg-gray-50 px-4 py-3.25 outline-none dark:bg-oxford"
        color="auto"
        size="auto"
        @click="changeCountriesListVisibility(true)"
      >
        <Icon
          :key="selectedCountry.code"
          class="h-5 w-5 transform transition"
          :name="`countries/${selectedCountry.code}`"
          radius="full"
        />
        <Typography
          size="lg"
          variant="span"
        >
          {{ selectedCountry.prefix }}
        </Typography>
        <Icon
          class="transform text-gray-350 transition dark:text-pearl"
          name="chevron-down"
        />
      </Button>
      <Input
        class="w-full"
        :has-error-message="false"
        input-mode="numeric"
        :placeholder="t('fields.phone.placeholder')"
        type="number"
        :validations="validations"
        :value="phone"
        @input="handleInput"
      />
    </div>
    <template
      v-for="validation of validations"
      :key="validation.message"
    >
      <Typography
        v-if="validation.isExists"
        class="mt-2"
        color="danger"
        size="sm"
      >
        {{ validation.message }}
      </Typography>
    </template>
    <Teleport to="body">
      <Transition :name="transitions.fade">
        <Overlay v-if="isCountriesListVisible" />
      </Transition>
      <Transition :name="transitions.slideTop">
        <div
          v-if="isCountriesListVisible"
          class="fixed bottom-0 z-20 h-[calc(100%-3rem)] w-full rounded-xl bg-white p-4 text-gray-900 dark:bg-onyx dark:text-white"
        >
          <div class="flex h-full flex-col items-start gap-6">
            <Button
              color="secondary"
              variant="plain"
              @click="changeCountriesListVisibility(false)"
            >
              {{ t("button.close") }}
            </Button>
            <Typography
              class="text-3xl"
              size="auto"
              variant="h2"
              weight="bold"
            >
              {{ t("selection.phone") }}
            </Typography>
            <SearchInput
              class="w-full"
              :is-search-active="isSearchActive"
              :items="searchedCountries"
              :search="search"
              @cancel="cancelSearch"
              @change="changeSearch"
              @focus="setIsSearchActive(true)"
              @input="(event) => changeSearch((event.target as HTMLInputElement).value)"
            />
            <div class="w-full overflow-y-auto rounded-2xl bg-gray-50 dark:bg-oxford">
              <Button
                v-for="(item, index) of searchedCountries"
                :key="item.code"
                color="auto"
                full-width
                :has-click-animation="false"
                size="auto"
                @click="selectCountry(item)"
              >
                <div class="flex p-4">
                  <Icon
                    class="h-6.5 w-6.5 transform transition"
                    :name="`countries/${item.code}`"
                    radius="full"
                  />
                </div>
                <div class="w-full">
                  <div class="flex items-center justify-between py-4 pr-4">
                    <div class="flex items-center gap-2 text-left">
                      <Typography
                        class="w-14 shrink-0"
                        size="lg"
                      >
                        {{ item.prefix }}
                      </Typography>
                      <Typography size="lg">
                        {{ item.name }}
                      </Typography>
                    </div>
                    <div
                      v-if="item.name === selectedCountry.name"
                      class="ml-4 flex h-5 w-5 shrink-0 items-center justify-center rounded-full bg-blue-cyan-500 dark:bg-sapphire"
                    >
                      <Icon
                        class="text-white"
                        name="check"
                      />
                    </div>
                  </div>
                  <Divider
                    v-if="index !== searchedCountries.length - 1"
                    class="bg-gray-225 dark:bg-shadow/[.65]"
                    size="sm"
                  />
                </div>
              </Button>
            </div>
          </div>
        </div>
      </Transition>
    </Teleport>
  </div>
</template>
