<script setup lang="ts">
import { useI18n } from "vue-i18n";

import type { CryptoApiResponse, Deposit } from "@/entities/deposit";
import { Paragraph } from "@/shared/ui-v2";

interface Props {
  response: Deposit["apiResponse"] & { data: CryptoApiResponse };
}

defineProps<Props>();

const { t } = useI18n();
</script>

<template>
  <div :class="$style.root">
    <Paragraph>
      {{ t("deposit.crypto.address.depositSum") }}
    </Paragraph>
    <Paragraph size="m-sb">{{ response.data.minDepositValue }} {{ response.data.cryptoName }}</Paragraph>
  </div>
</template>

<style module lang="postcss">
.root {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-8);
}
</style>
