import IMask from "imask";
import type { Ref } from "vue";
import { computed } from "vue";

import type { FormField } from "@/entities/payment-method";

interface Props {
  mask: Ref<FormField["props"]["mask"]>;
}

export const useMask = (props: Props) => {
  const masked = computed(() => {
    if (props.mask.value) {
      return IMask.createMask({ lazy: true, mask: props.mask.value });
    }

    return undefined;
  });

  const changeMaskedValue = (value: string) => {
    masked.value?.resolve(value);
  };

  return {
    masked,
    changeMaskedValue,
  };
};
