<script setup lang="ts">
import { storeToRefs } from "pinia";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

import type { CryptoApiResponse, Deposit } from "@/entities/deposit";
import { useDepositStore } from "@/entities/deposit";
import { QrCode } from "@/entities/qr-code";
import { SectionWrapper } from "@/entities/section";
import { routeNames } from "@/shared/constants";
import { Typography } from "@/shared/ui";
import { CryptoButtons, CryptoInfo } from "@/widgets/crypto";
import { DepositInfo } from "@/widgets/deposit";

const { t } = useI18n();

const { apiResponse } = storeToRefs(useDepositStore());

const response = computed(
  () => apiResponse.value as Omit<Deposit["apiResponse"], "data"> & { data: CryptoApiResponse },
);
</script>

<template>
  <SectionWrapper class="scrollbar-hidden overflow-y-auto">
    <DepositInfo
      :icon="response.data.group"
      :path="routeNames.depositCryptoNetworkPage"
    >
      <template #text>
        {{ response.data.cryptoName }}
        ({{ response.data.network }})
      </template>
    </DepositInfo>
    <div class="mx-auto max-w-[11.25rem] rounded-2xl bg-gray-50 p-2.5 dark:bg-white">
      <QrCode
        :options="{
          width: 140,
          height: 140,
        }"
        :text="response.data.depositAddress"
      />
    </div>
    <div class="text-center">
      <Typography
        class="text-xs text-gray-350 dark:text-pearl"
        size="auto"
      >
        {{ t("deposit.crypto.address.text") }}
      </Typography>
      <Typography
        class="break-words"
        size="lg"
      >
        {{ response.data.depositAddress }}
      </Typography>
    </div>
    <CryptoButtons :response="response" />
    <CryptoInfo :response="response" />
  </SectionWrapper>
</template>
