<script setup lang="ts">
import { useI18n } from "vue-i18n";

import { confirmationIdFieldRegex } from "@/entities/peer-to-peer";
import { Input } from "@/shared/ui-v2";

type Emits = {
  input: [value: string];
};

interface Props {
  isValid: boolean;
  value: string;
}

const emit = defineEmits<Emits>();

defineProps<Props>();

const { t } = useI18n();

const onInput = (event: Event) => emit("input", (event.target as HTMLInputElement).value);
</script>

<template>
  <Input
    :label="t('paymentMethods.placeholder.confirmationId')"
    :validations="[
      {
        isExists: !isValid,
        message: t('paymentMethods.regex_error.confirmationId'),
        regex: confirmationIdFieldRegex,
      },
    ]"
    :value="value"
    @input="onInput"
  />
</template>
