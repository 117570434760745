<script setup lang="ts">
import { storeToRefs } from "pinia";

import { usePaymentMethodStore } from "@/entities/payment-method";
import { useUserStore } from "@/entities/user";
import { WithdrawalRuleV2, useWithdrawalStore } from "@/entities/withdrawal";
import { AmountInputV2, FormFieldsV2, useFormStore } from "@/features/form";
import { transitions } from "@/shared/constants";

const { limits } = storeToRefs(usePaymentMethodStore());

const { country, user } = storeToRefs(useUserStore());

const { isRuleShown } = storeToRefs(useWithdrawalStore());

const formStore = useFormStore();
const { amount, fields } = storeToRefs(formStore);
const { changeAmount, changeAmountValidity } = formStore;
</script>

<template>
  <div :class="$style.root">
    <FormFieldsV2 v-if="fields.length" />
    <AmountInputV2
      :currency="user.currency"
      :limits="limits"
      :value="amount"
      @focus="changeAmountValidity(true)"
      @input="changeAmount"
    />
    <Transition :name="transitions.slideDown">
      <WithdrawalRuleV2
        v-if="isRuleShown"
        :country="country"
      />
    </Transition>
  </div>
</template>

<style module lang="postcss">
.root {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-16);
}
</style>
