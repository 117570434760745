<script setup lang="ts">
import { computed } from "vue";

import type { TailwindClass } from "@/shared/ui";
import { Icon, Typography } from "@/shared/ui";

interface Props {
  type: "warning";
}

interface Slots {
  append(props: object): unknown;
  text(props: object): unknown;
  title(props: object): unknown;
}

interface Details {
  color: {
    background: TailwindClass;
    icon: TailwindClass;
    text: TailwindClass;
    title: TailwindClass;
  };
  icon: string;
}

const props = defineProps<Props>();

defineSlots<Slots>();

const details = computed<Record<Props["type"], Details>>(() => ({
  warning: {
    color: {
      background: "bg-yellow-amber-500 dark:bg-amber/[.1]",
      icon: "text-white dark:text-gold",
      text: "text-white dark:text-amber",
      title: "text-white dark:text-amber",
    },
    icon: "circle-attention",
  },
}));

const detailsByType = computed(() => details.value[props.type]);
</script>

<template>
  <div
    class="flex flex-col gap-2 rounded-2xl p-4"
    :class="detailsByType.color.background"
    data-testid="root"
  >
    <div class="flex items-center justify-between">
      <div class="flex items-center gap-2">
        <Icon
          class="h-4 w-4"
          :class="detailsByType.color.icon"
          data-testid="icon"
          :name="detailsByType.icon"
        />
        <Typography
          :class="detailsByType.color.title"
          data-testid="title"
          size="lg"
          variant="h3"
          weight="semibold"
        >
          <slot name="title" />
        </Typography>
      </div>
      <slot name="append" />
    </div>
    <Typography
      :class="detailsByType.color.text"
      data-testid="text"
      size="sm"
    >
      <slot name="text" />
    </Typography>
  </div>
</template>
