<script setup lang="ts">
import { useI18n } from "vue-i18n";

import { Card, Button, Icon, Typography } from "@/shared/ui";

type Emits = {
  change: [];
};

interface Props {
  icon: Nullable<string>;
}

interface Slots {
  text(props: object): unknown;
  title(props: object): unknown;
}

const emit = defineEmits<Emits>();

defineProps<Props>();

defineSlots<Slots>();

const { t } = useI18n();

const onChange = () => emit("change");
</script>

<template>
  <Card class="highlight-none flex items-center justify-between border-0.5 p-4">
    <div class="flex items-center gap-4">
      <Icon
        class="h-8 w-8"
        :name="icon"
      />
      <div class="grid gap-y-0.5">
        <Typography
          class="text-xs text-gray-350 dark:text-pearl"
          size="auto"
        >
          <slot name="title" />
        </Typography>
        <Typography>
          <slot name="text" />
        </Typography>
      </div>
    </div>
    <Button
      variant="plain"
      @click="onChange"
    >
      <Typography
        class="text-blue-cyan-500 dark:text-sapphire"
        weight="medium"
      >
        {{ t("button.change") }}
      </Typography>
    </Button>
  </Card>
</template>
