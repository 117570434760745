<script setup lang="ts">
import { onMounted } from "vue";
import { useRoute } from "vue-router";

import { SectionSpinnerV2 } from "@/entities/section";
import { generateAndSubmitForm } from "@/features/form";
import { Container } from "@/shared/ui-v2";

const route = useRoute();

onMounted(() => generateAndSubmitForm(route.query as Dictionary<string>));
</script>

<template>
  <section>
    <Container>
      <SectionSpinnerV2 />
    </Container>
  </section>
</template>
