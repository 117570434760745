<script setup lang="ts">
import { storeToRefs } from "pinia";
import { onBeforeMount } from "vue";

import type { FormField } from "@/entities/payment-method";
import { DynamicField, useFormStore } from "@/features/form";

const formStore = useFormStore();
const { fields, errorRefs } = storeToRefs(formStore);
const { setErrorRefs } = formStore;

const addRef = (el: HTMLDivElement, field: FormField) => {
  const isExists = errorRefs.value.some((item) => item.name === field.name);

  if (isExists) {
    return;
  }

  setErrorRefs([
    ...errorRefs.value,
    {
      el,
      hasError: false,
      name: field.name,
    },
  ]);
};

onBeforeMount(() => setErrorRefs([]));
</script>

<template>
  <div class="flex flex-col gap-4">
    <div
      v-for="field of fields"
      :key="field.name"
      :ref="(el) => addRef(el as HTMLDivElement, field)"
    >
      <DynamicField :field="field" />
    </div>
  </div>
</template>
