<script setup lang="ts">
import { storeToRefs } from "pinia";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

import { useDepositStore } from "@/entities/deposit";
import type { PaymentMethod } from "@/entities/payment-method";
import { mapPaymentMethod, usePaymentMethodStore } from "@/entities/payment-method";
import { SectionWrapper } from "@/entities/section";
import type { Snippet } from "@/entities/user";
import { useUserStore } from "@/entities/user";
import { SubmitButton, getAmount, useFormStore } from "@/features/form";
import { useSearch } from "@/features/search";
import { transitions } from "@/shared/constants";
import { ScrollShadow } from "@/shared/ui";
import { DepositForm, DepositInfo, DepositSearch } from "@/widgets/deposit";

const { t } = useI18n();

const depositStore = useDepositStore();
const { isLoadingDeposit } = storeToRefs(depositStore);
const { onSubmit } = depositStore;

const paymentMethodStore = usePaymentMethodStore();
const { limits, paymentGroup, paymentMethod, sortedPaymentMethods } = storeToRefs(paymentMethodStore);
const { changePaymentMethod } = paymentMethodStore;

const { country, snippet } = storeToRefs(useUserStore());

const { changeAmount, changeFields } = useFormStore();

const { isSearchActive, search, cancelSearch, changeSearch, setIsSearchActive } = useSearch();

const searchedMethods = computed(() =>
  sortedPaymentMethods.value.filter(({ text }) =>
    (text[country.value] ?? text.en ?? "").toLowerCase().includes(search.value.toLowerCase()),
  ),
);

const handleSearch = (search: string) => {
  changeSearch(search);
  changePaymentMethod(null);
};

const selectPaymentMethod = (selectedMethod: PaymentMethod) => {
  const paymentMethod = mapPaymentMethod(selectedMethod);
  changePaymentMethod(paymentMethod);
  changeFields(paymentMethod.fields);
  const amount = getAmount(snippet.value as Snippet, limits.value);
  changeAmount(amount);
};
</script>

<template>
  <SectionWrapper>
    <ScrollShadow
      class="flex flex-col gap-6"
      :class="[...(searchedMethods.length ? [] : ['grow'])]"
    >
      <DepositInfo :icon="paymentGroup?.name">
        <template #text>
          {{ paymentGroup?.label ?? "" }}
        </template>
      </DepositInfo>
      <DepositSearch
        :is-search-active="isSearchActive"
        :payment-method="paymentMethod"
        :search="search"
        :searched-methods="searchedMethods"
        @cancel-search="cancelSearch"
        @change-search="handleSearch"
        @select-payment-method="selectPaymentMethod"
        @set-is-search-active="setIsSearchActive"
      />
    </ScrollShadow>
    <Transition :name="transitions.slideDown">
      <div
        v-if="paymentMethod"
        class="flex flex-1 flex-col gap-6"
      >
        <DepositForm />
        <SubmitButton
          class="mt-auto"
          :is-loading="isLoadingDeposit"
          @click="onSubmit"
        >
          {{ t("deposit.button") }}
        </SubmitButton>
      </div>
    </Transition>
  </SectionWrapper>
</template>
