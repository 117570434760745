<script setup lang="ts">
import { storeToRefs } from "pinia";

import { usePaymentMethodStore, usePaymentInstruction } from "@/entities/payment-method";
import { InstructionPageV2 } from "@/pages";

const { paymentMethod } = storeToRefs(usePaymentMethodStore());

const { instruction } = usePaymentInstruction({ paymentMethod });
</script>

<template>
  <InstructionPageV2 :content="instruction" />
</template>
