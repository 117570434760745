import type { Router } from "vue-router";

import { routeNames } from "@/shared/constants";

const TIMEOUT_DURATION = 1_000 * 30;

export const fetchData = async (requests: Promise<void>[], router: Router, errorRoute = routeNames.errorPage) => {
  try {
    await Promise.race([
      Promise.all(requests),
      new Promise((_, reject) => setTimeout(() => reject(new Error("Request timeout")), TIMEOUT_DURATION)),
    ]);
  } catch (error) {
    await router.push({
      name: errorRoute,
      query: {
        refreshUrl: window.location.href,
      },
    });
    throw new Error((error as Error).message);
  }
};
