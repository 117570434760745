<script setup lang="ts">
import { storeToRefs } from "pinia";
import { onBeforeMount } from "vue";
import { useI18n } from "vue-i18n";

import { DEFAULT_DEPOSIT_TIME } from "@/entities/crypto";
import { depositTimes, useDepositStore } from "@/entities/deposit";
import type { PaymentMethodNetwork } from "@/entities/payment-method";
import { CRYPTO_NETWORK_FIELD, useFormStore } from "@/features/form";
import { Caption, Dropdown, Paragraph } from "@/shared/ui-v2";

interface Props {
  items: {
    text: string;
    value: string | number;
  }[];
}

const props = defineProps<Props>();

const { t } = useI18n();

const depositStore = useDepositStore();
const { isLoadingDeposit } = storeToRefs(depositStore);
const { onSubmit } = depositStore;

const formStore = useFormStore();
const { validationState } = storeToRefs(formStore);
const { changeFieldProps } = formStore;

const changeNetwork = async (value: string | number) => {
  changeFieldProps(CRYPTO_NETWORK_FIELD, { value });
  await onSubmit({ isNewFlow: true }, null);
};

onBeforeMount(async () => {
  await changeNetwork(props.items.at(-1)?.value ?? "");
});
</script>

<template>
  <Dropdown
    :is-disabled="isLoadingDeposit || items.length < 2"
    :items="items"
    label=""
    :value="validationState.network"
    @change="changeNetwork"
  >
    <template #item="{ item }">
      <div :class="$style.wrapper">
        <Paragraph color="primary">
          {{ item.text }}
        </Paragraph>
        <Caption color="tertiary">
          {{
            t("deposit.crypto.network.time", {
              time: depositTimes?.[item.value as PaymentMethodNetwork] ?? DEFAULT_DEPOSIT_TIME,
            })
          }}
        </Caption>
      </div>
    </template>
  </Dropdown>
</template>

<style module lang="postcss">
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
</style>
