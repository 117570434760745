<script setup lang="ts">
import { storeToRefs } from "pinia";
import { computed, onBeforeUnmount } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

import { useDepositStore } from "@/entities/deposit";
import { usePaymentMethodStore } from "@/entities/payment-method";
import { transactionStatuses } from "@/entities/transaction";
import { CompletePageV2 } from "@/pages";
import { routeNamesV2 } from "@/shared/constants";

const { t } = useI18n();
const router = useRouter();

const depositStore = useDepositStore();
const { transactionStatus } = storeToRefs(depositStore);
const { changeTransactionStatus } = depositStore;

const { paymentGroup } = storeToRefs(usePaymentMethodStore());

const translations = computed(() => ({
  accepted: {
    button: t("deposit.complete.statusV2.accepted.button"),
    text: t("deposit.complete.statusV2.accepted.text"),
    title: t("deposit.complete.statusV2.accepted.title"),
  },
  mistaked: {
    button: t("deposit.complete.statusV2.mistaked.button"),
    text: t("deposit.complete.statusV2.mistaked.text"),
    title: t("deposit.complete.statusV2.mistaked.title"),
  },
  processed: {
    button: t("deposit.complete.statusV2.processed.button"),
    title: t("deposit.complete.statusV2.processed.title"),
  },
  waiting: {
    text: t("deposit.complete.statusV2.waiting.text"),
    title: t("deposit.complete.statusV2.waiting.title"),
  },
}));

const backToPaymentPage = () => {
  if (paymentGroup.value) {
    router.replace({ name: routeNamesV2.depositGroupPage });
    return;
  }

  router.replace({ name: routeNamesV2.depositRedirectPage });
};

onBeforeUnmount(() => changeTransactionStatus(transactionStatuses.waiting));
</script>

<template>
  <CompletePageV2
    transaction="deposit"
    :transaction-status="transactionStatus"
    :translations="translations"
    @back-to-payment-page="backToPaymentPage"
    @change-transaction-status="changeTransactionStatus"
  />
</template>
