<script setup lang="ts">
import { nextTick, ref, watch } from "vue";

import { transitions } from "@/shared/constants";
import { IconBox, Paragraph } from "@/shared/ui-v2";

type Placement =
  | "bottom-start"
  | "bottom-center"
  | "bottom-end"
  | "center-start"
  | "center-end"
  | "top-start"
  | "top-center"
  | "top-end";

type Emits = {
  change: [value: boolean];
  close: [];
};

interface Props {
  duration?: number;
  isVisible?: boolean;
  placement?: Placement;
}

interface Slots {
  tooltip(props: object): unknown;
  trigger(props: object): unknown;
}

const emit = defineEmits<Emits>();

const props = withDefaults(defineProps<Props>(), {
  duration: 0,
  isVisible: false,
  placement: "bottom-start",
});

defineSlots<Slots>();

const calculatedPlacement = ref<Placement>(props.placement);
const tooltipRef = ref<HTMLElement>();

const onChange = (value: boolean) => emit("change", value);
const onClose = () => emit("close");

const calculatePlacement = () => {
  if (!tooltipRef.value) {
    return props.placement;
  }

  const { bottom, top, left, right } = tooltipRef.value.getBoundingClientRect();

  if (bottom > window.innerHeight) {
    return "top-start";
  }

  if (top < 0) {
    return "bottom-start";
  }

  if (left < 0) {
    return "center-end";
  }

  if (right > window.innerWidth) {
    return "center-start";
  }

  return props.placement;
};

const changePlacement = (placement: Placement) => {
  calculatedPlacement.value = placement;
};

const handleMove = (value: boolean) => {
  changePlacement(props.placement);
  onChange(value);
  nextTick(() => changePlacement(calculatePlacement()));
};

watch(
  () => props.isVisible,
  () => {
    if (props.isVisible && props.duration) {
      setTimeout(onClose, props.duration);
    }
  },
);
</script>

<template>
  <div :class="$style.root">
    <Transition :name="transitions.fade">
      <div
        v-if="isVisible"
        ref="tooltipRef"
        :class="[$style.tooltip, $style[calculatedPlacement]]"
      >
        <IconBox
          :class="[$style.arrow, $style[calculatedPlacement]]"
          name="tooltip-arrow"
        />
        <Paragraph color="primary">
          <slot name="tooltip" />
        </Paragraph>
      </div>
    </Transition>
    <div
      :class="$style.trigger"
      @mouseleave="handleMove(false)"
      @mouseover="handleMove(true)"
    >
      <slot name="trigger" />
    </div>
  </div>
</template>

<style module lang="postcss">
.root {
  position: relative;
  z-index: 10;
  display: inline-flex;
}

.tooltip {
  position: absolute;
  max-width: var(--max-width-tooltip);
  width: max-content;
  padding: var(--spacing-8) var(--spacing-12);
  box-shadow: var(--box-shadow-tooltip);
  border-radius: var(--rounding-10);
  background-color: var(--color-bg-elevated);

  &.bottom-start {
    left: 0;
  }

  &.bottom-center {
    left: 50%;
    transform: translateX(-50%);
  }

  &.bottom-end {
    right: 0;
  }

  &.bottom-start,
  &.bottom-center,
  &.bottom-end {
    top: calc(100% + var(--spacing-16));
  }

  &.center-start {
    right: calc(100% + var(--spacing-16));
  }

  &.center-end {
    left: calc(100% + var(--spacing-16));
  }

  &.center-start,
  &.center-end {
    top: 50%;
    transform: translateY(-50%);
  }

  &.top-start {
    left: 0;
  }

  &.top-center {
    left: 50%;
    transform: translateX(-50%);
  }

  &.top-end {
    right: 0;
  }

  &.top-start,
  &.top-center,
  &.top-end {
    bottom: calc(100% + var(--spacing-16));
  }
}

.arrow {
  position: absolute;
  z-index: 20;
  color: var(--color-bg-elevated);

  &.bottom-start {
    left: var(--spacing-16);
    transform: rotate(180deg);
  }

  &.bottom-center {
    left: 50%;
    transform: translateX(-50%) rotate(180deg);
  }

  &.bottom-end {
    right: var(--spacing-16);
    transform: rotate(180deg);
  }

  &.bottom-start,
  &.bottom-center,
  &.bottom-end {
    bottom: 100%;
  }

  &.center-start {
    right: calc(calc(var(--spacing-8) - 1px) * -1);
    transform: translateY(-50%) rotate(270deg);
  }

  &.center-end {
    left: calc(calc(var(--spacing-8) - 1px) * -1);
    transform: translateY(-50%) rotate(90deg);
  }

  &.center-start,
  &.center-end {
    top: 50%;
  }

  &.top-start {
    left: var(--spacing-16);
  }

  &.top-center {
    left: 50%;
    transform: translateX(-50%);
  }

  &.top-end {
    right: var(--spacing-16);
  }

  &.top-start,
  &.top-center,
  &.top-end {
    top: 100%;
  }
}

.trigger {
  display: inline-flex;
  flex-grow: 1;
}
</style>
