import type { PaymentMethodNetwork } from "@/entities/payment-method";

export const depositTimes: Readonly<Record<PaymentMethodNetwork, number>> = {
  "Bitcoin Blockchain": 15,
  "Monero Blockchain": 10,
  "Bitcoin Cash Blockchain": 10,
  "ERC-20": 10,
  "TRC-20": 5,
  "BEP-20": 5,
  "Litecoin Blockchain": 5,
  "Dash Blockchain": 5,
  "Doge Blockchain": 5,
  "Zcash Blockchain": 5,
  "Ripple Network": 3,
  "Stellar Network": 3,
};

export const depositApiResponseTypes = {
  customPage: "CUSTOM_PAGE",
  offline: "OFFLINE",
};

export const depositCustomPageIds = {
  eazype: "custom-page-easype",
  upiQr: "custom-page-upi-qr",
};
