import DOMPurify from "dompurify";
import type { DirectiveBinding } from "vue";

export const sanitizeHtmlDirective = {
  beforeMount(el: HTMLElement, binding: DirectiveBinding) {
    // eslint-disable-next-line no-param-reassign
    el.innerHTML = DOMPurify.sanitize(binding.value);
  },
  updated(el: HTMLElement, binding: DirectiveBinding) {
    // eslint-disable-next-line no-param-reassign
    el.innerHTML = DOMPurify.sanitize(binding.value);
  },
};
