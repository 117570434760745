import type { Snippet, SnippetPayload, User, UserPayload } from "@/entities/user";
import { client } from "@/shared/api";

export const fetchPaymentSnippet = async (payload: SnippetPayload) => {
  const { data } = await client.send<Snippet, SnippetPayload>("BANKING:userdata-payment-snippet", payload);
  return data;
};

export const updateProfile = async (payload: UserPayload) => {
  const { data } = await client.send<User, UserPayload>("USER:profile-update", payload);
  return data;
};
