<script setup lang="ts">
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";

import { useOfferStore } from "@/entities/offer";
import { usePaymentMethodStore } from "@/entities/payment-method";
import { useUserStore } from "@/entities/user";
import { AmountInput, AmountTags, FormFields, useFormStore } from "@/features/form";
import { Button } from "@/shared/ui";

const { t } = useI18n();

const { offer } = storeToRefs(useOfferStore());

const { limits } = storeToRefs(usePaymentMethodStore());

const { user } = storeToRefs(useUserStore());

const formStore = useFormStore();
const { amount, fields, isAmountValid } = storeToRefs(formStore);
const { changeAmount, changeAmountValidity } = formStore;

const handleClick = (amount: number) => {
  changeAmount(String(amount));
  changeAmountValidity(true);
};
</script>

<template>
  <div class="flex flex-col gap-4">
    <FormFields v-if="fields.length" />
    <AmountInput
      :has-error-message="!isAmountValid"
      :limits="limits"
      :value="amount"
      @focus="changeAmountValidity(true)"
      @input="changeAmount"
    >
      <template #append>
        <Button
          color="auto"
          size="sm"
          variant="bordered"
          @click="handleClick(limits.max)"
        >
          {{ t("deposit.inputAmount.max") }}
        </Button>
      </template>
      <template #label>
        {{ t("deposit.text") }}
      </template>
    </AmountInput>
    <AmountTags
      :currency="user.currency"
      :limits="limits"
      :offer="offer"
      :value="amount"
      @click="handleClick"
    />
  </div>
</template>
