<script setup lang="ts">
import { storeToRefs } from "pinia";

import { useUserStore } from "@/entities/user";
import { Button } from "@/shared/ui-v2";
import type { TransactionButton } from "@/widgets/transaction";

interface Props {
  buttons: TransactionButton[];
}

defineProps<Props>();

const { user } = storeToRefs(useUserStore());
</script>

<template>
  <div :class="[$style.root, user.platform === 'ios' && $style.rootMargin]">
    <Button
      v-for="(button, index) of buttons"
      :key="index"
      v-bind="button.props"
      @click="button.callback"
    >
      {{ button.text }}
    </Button>
  </div>
</template>

<style module lang="postcss">
.root {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-2);
}

.rootMargin {
  margin-bottom: var(--spacing-24);
}
</style>
