<script setup lang="ts">
import type { CssClass } from "@/shared/ui-v2";

interface Props {
  color?: "inherit" | "accent" | "primary";
  size?: "auto" | "full" | "s" | "m";
}

withDefaults(defineProps<Props>(), {
  color: "primary",
  size: "m",
});

const colors: Record<NotUndefined<Props["color"]>, CssClass> = {
  inherit: "",
  accent: "color-accent",
  primary: "color-primary",
};

const sizes: Record<NotUndefined<Props["size"]>, CssClass> = {
  auto: "",
  full: "size-full",
  s: "size-s",
  m: "size-m",
};
</script>

<template>
  <svg
    :class="[$style.root, $style[colors[color]], $style[sizes[size]]]"
    fill="none"
    height="20"
    viewBox="0 0 21 20"
    width="21"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.5 0a10 10 0 1 1-10 10H3a7.5 7.5 0 1 0 7.5-7.5V0Z"
      fill="currentColor"
    />
  </svg>
</template>

<style module lang="postcss">
.root {
  width: fit-content;
  animation: spin 1s linear infinite;
}

.color-accent {
  color: var(--color-brand-accent);
}

.color-primary {
  color: var(--color-brand-primary);
}

.size-full {
  height: 100%;
}

.size-s {
  height: var(--size-loader-s);
}

.size-m {
  height: var(--size-loader-m);
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
