import type {
  AdditionalDepositPayload,
  Deposit,
  DepositPayload,
  PrepareDepositPayload,
  PrepareDepositResponse,
} from "@/entities/deposit";
import { client } from "@/shared/api";

export const createDeposit = async (payload: DepositPayload) => {
  const { data } = await client.send<Deposit, AdditionalDepositPayload & DepositPayload>(
    "BANKING:deposit-create",
    payload,
  );
  return data;
};

export const prepareDeposit = async (payload: PrepareDepositPayload) => {
  const { data } = await client.send<PrepareDepositResponse, PrepareDepositPayload>("BANKING:deposit-prepare", payload);
  return data;
};
