<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";

import { getDays, getHours, getMinutes, getSeconds, parseTime } from "@/shared/lib";
import type { CssClass } from "@/shared/ui-v2";
import { Caption } from "@/shared/ui-v2";

type Emits = {
  expire: [];
};

interface Props {
  duration: number;
  type?: "default" | "static";
}

const emit = defineEmits<Emits>();

const props = withDefaults(defineProps<Props>(), {
  type: "default",
});

const { t } = useI18n();

const colors: Record<NotUndefined<Props["type"]>, CssClass> = {
  default: "color-default",
  static: "color-static",
};

const interval = ref<ReturnType<typeof setInterval>>();
const time = ref(0);

const config = computed(() => ({
  days: getDays(props.duration),
  hours: getHours(props.duration),
  minutes: getMinutes(props.duration),
  seconds: getSeconds(props.duration),
}));

const formattedTime = computed(() => {
  let result = [t("timer.entity.second", { time: parseTime(getSeconds(time.value)) })];

  if (config.value.minutes > 0 || config.value.hours > 0) {
    result = [t("timer.entity.minute", { time: parseTime(getMinutes(time.value)) }), ...result];
  }

  if (config.value.hours > 0 || config.value.days > 0) {
    result = [t("timer.entity.hour", { time: parseTime(getHours(time.value)) }), ...result];
  }

  if (config.value.days > 0) {
    result = [t("timer.entity.day", { time: parseTime(getDays(time.value)) }), ...result];
  }

  return result.join(" : ");
});

const onExpire = () => emit("expire");

const changeInterval = (value: ReturnType<typeof setInterval>) => {
  interval.value = value;
};

const changeTime = (value: number) => {
  time.value = value;
};

const updateTime = () => {
  changeTime(time.value - 1_000);

  if (time.value < 1_000) {
    clearInterval(interval.value);
    onExpire();
  }
};

onBeforeUnmount(() => clearInterval(interval.value));
onMounted(() => {
  changeTime(props.duration);
  changeInterval(setInterval(updateTime, 1_000));
});
</script>

<template>
  <div :class="[$style.root, $style[colors[type]]]">
    <Caption size="s-sb">
      {{ formattedTime }}
    </Caption>
  </div>
</template>

<style module lang="postcss">
.root {
  display: flex;
  width: fit-content;
  padding: var(--spacing-2) var(--spacing-8);
  text-align: left;
  border-radius: var(--rounding-countdown);
}

.color-default {
  background-color: var(--color-bg-secondary);
}

.color-static {
  background-color: var(--color-bg-on-banner-white-subtle);
}
</style>
