<script setup lang="ts">
import { onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";

import type { User } from "@/entities/user";
import { bridgeService } from "@/shared/services";
import { Alert, Button, Icon } from "@/shared/ui";

interface Props {
  country: User["country"];
}

defineProps<Props>();

const DEFAULT_RULE_ID = 324;

const ruleIdsByCountry: Readonly<Partial<Record<Props["country"], number>>> = {
  in: 2,
};

const { t } = useI18n();

const alertRef = ref<Nullable<HTMLDivElement>>(null);

const scrollToRef = () => {
  alertRef.value?.scrollIntoView({ behavior: "smooth" });
};

onMounted(scrollToRef);
</script>

<template>
  <div ref="alertRef">
    <Alert type="warning">
      <template #append>
        <Button
          variant="plain"
          @click="
            bridgeService.notify({
              messageType: 'openRule',
              ruleId: ruleIdsByCountry[country] ?? DEFAULT_RULE_ID,
            })
          "
        >
          <Icon
            class="h-4 w-4 text-white dark:text-amber"
            name="chevron-right"
          />
        </Button>
      </template>
      <template #text>
        {{ t("withdrawal.blockingNotice.text") }}
      </template>
      <template #title>
        {{ t("withdrawal.blockingNotice.title") }}
      </template>
    </Alert>
  </div>
</template>
