import { storeToRefs } from "pinia";
import type { NavigationGuardNext, RouteLocationNormalized } from "vue-router";

import { usePaymentMethodStore } from "@/entities/payment-method";

export const paymentMethodMiddleware = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
) => {
  const paymentMethodStore = usePaymentMethodStore();
  const { initialPaymentMethods, paymentGroup, paymentMethod } = storeToRefs(paymentMethodStore);
  const { changePaymentMethods } = paymentMethodStore;

  if (paymentGroup.value || paymentMethod.value) {
    next();
  } else {
    changePaymentMethods(initialPaymentMethods.value);
    next("/");
  }
};
