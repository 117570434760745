import { useVuelidate } from "@vuelidate/core";
import { storeToRefs } from "pinia";

import { usePaymentMethodStore } from "@/entities/payment-method";
import { useFormStore } from "@/features/form";
import { useNotificationStore } from "@/features/notification";
import type { RequestError } from "@/shared/api";
import { safeJsonParse, removeHtmlTagsFromString } from "@/shared/lib";

const MAX_ERROR_LENGTH = 40;

const errorCodes: Readonly<Record<number, string>> = {
  200: "OK",
  201: "Created",
  202: "Accepted",
  203: "Non-Authoritative Information",
  204: "No Content",
  205: "Reset Content",
  206: "Partial Content",
  300: "Multiple Choices",
  301: "Moved Permanently",
  302: "Found",
  303: "See Other",
  304: "Not Modified",
  305: "Use Proxy",
  306: "Unused",
  307: "Temporary Redirect",
  400: "Bad Request",
  401: "Unauthorized",
  402: "Payment Required",
  403: "Forbidden",
  404: "Not Found",
  405: "Method Not Allowed",
  406: "Not Acceptable",
  407: "Proxy Authentication Required",
  408: "Request Timeout",
  409: "Conflict",
  410: "Gone",
  411: "Length Required",
  412: "Precondition Required",
  413: "Request Entry Too Large",
  414: "Request-URI Too Long",
  415: "Unsupported Media Type",
  416: "Requested Range Not Satisfiable",
  417: "Expectation Failed",
  418: "I'm a teapot",
  429: "Too Many Requests",
  500: "Internal Server Error",
  501: "Not Implemented",
  502: "Bad Gateway",
  503: "Service Unavailable",
  504: "Gateway Timeout",
  505: "HTTP Version Not Supported",
};

export const useValidation = () => {
  const { limits } = storeToRefs(usePaymentMethodStore());

  const formStore = useFormStore();
  const { amount, fields, errorRefs, isAmountValid, validationRules, validationState } = storeToRefs(formStore);
  const { changeAmountValidity, changeFormValidity, setErrorRefs, setFields, scrollToErrorRef } = formStore;

  const { createNotification } = useNotificationStore();

  const handleError = (error: RequestError) => {
    const content = error?.message ?? "";

    if (error?.data?.code) {
      const text = removeHtmlTagsFromString(content);

      if (!text.length) {
        createNotification({ color: "danger", content: error.data.code });
        return;
      }

      if (text.length > MAX_ERROR_LENGTH) {
        createNotification({ color: "danger", content });
        return;
      }
    }

    const [, result] = safeJsonParse(content);

    if (result?.status) {
      createNotification({ color: "danger", content: errorCodes[result.status] });
      return;
    }

    createNotification({ color: "danger", content });
  };

  const validate = async () => {
    const v$ = useVuelidate(validationRules.value, validationState.value);
    const isFormValid = await v$.value.$validate();

    changeAmountValidity(+amount.value >= limits.value.min && +amount.value <= limits.value.max);
    changeFormValidity(isFormValid);

    if (!isFormValid || !isAmountValid.value) {
      setFields(
        fields.value.map((field) => ({
          ...field,
          props: {
            ...field.props,
            validations: field.props.validations.map((validation) => ({
              ...validation,
              isExists: v$.value?.[field.name]?.$error || !field.props.value,
            })),
          },
        })),
      );
      setErrorRefs(
        errorRefs.value.map((item) => ({
          ...item,
          hasError:
            fields.value
              .find((field) => field.name === item.name)
              ?.props?.validations?.every((validation) => validation.isExists) ?? false,
        })),
      );
      scrollToErrorRef(errorRefs.value);
    }

    return isFormValid && isAmountValid.value;
  };

  return {
    handleError,
    validate,
  };
};
