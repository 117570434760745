<script setup lang="ts">
import { useI18n } from "vue-i18n";

import { CryptoAddress, MinimumDeposit } from "@/entities/crypto";
import type { CryptoApiResponse, Deposit } from "@/entities/deposit";
import { ActionsPanel } from "@/features/crypto";
import { Alert } from "@/shared/ui-v2";

interface Props {
  response: Deposit["apiResponse"] & { data: CryptoApiResponse };
}

defineProps<Props>();

const { t } = useI18n();
</script>

<template>
  <div :class="$style.root">
    <MinimumDeposit :response="response" />
    <div :class="$style.container">
      <CryptoAddress :address="response.data.depositAddress" />
      <ActionsPanel :address="response.data.depositAddress" />
    </div>
    <Alert type="warning">
      {{ t("deposit.crypto.address.depositText") }}
    </Alert>
  </div>
</template>

<style module lang="postcss">
.root {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-16);
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-16);
  max-width: var(--max-width-qr);
  margin: 0 auto;
}
</style>
