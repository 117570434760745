<script setup lang="ts">
import type { CssClass } from "@/shared/ui-v2";

type Emits = {
  click: [];
};

interface Props {
  isPressable?: boolean;
  size?: "m" | "l";
}

interface Slots {
  default(props: object): unknown;
}

const emit = defineEmits<Emits>();

withDefaults(defineProps<Props>(), {
  isPressable: false,
  size: "m",
});

defineSlots<Slots>();

const sizes: Record<NotUndefined<Props["size"]>, CssClass> = {
  m: "size-m",
  l: "size-l",
};

const onClick = () => emit("click");
</script>

<template>
  <button
    :class="[$style.root, isPressable && $style.pressable, $style[sizes[size]]]"
    type="button"
    @click="onClick"
  >
    <slot />
  </button>
</template>

<style module lang="postcss">
.root {
  border-radius: var(--rounding-method-card);
  cursor: default;
  outline: transparent solid var(--spacing-2);
  user-select: none;
  transition: outline-color 0.25s;
  background-color: var(--color-control-neutral) !important;
  -webkit-tap-highlight-color: transparent;

  &.pressable {
    cursor: pointer;

    &:active {
      opacity: var(--opacity-item-active);
    }

    &:focus-visible {
      outline: var(--color-border-focus) solid var(--spacing-2);
      outline-offset: var(--spacing-2);
    }

    &:hover {
      background-color: var(--color-control-neutral-hover) !important;
    }
  }
}

.size-m {
  padding: var(--spacing-12);
}

.size-l {
  padding: var(--spacing-16);
}
</style>
