import type {
  BankPayload,
  BankResponse,
  DepositCancelPayload,
  DepositCancelResponse,
  DepositCheckPayload,
  DepositCheckResponse,
  DepositCompletePayload,
  DepositCompleteResponse,
  DepositCreatePayload,
  DepositCreateResponse,
  DepositStatusPayload,
  DepositStatusResponse,
} from "@/entities/peer-to-peer";
import { client } from "@/shared/api";

export const fetchBank = async (payload: BankPayload) => {
  const { data } = await client.send<BankResponse, BankPayload>("BANKING_DEPOSIT:bank-get", payload);
  return data;
};

export const fetchDepositStatus = async (payload: DepositStatusPayload) => {
  const { data } = await client.send<DepositStatusResponse, DepositStatusPayload>(
    "BANKING_DEPOSIT:deposit-status",
    payload,
  );
  return data;
};

export const cancelDeposit = async (payload: DepositCancelPayload) => {
  const { data } = await client.send<DepositCancelResponse, DepositCancelPayload>(
    "BANKING_DEPOSIT:deposit-cancel",
    payload,
  );
  return data;
};

export const checkDeposit = async (payload: DepositCheckPayload) => {
  const { data } = await client.send<DepositCheckResponse, DepositCheckPayload>(
    "BANKING_DEPOSIT:deposit-check",
    payload,
  );
  return data;
};

export const completeDeposit = async (payload: DepositCompletePayload) => {
  const { data } = await client.send<DepositCompleteResponse, DepositCompletePayload>(
    "BANKING_DEPOSIT:deposit-complete",
    payload,
  );
  return data;
};

export const createDeposit = async (payload: DepositCreatePayload) => {
  const { data } = await client.send<DepositCreateResponse, DepositCreatePayload>(
    "BANKING_DEPOSIT:deposit-create-p2p",
    payload,
  );
  return data;
};
