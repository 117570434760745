<script setup lang="ts">
import { useI18n } from "vue-i18n";

import type { WithdrawalConfirmation } from "@/entities/withdrawal";
import { Button, ConfirmationInput, Typography } from "@/shared/ui";

type Emits = {
  changeCode: [value: string[]];
  changeCodeByIndex: [index: number, value: string];
  changeError: [value: boolean];
  resendCode: [];
};

interface Props {
  code: string[];
  confirmationData: WithdrawalConfirmation;
  count: number;
  error: {
    isExists: boolean;
    message: string;
  };
  formattedTime: string;
  isSubmitButtonVisible: boolean;
}

const emit = defineEmits<Emits>();

defineProps<Props>();

const { t } = useI18n();

const onChangeCode = (value: string[]) => emit("changeCode", value);
const onChangeCodeByIndex = (index: number, value: string) => emit("changeCodeByIndex", index, value);
const onChangeError = (value: boolean) => emit("changeError", value);
const onResendCode = () => emit("resendCode");
</script>

<template>
  <div>
    <Typography class="mb-6 text-gray-900/[.8] dark:text-white/[.8]">
      {{ t("withdrawal.confirm.sendText", { email: confirmationData.email }) }}
    </Typography>
    <ConfirmationInput
      class="mb-4"
      :count="count"
      :error="error"
      :value="code"
      @change="onChangeCode"
      @change-item-by-index="onChangeCodeByIndex"
      @focus="onChangeError(false)"
    />
    <Button
      v-if="isSubmitButtonVisible"
      color="secondary"
      size="sm"
      variant="plain"
      @click="onResendCode"
    >
      {{ t("withdrawal.confirm.buttonText") }}
    </Button>
    <Typography
      v-else
      class="text-gray-350 dark:text-pearl/[.64]"
    >
      {{ t("withdrawal.confirm.repeatText", { time: formattedTime }) }}
    </Typography>
  </div>
</template>
