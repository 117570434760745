import type { DirectiveBinding } from "vue";

interface ExtendedHTMLElement extends HTMLElement {
  clickOutside: (event: Event) => void;
}

export const clickOutsideDirective = {
  beforeMount(el: ExtendedHTMLElement, binding: DirectiveBinding) {
    const clickOutside = (event: Event) => {
      if (!(el === event.target || el.contains(event.target as Node))) {
        binding.value(event);
      }
    };

    Object.assign(el, { clickOutside });

    document.addEventListener("click", el.clickOutside);
  },
  unmounted(el: ExtendedHTMLElement) {
    document.removeEventListener("click", el.clickOutside);
  },
};
