import { computed } from "vue";

import { i18n } from "@/app/plugins";
import type { ImageGuideContent } from "@/entities/payment-method";
import type { InternationalTransferBank } from "@/entities/peer-to-peer";
import GazpromInstructionOne from "@/shared/assets/images/instructions/international-transfer/gazprom/1.png";
import GazpromInstructionTwo from "@/shared/assets/images/instructions/international-transfer/gazprom/2.png";
import GazpromInstructionThree from "@/shared/assets/images/instructions/international-transfer/gazprom/3.png";
import GazpromInstructionFour from "@/shared/assets/images/instructions/international-transfer/gazprom/4.png";
import SberInstructionOne from "@/shared/assets/images/instructions/international-transfer/sber/1.png";
import SberInstructionTwo from "@/shared/assets/images/instructions/international-transfer/sber/2.png";
import SberInstructionThree from "@/shared/assets/images/instructions/international-transfer/sber/3.png";
import SberInstructionFour from "@/shared/assets/images/instructions/international-transfer/sber/4.png";
import SberInstructionFive from "@/shared/assets/images/instructions/international-transfer/sber/5.png";
import SberInstructionSix from "@/shared/assets/images/instructions/international-transfer/sber/6.png";
import TinkoffInstructionOne from "@/shared/assets/images/instructions/international-transfer/tinkoff/1.png";
import TinkoffInstructionTwo from "@/shared/assets/images/instructions/international-transfer/tinkoff/2.png";
import TinkoffInstructionThree from "@/shared/assets/images/instructions/international-transfer/tinkoff/3.png";
import TinkoffInstructionFour from "@/shared/assets/images/instructions/international-transfer/tinkoff/4.png";
import TinkoffInstructionFive from "@/shared/assets/images/instructions/international-transfer/tinkoff/5.png";
import TinkoffInstructionSix from "@/shared/assets/images/instructions/international-transfer/tinkoff/6.png";
import TinkoffInstructionSeven from "@/shared/assets/images/instructions/international-transfer/tinkoff/7.png";
import TinkoffInstructionEight from "@/shared/assets/images/instructions/international-transfer/tinkoff/8.png";

interface Props {
  amount: string;
}

export const useInternationalTransferInstruction = (props: Props) => {
  const instructionsByBank = computed<Record<InternationalTransferBank, ImageGuideContent[]>>(() => ({
    gazprom: [
      {
        description: i18n.global.t("peerToPeer.invoice.international.instruction.bank.gazprom.one.description"),
        image: GazpromInstructionOne,
        text: i18n.global.t("peerToPeer.invoice.international.instruction.bank.gazprom.one.text"),
        title: i18n.global.t("peerToPeer.invoice.international.instruction.bank.gazprom.one.title"),
      },
      {
        description: i18n.global.t("peerToPeer.invoice.international.instruction.bank.gazprom.two.description"),
        image: GazpromInstructionTwo,
        text: i18n.global.t("peerToPeer.invoice.international.instruction.bank.gazprom.two.text"),
        title: i18n.global.t("peerToPeer.invoice.international.instruction.bank.gazprom.two.title"),
      },
      {
        description: i18n.global.t("peerToPeer.invoice.international.instruction.bank.gazprom.three.description"),
        image: GazpromInstructionThree,
        text: i18n.global.t("peerToPeer.invoice.international.instruction.bank.gazprom.three.text"),
        title: i18n.global.t("peerToPeer.invoice.international.instruction.bank.gazprom.three.title"),
      },
      {
        description: i18n.global.t("peerToPeer.invoice.international.instruction.bank.gazprom.four.description"),
        image: GazpromInstructionFour,
        text: i18n.global.t("peerToPeer.invoice.international.instruction.bank.gazprom.four.text"),
        title: i18n.global.t("peerToPeer.invoice.international.instruction.bank.gazprom.four.title"),
      },
    ],
    sber: [
      {
        description: i18n.global.t("peerToPeer.invoice.international.instruction.bank.sber.one.description"),
        image: SberInstructionOne,
        text: i18n.global.t("peerToPeer.invoice.international.instruction.bank.sber.one.text"),
        title: i18n.global.t("peerToPeer.invoice.international.instruction.bank.sber.one.title"),
      },
      {
        description: i18n.global.t("peerToPeer.invoice.international.instruction.bank.sber.two.description"),
        image: SberInstructionTwo,
        text: i18n.global.t("peerToPeer.invoice.international.instruction.bank.sber.two.text"),
        title: i18n.global.t("peerToPeer.invoice.international.instruction.bank.sber.two.title"),
      },
      {
        description: i18n.global.t("peerToPeer.invoice.international.instruction.bank.sber.three.description"),
        image: SberInstructionThree,
        text: i18n.global.t("peerToPeer.invoice.international.instruction.bank.sber.three.text"),
        title: i18n.global.t("peerToPeer.invoice.international.instruction.bank.sber.three.title"),
      },
      {
        description: i18n.global.t("peerToPeer.invoice.international.instruction.bank.sber.four.description"),
        image: SberInstructionFour,
        text: i18n.global.t("peerToPeer.invoice.international.instruction.bank.sber.four.text"),
        title: i18n.global.t("peerToPeer.invoice.international.instruction.bank.sber.four.title"),
      },
      {
        description: i18n.global.t("peerToPeer.invoice.international.instruction.bank.sber.five.description"),
        image: SberInstructionFive,
        text: i18n.global.t("peerToPeer.invoice.international.instruction.bank.sber.five.text"),
        title: i18n.global.t("peerToPeer.invoice.international.instruction.bank.sber.five.title"),
      },
      {
        description: "",
        image: SberInstructionFive,
        text: i18n.global.t("peerToPeer.invoice.international.instruction.bank.sber.six.text"),
        title: i18n.global.t("peerToPeer.invoice.international.instruction.bank.sber.six.title"),
      },
      {
        description: i18n.global.t("peerToPeer.invoice.international.instruction.bank.sber.seven.description", {
          amount: props.amount,
        }),
        image: SberInstructionSix,
        text: i18n.global.t("peerToPeer.invoice.international.instruction.bank.sber.seven.text"),
        title: i18n.global.t("peerToPeer.invoice.international.instruction.bank.sber.seven.title"),
      },
    ],
    tinkoff: [
      {
        description: i18n.global.t("peerToPeer.invoice.international.instruction.bank.tinkoff.one.description"),
        image: TinkoffInstructionOne,
        text: i18n.global.t("peerToPeer.invoice.international.instruction.bank.tinkoff.one.text"),
        title: i18n.global.t("peerToPeer.invoice.international.instruction.bank.tinkoff.one.title"),
      },
      {
        description: "",
        image: TinkoffInstructionTwo,
        text: i18n.global.t("peerToPeer.invoice.international.instruction.bank.tinkoff.two.text"),
        title: i18n.global.t("peerToPeer.invoice.international.instruction.bank.tinkoff.two.title"),
      },
      {
        description: i18n.global.t("peerToPeer.invoice.international.instruction.bank.tinkoff.three.description"),
        image: TinkoffInstructionThree,
        text: i18n.global.t("peerToPeer.invoice.international.instruction.bank.tinkoff.three.text"),
        title: i18n.global.t("peerToPeer.invoice.international.instruction.bank.tinkoff.three.title"),
      },
      {
        description: i18n.global.t("peerToPeer.invoice.international.instruction.bank.tinkoff.four.description"),
        image: TinkoffInstructionFour,
        text: i18n.global.t("peerToPeer.invoice.international.instruction.bank.tinkoff.four.text"),
        title: i18n.global.t("peerToPeer.invoice.international.instruction.bank.tinkoff.four.title"),
      },
      {
        description: i18n.global.t("peerToPeer.invoice.international.instruction.bank.tinkoff.five.description"),
        image: TinkoffInstructionFive,
        text: i18n.global.t("peerToPeer.invoice.international.instruction.bank.tinkoff.five.text"),
        title: i18n.global.t("peerToPeer.invoice.international.instruction.bank.tinkoff.five.title"),
      },
      {
        description: i18n.global.t("peerToPeer.invoice.international.instruction.bank.tinkoff.six.description"),
        image: TinkoffInstructionSix,
        text: i18n.global.t("peerToPeer.invoice.international.instruction.bank.tinkoff.six.text"),
        title: i18n.global.t("peerToPeer.invoice.international.instruction.bank.tinkoff.six.title"),
      },
      {
        description: i18n.global.t("peerToPeer.invoice.international.instruction.bank.tinkoff.seven.description"),
        image: TinkoffInstructionSeven,
        text: i18n.global.t("peerToPeer.invoice.international.instruction.bank.tinkoff.seven.text"),
        title: i18n.global.t("peerToPeer.invoice.international.instruction.bank.tinkoff.seven.title"),
      },
      {
        description: i18n.global.t("peerToPeer.invoice.international.instruction.bank.tinkoff.eight.description"),
        image: TinkoffInstructionEight,
        text: i18n.global.t("peerToPeer.invoice.international.instruction.bank.tinkoff.eight.text"),
        title: i18n.global.t("peerToPeer.invoice.international.instruction.bank.tinkoff.eight.title"),
      },
    ],
  }));

  return {
    instructionsByBank,
  };
};
