<script setup lang="ts">
import { storeToRefs } from "pinia";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

import { useUserStore } from "@/entities/user";
import { useFormStore } from "@/features/form";
import { formatNumber } from "@/shared/lib";
import { Caption, Title } from "@/shared/ui-v2";

const { t } = useI18n();

const { user } = storeToRefs(useUserStore());

const { amount } = storeToRefs(useFormStore());

const formattedAmount = computed(() =>
  formatNumber(
    {
      currency: user.value.currency,
      style: "currency",
    },
    +amount.value,
    user.value.lang,
  ),
);
</script>

<template>
  <div :class="$style.root">
    <Caption color="secondary">
      {{ t("withdrawal.confirm.amountText") }}
    </Caption>
    <Title size="l">
      {{ formattedAmount }}
    </Title>
  </div>
</template>

<style module lang="postcss">
.root {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-4);
}
</style>
