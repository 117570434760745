<script setup lang="ts">
import type { Size, TailwindClass } from "@/shared/ui";

interface Props {
  orientation?: "horizontal" | "vertical";
  size?: Extract<Size, "sm" | "md">;
}

withDefaults(defineProps<Props>(), {
  orientation: "horizontal",
  size: "md",
});

const sizes: Record<NotUndefined<Props["size"]>, Record<NotUndefined<Props["orientation"]>, TailwindClass>> = {
  sm: {
    horizontal: "w-full h-px-1/2",
    vertical: "w-px-1/2",
  },
  md: {
    horizontal: "w-full h-px",
    vertical: "w-px",
  },
};
</script>

<template>
  <div :class="[sizes[size][orientation]]" />
</template>
