<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";

import type { DepositCreateResponse, PeerToPeerBank } from "@/entities/peer-to-peer";
import { Caption, Divider, IconContainer, Paragraph } from "@/shared/ui-v2";

interface Props {
  bank?: PeerToPeerBank;
  hasConfirmationId: boolean;
  isInternationalTransfer: boolean;
  response: DepositCreateResponse;
}

const props = defineProps<Props>();

const { t } = useI18n();

const annotation = computed(() => {
  if (props.isInternationalTransfer) {
    return t("peerToPeer.annotation.header.international");
  }

  if (props.hasConfirmationId) {
    return t("peerToPeer.annotation.header.confirmationId");
  }

  if (props.response.card?.bank_account) {
    return t("peerToPeer.annotation.header.bankAccount");
  }

  if (props.response.card?.phone) {
    return t("peerToPeer.annotation.header.phoneNumber");
  }

  return t("peerToPeer.annotation.header.cardNumber");
});

const hasBank = computed(() => Boolean(props.bank?.bank?.logoUrl) && Boolean(props.response.card.bank_name));
</script>

<template>
  <div :class="$style.root">
    <div :class="$style.container">
      <template v-if="hasBank">
        <IconContainer
          color="informative"
          name="bank-card"
          size="m"
        />
        <div :class="$style.bank">
          <Caption color="tertiary">
            {{ t("peerToPeer.invoice.fields.bankName") }}
          </Caption>
          <Paragraph
            color="primary"
            size="m-sb"
          >
            {{ response.card.bank_name }}
          </Paragraph>
        </div>
      </template>
      <Caption
        v-else
        color="tertiary"
      >
        <span v-sanitize-html="annotation" />
      </Caption>
    </div>
    <Divider />
  </div>
</template>

<style module lang="postcss">
.root {
  display: flex;
  flex-direction: column;
  text-align: start;
  gap: var(--spacing-16);
}

.container {
  display: flex;
  align-items: center;
  gap: var(--spacing-12);
  text-align: start;
}

.bank {
  display: flex;
  flex-direction: column;
}
</style>
