import { defineStore } from "pinia";
import { reactive } from "vue";

import type { ParentData } from "@/entities/parent";

const NAMESPACE = "parent";

type Data = Pick<ParentData["payload"], "accessToken" | "closingButton" | "experiments" | "host" | "xOrigin">;

export const useParentStore = defineStore(NAMESPACE, () => {
  const parentData = reactive<Data>({
    accessToken: "",
    closingButton: true,
    experiments: [],
    host: "",
    xOrigin: "",
  });

  const changeParentData = <K extends keyof Data>(key: K, value: Data[K]) => {
    parentData[key] = value;
  };

  return {
    parentData,
    changeParentData,
  };
});
