<script setup lang="ts">
import type { CssClass } from "@/shared/ui-v2";
import { IconBox } from "@/shared/ui-v2";

interface Props {
  color?: "accent" | "primary";
}

withDefaults(defineProps<Props>(), {
  color: "primary",
});

const colors: Record<NotUndefined<Props["color"]>, CssClass> = {
  accent: "color-accent",
  primary: "color-primary",
};
</script>

<template>
  <div :class="[$style.root, $style[colors[color]]]">
    <IconBox name="check-mark" />
  </div>
</template>

<style module lang="postcss">
.root {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: var(--size-checkbox);
  height: var(--size-checkbox);
  padding: var(--spacing-4);
  border: 1px solid var(--color-border-neutral);
  border-radius: var(--rounding-max);
}

.color-accent {
  background-color: var(--color-brand-accent);
  color: var(--color-fg-brand-accent);
}

.color-primary {
  background-color: var(--color-brand-primary);
  color: var(--color-fg-brand-primary);
}
</style>
