<script setup lang="ts">
import { computed } from "vue";

import type { Offer } from "@/entities/offer";
import type { Limits } from "@/entities/payment-method";
import type { User } from "@/entities/user";
import { generateAmountTags } from "@/features/form";
import { formatNumber } from "@/shared/lib";
import { Badge, Chip } from "@/shared/ui";

type Emits = {
  click: [value: number];
};

interface Props {
  currency: User["currency"];
  limits: Limits;
  offer: Nullable<Offer>;
  value: string;
}

const emit = defineEmits<Emits>();

const props = defineProps<Props>();

const TAGS_COUNT = 5;

const amounts = computed(() => {
  const {
    offer,
    currency,
    limits: { min, max },
  } = props;

  return generateAmountTags(min, max, TAGS_COUNT)
    .filter(Boolean)
    .map((amount) => ({
      hasBonus: !!(offer?.active && offer?.minAmount <= amount),
      label: formatNumber({ currency }, amount),
      value: amount,
    }));
});

const onClick = (value: number) => emit("click", value);
</script>

<template>
  <div class="flex flex-wrap justify-center gap-2">
    <Badge
      v-for="item of amounts"
      :key="item.value"
      color="secondary"
      :is-visible="item.hasBonus"
    >
      <template #default>
        <Chip
          :is-active="String(item.value) === value"
          @click="onClick(item.value)"
        >
          {{ item.label }}
        </Chip>
      </template>
      <template #content>
        {{ `${offer?.percent ?? 0} %` }}
      </template>
    </Badge>
  </div>
</template>
