<template>
  <div :class="$style.root" />
</template>

<style module lang="postcss">
.root {
  width: 100%;
  height: 1px;
  background-color: var(--color-border-neutral);
}
</style>
