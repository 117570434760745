<script setup lang="ts">
import { computed } from "vue";

import { Caption, IconBox } from "@/shared/ui-v2";

interface Props {
  type: "informative" | "success" | "warning";
}

interface Slots {
  append(props: object): unknown;
  default(props: object): unknown;
}

interface Details {
  color: "inherit" | "accent" | "informative" | "secondary" | "tertiary" | "warning";
  icon: string;
}

const props = defineProps<Props>();

defineSlots<Slots>();

const details = computed<Record<Props["type"], Details>>(() => ({
  informative: {
    color: "informative",
    icon: "inform",
  },
  success: {
    color: "accent",
    icon: "verify",
  },
  warning: {
    color: "warning",
    icon: "danger",
  },
}));

const detailsByType = computed(() => details.value[props.type]);
</script>

<template>
  <div :class="$style.root">
    <div :class="$style.container">
      <IconBox
        :color="detailsByType.color"
        :name="detailsByType.icon"
        size="m"
      />
      <Caption color="secondary">
        <slot />
      </Caption>
      <slot name="append" />
    </div>
  </div>
</template>

<style module lang="postcss">
.root {
  border: 1px solid var(--color-border-neutral);
  border-radius: var(--rounding-16);
  padding: var(--spacing-12) var(--spacing-16);
  background-color: transparent;
}

.container {
  display: flex;
  align-items: center;
  gap: var(--spacing-12);
}
</style>
