<script setup lang="ts">
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";

import { FormFields, useFormStore } from "@/features/form";
import { Typography } from "@/shared/ui";

const { t } = useI18n();

const { fields } = storeToRefs(useFormStore());
</script>

<template>
  <div class="flex flex-col gap-4">
    <Typography
      size="lg"
      variant="h3"
      weight="medium"
    >
      {{ t("deposit.india.stepTwo.title") }}
    </Typography>
    <FormFields v-if="fields.length" />
  </div>
</template>
