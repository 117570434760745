<script setup lang="ts">
import { storeToRefs } from "pinia";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

import type { CustomPageApiResponse, Deposit } from "@/entities/deposit";
import { useDepositStore } from "@/entities/deposit";
import { usePaymentMethodStore } from "@/entities/payment-method";
import { SectionWrapper } from "@/entities/section";
import { SubmitButton } from "@/features/form";
import { routeNames } from "@/shared/constants";
import { Card, ScrollShadow, Typography } from "@/shared/ui";
import { DepositInfo } from "@/widgets/deposit";

const { t } = useI18n();
const router = useRouter();

const { apiResponse } = storeToRefs(useDepositStore());

const { paymentLabel, paymentMethod } = storeToRefs(usePaymentMethodStore());

const response = computed(
  () => apiResponse.value as Omit<Deposit["apiResponse"], "data"> & { data: CustomPageApiResponse },
);
</script>

<template>
  <SectionWrapper>
    <ScrollShadow class="flex flex-col gap-6">
      <DepositInfo :icon="paymentMethod?.name">
        <template #text>
          {{ paymentLabel }}
        </template>
      </DepositInfo>
      <div class="flex flex-col gap-6">
        <div class="mx-auto max-w-[10.25rem] rounded-2xl bg-gray-900 p-2.5 dark:bg-white">
          <img
            :alt="response.data.qrCodeText"
            :src="response.data.qrDataURL"
          />
        </div>
        <Card class="highlight-none select-none p-4 text-center">
          <Typography size="sm">
            {{ t("deposit.customPage.upiQr.text") }}
          </Typography>
        </Card>
      </div>
    </ScrollShadow>
    <SubmitButton
      class="mt-auto"
      @click="router.push({ name: routeNames.depositCompletePage })"
    >
      {{ t("deposit.customPage.upiQr.button") }}
    </SubmitButton>
  </SectionWrapper>
</template>
