<script setup lang="ts">
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";

import { useParentStore } from "@/entities/parent";
import { useModalStore } from "@/features/modal";
import { Button, Container, Icon } from "@/shared/ui";

const { t } = useI18n();
const route = useRoute();
const router = useRouter();

const { parentData } = storeToRefs(useParentStore());

const { changeIsOpen } = useModalStore();
</script>

<template>
  <header>
    <Container>
      <div class="flex items-center justify-between gap-2">
        <div>
          <Button
            v-if="route.meta.backRoute"
            color="secondary"
            variant="plain"
            @click="router.push({ name: route.meta.backRoute })"
          >
            <Icon
              class="text-blue-cyan-500 dark:text-sapphire"
              name="chevron-left"
            />
            {{ t("button.back") }}
          </Button>
        </div>
        <div class="absolute left-1/2 flex -translate-x-1/2 transform items-end gap-1">
          <Icon name="logo" />
        </div>
        <Button
          v-if="parentData.closingButton"
          class="p-2"
          variant="plain"
          @click="changeIsOpen(true)"
        >
          <Icon
            class="text-gray-350 dark:text-pearl"
            name="close"
          />
        </Button>
      </div>
    </Container>
  </header>
</template>
