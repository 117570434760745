<script setup lang="ts">
import { storeToRefs } from "pinia";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

import type { CustomPageApiResponse, Deposit } from "@/entities/deposit";
import { useDepositStore } from "@/entities/deposit";
import { usePaymentMethodStore } from "@/entities/payment-method";
import { SectionWrapper } from "@/entities/section";
import { SubmitButton } from "@/features/form";
import { bridgeService } from "@/shared/services";
import { ScrollShadow } from "@/shared/ui";
import { DepositForm, DepositInfo } from "@/widgets/deposit";

const { t } = useI18n();

const depositStore = useDepositStore();
const { apiResponse, isLoadingDeposit } = storeToRefs(depositStore);
const { onSubmit } = depositStore;

const { paymentLabel, paymentMethod } = storeToRefs(usePaymentMethodStore());

const response = computed(
  () => apiResponse.value as Omit<Deposit["apiResponse"], "data"> & { data: CustomPageApiResponse },
);

const submit = async () => {
  await onSubmit();
  bridgeService.notify({
    messageType: "extNavigation",
    uri: response.value?.data?.buttonPay,
  });
};
</script>

<template>
  <SectionWrapper>
    <ScrollShadow class="flex flex-col gap-6">
      <DepositInfo :icon="paymentMethod?.name">
        <template #text>
          {{ paymentLabel }}
        </template>
      </DepositInfo>
      <DepositForm />
    </ScrollShadow>
    <SubmitButton
      class="mt-auto"
      :is-loading="isLoadingDeposit"
      @click="submit"
    >
      {{ t("deposit.button") }}
    </SubmitButton>
  </SectionWrapper>
</template>
