<script setup lang="ts">
import { useI18n } from "vue-i18n";

import type { CryptoApiResponse, Deposit } from "@/entities/deposit";
import { Alert, Typography } from "@/shared/ui";

interface Props {
  response: Deposit["apiResponse"] & { data: CryptoApiResponse };
}

defineProps<Props>();

const { t } = useI18n();
</script>

<template>
  <div class="flex flex-col gap-2 rounded-2xl bg-gray-50 p-4 dark:bg-oxford">
    <div class="text-center">
      <Typography
        class="text-xs text-gray-350 dark:text-pearl"
        size="auto"
      >
        {{ t("deposit.crypto.address.depositSum") }}
      </Typography>
      <Typography
        class="flex justify-center gap-1 text-2.5xl"
        size="auto"
        variant="span"
        weight="bold"
      >
        {{ response.data.minDepositValue }} {{ response.data.cryptoName }}
      </Typography>
    </div>
    <Alert type="warning">
      <template #title>
        {{ t("deposit.alert.warning") }}
      </template>
      <template #text>
        {{ t("deposit.crypto.address.depositText") }}
      </template>
    </Alert>
  </div>
</template>
