import type { App } from "vue";

import type { User } from "@/entities/user";

interface Options {
  isLegacyDesign: boolean;
  user: User;
}

const initStylesByPlatform = (platform: User["platform"]) => {
  const stylesByPlatform = {
    android: () => {
      import("@/app/styles/tokens/android/index.css");
    },
    desktop: () => {
      import("@/app/styles/tokens/desktop/index.css");
    },
    ios: () => {
      import("@/app/styles/tokens/ios/index.css");
    },
    mobile: () => {
      import("@/app/styles/tokens/mobile/index.css");
    },
  };

  stylesByPlatform[platform]();
};

export default {
  install: (_: App, options: Options) => {
    if (options.isLegacyDesign) {
      import("@/app/styles/legacy/index.css");
      return;
    }

    import("@/app/styles/base/index.css");
    import("@/app/styles/tokens/common/index.css");

    initStylesByPlatform(options.user.platform);
  },
};
