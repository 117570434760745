import { pick } from "lodash";
import { defineStore, storeToRefs } from "pinia";

import type { ParentData } from "@/entities/parent";
import { useParentStore } from "@/entities/parent";
import { useUserStore } from "@/entities/user";
import { safeJsonParse } from "@/shared/lib";
import { appStorage } from "@/shared/services";

const NAMESPACE = "app";

export const useAppStore = defineStore(NAMESPACE, () => {
  const parentStore = useParentStore();
  const { parentData } = storeToRefs(parentStore);
  const { changeParentData } = parentStore;

  const userStore = useUserStore();
  const { user } = storeToRefs(userStore);
  const { changeUser } = userStore;

  const init = async () => {
    if (window.billingBridge || window.webkit) {
      await waitForProperty("billingUserData", 5_000, changeUser);
      return;
    }

    const data = await waitForData();

    changeParentData("accessToken", data?.payload?.accessToken ?? "");
    changeParentData("closingButton", data?.payload?.closingButton ?? true);
    changeParentData("experiments", data?.payload?.experiments ?? []);
    changeParentData("host", data?.payload?.host ?? "");
    changeParentData("xOrigin", data?.payload?.xOrigin ?? "");

    const { language } = new Intl.Locale(data?.payload?.locale ?? "en-US");

    changeUser({
      ...user.value,
      ...pick(data?.payload ?? [], ["currency", "paymentGeo", "platform"]),
      lang: language,
      ...(appStorage.getUser() ?? {}),
    });
  };

  const setSession = async () => {
    if (!parentData.value.accessToken) {
      return;
    }

    await fetch("/api/internal/set-session", {
      headers: {
        Authorization: `Bearer ${parentData.value.accessToken}`,
        "Content-Type": "application/json",
      },
      method: "POST",
    });
  };

  const waitForData = async (): Promise<ParentData | void> =>
    new Promise((resolve) => {
      window.addEventListener("message", (event) => {
        const [, data] = safeJsonParse(event.data);

        if (data?.type === "loaded") {
          resolve(data);
        }

        resolve();
      });
    });

  const waitForProperty = <T>(
    property: "billingUserData",
    timeout: number,
    callback: (value: T) => void,
  ): Promise<void> => {
    const INTERVAL_TIME = 250;

    return new Promise((resolve, reject) => {
      const interval = setInterval(() => {
        if (property in window) {
          clearInterval(interval);
          callback(window[property] as T);
          resolve();
        }
      }, INTERVAL_TIME);

      setTimeout(() => {
        clearInterval(interval);
        reject("Can't find a property in window");
      }, timeout);
    });
  };

  return {
    init,
    setSession,
  };
});
