import { storeToRefs } from "pinia";
import type { Ref } from "vue";
import { computed } from "vue";

import { i18n } from "@/app/plugins";
import type { PaymentMethod, PaymentMethodWithAnnotation } from "@/entities/payment-method";
import type { Transaction } from "@/entities/transaction";
import { useUserStore } from "@/entities/user";

interface Props {
  paymentMethod: Ref<Nullable<PaymentMethod>>;
  transaction: Transaction;
}

export const useAnnotation = (props: Props) => {
  const { country } = storeToRefs(useUserStore());

  const annotation = computed(() => {
    const method = paymentMethodsWithAnnotation.value.find(
      (method) =>
        method.country === country.value &&
        method.name === props.paymentMethod?.value?.name &&
        method.transaction === props.transaction,
    );
    return method?.text ?? "";
  });

  const paymentMethodsWithAnnotation = computed<PaymentMethodWithAnnotation[]>(() => [
    {
      country: "ru",
      name: "card_rub",
      text: i18n.global.t("withdrawal.annotation.ru"),
      transaction: "withdrawal",
    },
    {
      country: "ru",
      name: "okt_okc_rub",
      text: i18n.global.t("withdrawal.annotation.ru"),
      transaction: "withdrawal",
    },
    {
      country: "ua",
      name: "card_p2p_ua_privat_bank",
      text: i18n.global.t("deposit.annotation.ua"),
      transaction: "deposit",
    },
    {
      country: "ua",
      name: "card_p2p_ua_mono_bank",
      text: i18n.global.t("deposit.annotation.ua"),
      transaction: "deposit",
    },
    {
      country: "ua",
      name: "card_p2p_ua_a_bank",
      text: i18n.global.t("deposit.annotation.ua"),
      transaction: "deposit",
    },
    {
      country: "ua",
      name: "card_p2p_ua_pumb_bank",
      text: i18n.global.t("deposit.annotation.ua"),
      transaction: "deposit",
    },
    {
      country: "ua",
      name: "card_p2p_ua_izi_bank",
      text: i18n.global.t("deposit.annotation.ua"),
      transaction: "deposit",
    },
    {
      country: "ua",
      name: "card_p2p_ua_other_bank",
      text: i18n.global.t("deposit.annotation.ua"),
      transaction: "deposit",
    },
  ]);

  return {
    annotation,
  };
};
