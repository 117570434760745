<script setup lang="ts">
import { storeToRefs } from "pinia";
import { onBeforeMount } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

import type { PaymentGroup, PaymentMethod } from "@/entities/payment-method";
import { BalanceCard, mapPaymentMethod, usePaymentMethodStore } from "@/entities/payment-method";
import { SectionTitle, SectionWrapper } from "@/entities/section";
import { useUserStore } from "@/entities/user";
import { useWithdrawalStore } from "@/entities/withdrawal";
import { useFormStore } from "@/features/form";
import { routeNames } from "@/shared/constants";
import { PaymentSystems } from "@/widgets/transaction";

const { t } = useI18n();
const router = useRouter();

const paymentMethodStore = usePaymentMethodStore();
const { initialPaymentMethods, paymentGroup, paymentMethod, limits, filteredPaymentGroups, filteredPaymentMethods } =
  storeToRefs(paymentMethodStore);
const { changePaymentGroup, changePaymentMethod, changePaymentMethods } = paymentMethodStore;

const { balance } = storeToRefs(useUserStore());

const { changeConfirmationData, changeIsRuleShown } = useWithdrawalStore();

const { changeAmount, changeAmountValidity, changeFields, setFields } = useFormStore();

const resetData = () => {
  changeAmount("");
  changeAmountValidity(true);
  changeIsRuleShown(false);
  changeConfirmationData({
    confirmationRequested: false,
    email: "",
  });
  changeIsRuleShown(false);
  changePaymentGroup(null);
  changePaymentMethod(null);
  changePaymentMethods(initialPaymentMethods.value);
  setFields([]);
};

const selectPaymentGroup = async (paymentGroup: PaymentGroup) => {
  changePaymentGroup(paymentGroup);
  changePaymentMethods(Object.values(paymentGroup.paymentMethods));
  await router.push({ name: routeNames.withdrawalGroupPage });
};

const selectPaymentMethod = async (selectedMethod: PaymentMethod) => {
  const paymentMethod = mapPaymentMethod(selectedMethod);
  changePaymentMethod(paymentMethod);
  changeFields(paymentMethod.fields);
  changeAmount(String(limits.value.min));
  await router.push({ name: routeNames.withdrawalBasePage });
};

onBeforeMount(resetData);
</script>

<template>
  <SectionWrapper class="scrollbar-hidden overflow-y-auto">
    <BalanceCard
      :balance="balance"
      class="mb-2"
    />
    <SectionTitle>{{ t("withdrawal.title") }}</SectionTitle>
    <PaymentSystems
      :payment-group="paymentGroup"
      :payment-groups="filteredPaymentGroups"
      :payment-method="paymentMethod"
      :payment-methods="filteredPaymentMethods"
      transaction="withdrawal"
      @select-payment-group="selectPaymentGroup"
      @select-payment-method="selectPaymentMethod"
    />
  </SectionWrapper>
</template>
