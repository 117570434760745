import { getRecaptchaV3 } from "@1win/billing-recaptcha-v3";
import { getRecaptchaV2 } from "@1win/recaptcha";

import { i18n } from "@/app/plugins";

interface Payload {
  action: string;
  onError?: (error: unknown) => string;
}

const defaultErrorHandler = () => i18n.global.t("error.title");

export const fetchCaptchaV2 = async (payload: Omit<Payload, "action">) => {
  const captchaResponse = await getRecaptchaV2({
    sitekey: import.meta.env.VITE_RECAPTCHA_KEY,
    onError: payload?.onError ?? defaultErrorHandler,
  });

  return {
    captchaResponse,
  };
};

export const fetchCaptchaV3 = async (payload: Payload) => {
  const captchaResponse = await getRecaptchaV2({
    sitekey: import.meta.env.VITE_RECAPTCHA_KEY,
    onError: payload?.onError ?? defaultErrorHandler,
  });

  const captchaResponseV3 = await getRecaptchaV3({
    sitekey: import.meta.env.VITE_RECAPTCHA_V3_KEY,
    action: payload.action,
    onError: payload?.onError ?? defaultErrorHandler,
  });

  return {
    captchaResponse,
    captchaResponseV3,
  };
};
