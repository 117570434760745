import io from "socket.io-client";
import type { App } from "vue";

import type { ParentData } from "@/entities/parent";
import type { User } from "@/entities/user";
import { deviceTypes } from "@/shared/constants";

interface Options extends Partial<Pick<ParentData["payload"], "accessToken" | "host" | "xOrigin">> {
  user: User;
}

const socket = io(import.meta.env.VITE_SERVER_HOST, {
  autoConnect: false,
  path: import.meta.env.VITE_SOCKET_PATH,
  reconnection: false,
  transports: ["websocket"],
});

export default {
  install: (_: App, options: Options) => {
    const deviceType = deviceTypes[options.user.platform];

    if (options.accessToken) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      socket.io.uri = undefined;
    }

    socket.io.opts.query = {
      ...(!options.accessToken && { Authorization: options.user.token }),
      Language: options.user.lang,
      xorigin: options.xOrigin || new URL(import.meta.env.VITE_SERVER_HOST).host,
      ...(deviceType && {
        deviceType,
        isNative: true,
      }),
      cashier_type: "widget",
    };

    socket.connect();
  },
};

export { socket };
