<script setup lang="ts">
import { Divider, ListItem } from "@/shared/ui-v2";

type Emits = {
  change: [value: string | number];
};

interface Props {
  items: {
    text: string;
    value: string | number;
  }[];
  value: string | number;
}

interface Slots {
  item(props: { item: Props["items"][number] }): unknown;
}

const emit = defineEmits<Emits>();

defineProps<Props>();

defineSlots<Slots>();

const onChange = (value: string | number) => emit("change", value);
</script>

<template>
  <div :class="$style.root">
    <div
      v-for="(item, index) of items"
      :key="item.value"
      :class="$style.wrapper"
    >
      <ListItem
        :item="item"
        :value="value"
        @change="onChange(item.value)"
      >
        <slot
          :item="item"
          name="item"
        />
      </ListItem>
      <Divider
        v-if="index !== items.length - 1"
        :class="$style.divider"
      />
    </div>
  </div>
</template>

<style module lang="postcss">
.root {
  max-height: var(--max-height-popover);
  box-shadow: var(--box-shadow-popover);
  background-color: var(--color-bg-elevated);
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &:hover {
    & > div {
      opacity: 0;
    }
  }
}

.divider {
  width: calc(100% - var(--spacing-12) * 2);
  margin: var(--spacing-0) var(--spacing-12);
  transition: opacity 0.25s;
}
</style>
