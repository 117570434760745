<script setup lang="ts">
import { storeToRefs } from "pinia";
import { onBeforeMount } from "vue";
import { useI18n } from "vue-i18n";

import { useDepositStore } from "@/entities/deposit";
import { usePaymentMethodStore } from "@/entities/payment-method";
import { SectionWrapper } from "@/entities/section";
import { SubmitButton, useFormStore } from "@/features/form";
import { routeNames } from "@/shared/constants";
import { ScrollShadow } from "@/shared/ui";
import { DepositForm, DepositInfo } from "@/widgets/deposit";

const { t } = useI18n();

const depositStore = useDepositStore();
const { isLoadingPrepareDeposit } = storeToRefs(depositStore);
const { onPrepare } = depositStore;

const { paymentLabel, paymentMethod } = storeToRefs(usePaymentMethodStore());

const { changeFields } = useFormStore();

onBeforeMount(() => changeFields([]));
</script>

<template>
  <SectionWrapper>
    <ScrollShadow class="flex flex-col gap-6">
      <DepositInfo :icon="paymentMethod?.name">
        <template #text>
          {{ paymentLabel }}
        </template>
      </DepositInfo>
      <DepositForm />
    </ScrollShadow>
    <SubmitButton
      class="mt-auto"
      :is-loading="isLoadingPrepareDeposit"
      @click="onPrepare(routeNames.depositIndiaPaymentPage)"
    >
      {{ t("deposit.button") }}
    </SubmitButton>
  </SectionWrapper>
</template>
