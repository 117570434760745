<script setup lang="ts">
import { computed, ref } from "vue";

import { transitions } from "@/shared/constants";
import type { Size, TailwindClass, Validations } from "@/shared/ui";
import { Button, Icon, Typography } from "@/shared/ui";

type Emits = {
  blur: [];
  change: [value: string];
  clear: [];
  focus: [];
  input: [event: Event];
};

interface Props {
  fullWidth?: boolean;
  hasErrorMessage?: boolean;
  inputMode?: "none" | "text" | "tel" | "url" | "email" | "numeric" | "decimal" | "search";
  isClearable?: boolean;
  isDisabled?: boolean;
  label?: string;
  placeholder?: string;
  size?: Extract<Size, "sm" | "md">;
  type?: "email" | "number" | "password" | "search" | "tel" | "text" | "url";
  validations?: Validations[];
  value: string;
  variant?: "bordered" | "flat";
}

interface Slots {
  "content-left"(props: object): unknown;
}

const emit = defineEmits<Emits>();

const props = withDefaults(defineProps<Props>(), {
  fullWidth: false,
  hasErrorMessage: true,
  inputMode: "text",
  isClearable: false,
  isDisabled: false,
  label: "",
  placeholder: "",
  size: "md",
  type: "text",
  validations: () => [
    {
      isExists: false,
      message: "",
      regex: "",
    },
  ],
  variant: "bordered",
});

defineSlots<Slots>();

const sizes: Record<NotUndefined<Props["size"]>, TailwindClass> = {
  sm: "h-9 py-2.5 px-3",
  md: "h-12 py-3.25 px-4",
};

const variants: Record<
  NotUndefined<Props["variant"]>,
  {
    active: TailwindClass;
    common: TailwindClass;
    error: TailwindClass;
  }
> = {
  bordered: {
    active: "border border-blue-cyan-500 bg-gray-50 dark:border-sapphire dark:bg-oxford",
    common: "border border-gray-50 bg-gray-50 dark:border-oxford dark:bg-oxford",
    error: "border border-orange-525 bg-gray-50 dark:border-ruby dark:bg-ruby/[.1]",
  },
  flat: {
    active: "bg-gray-50 dark:bg-oxford",
    common: "bg-gray-50 dark:bg-oxford",
    error: "bg-orange-525/[.1] dark:bg-ruby/[.1]",
  },
};

const inputRef = ref<Nullable<HTMLInputElement>>(null);
const isActive = ref(false);

const hasError = computed(() => props.validations.some((validation) => validation.isExists));

const onBlur = () => emit("blur");
const onClear = () => emit("clear");
const onFocus = () => emit("focus");
const onInput = (event: Event) => emit("input", event);

const changeIsActive = (value: boolean) => {
  isActive.value = value;
};

const handleBlur = (ref: Nullable<HTMLInputElement>) => {
  ref?.blur();
  onBlur();
  changeIsActive(false);
};

const handleFocus = (ref: Nullable<HTMLInputElement>) => {
  ref?.focus();
  onFocus();
  changeIsActive(true);
};
</script>

<template>
  <div
    class="flex flex-col gap-1"
    :class="[...(fullWidth ? ['w-full'] : ['w-auto'])]"
  >
    <button
      class="highlight-none relative flex cursor-text gap-2 rounded-xl outline-none transition"
      :class="[
        ...(label ? ['items-baseline'] : ['items-center']),
        sizes[size],
        ...(!isActive && !hasError ? [variants[variant].common] : []),
        ...(isActive ? [variants[variant].active] : []),
        ...(hasError ? [variants[variant].error] : []),
      ]"
      data-testid="trigger-button"
      type="button"
      @focusin="handleFocus(inputRef)"
      @focusout="handleBlur(inputRef)"
    >
      <slot name="content-left" />
      <label
        v-if="label"
        class="pointer-events-none absolute max-w-[calc(100vw-4rem)] transform overflow-hidden text-ellipsis whitespace-nowrap text-gray-350 transition-all duration-300 ease-in-out dark:text-pearl"
        :class="[
          ...(isActive || value
            ? ['top-1.5', 'text-xxs', 'leading-3.25']
            : ['top-1/2', '-translate-y-1/2', 'text-2md', 'leading-5.5']),
        ]"
        for="input"
      >
        {{ label }}
      </label>
      <input
        id="input"
        ref="inputRef"
        class="w-full bg-transparent text-2md leading-5.5 text-gray-900 outline-none placeholder:text-gray-350 dark:text-white dark:placeholder:text-pearl [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-search-cancel-button]:appearance-none"
        :class="[...(label ? ['pt-1.5'] : [])]"
        :disabled="isDisabled"
        :inputmode="inputMode"
        :placeholder="placeholder"
        :type="type"
        :value="value"
        @input="onInput"
      />
      <Transition :name="transitions.fade">
        <Button
          v-if="isClearable && value"
          data-testid="clear-button"
          variant="plain"
          @click="onClear"
        >
          <Icon
            class="text-gray-350 dark:text-pearl/[.64]"
            name="circle-close"
          />
        </Button>
      </Transition>
    </button>
    <template
      v-for="validation of validations"
      :key="validation.message"
    >
      <Typography
        v-if="validation.isExists && hasErrorMessage"
        color="danger"
        size="sm"
      >
        {{ validation.message }}
      </Typography>
    </template>
  </div>
</template>
