import type { PrepareDepositDetail } from "@/entities/deposit";
import type { PaymentMethod } from "@/entities/payment-method";

const bankMethods: Readonly<PaymentMethod["name"][]> = ["bank_transfer_onewin"];

export const getDetailIcon = (detail: PrepareDepositDetail) => {
  const name = detail.name.toLowerCase();

  if (name.includes("name")) {
    return "fields/user";
  }

  if (name.includes("upi")) {
    return "fields/id";
  }

  return null;
};

export const isBank = (name: PaymentMethod["name"]) => bankMethods.includes(name);

export const isCopiedDetail = (detail: PrepareDepositDetail) => detail.type === "copied_text";

export const isQrDetail = (detail: PrepareDepositDetail) => detail.type === "QR";
