<script setup lang="ts">
import type { PaymentMethod } from "@/entities/payment-method";
import { PaymentMethodTile } from "@/entities/payment-method";
import { SearchInput } from "@/features/search";

type Emits = {
  cancelSearch: [];
  changeSearch: [value: string];
  selectPaymentMethod: [paymentMethod: PaymentMethod];
  setIsSearchActive: [value: boolean];
};

interface Props {
  isSearchActive: boolean;
  paymentMethod: Nullable<PaymentMethod>;
  search: string;
  searchedMethods: PaymentMethod[];
}

const emit = defineEmits<Emits>();

defineProps<Props>();

const onCancelSearch = () => emit("cancelSearch");
const onChangeSearch = (value: string) => emit("changeSearch", value);
const onSelectPaymentMethod = (paymentMethod: PaymentMethod) => emit("selectPaymentMethod", paymentMethod);
const onSetIsSearchActive = (value: boolean) => emit("setIsSearchActive", value);
</script>

<template>
  <div class="flex grow flex-col gap-6">
    <SearchInput
      :is-search-active="isSearchActive"
      :items="searchedMethods"
      :search="search"
      @cancel="onCancelSearch"
      @change="onChangeSearch"
      @focus="onSetIsSearchActive(true)"
      @input="(event) => onChangeSearch((event.target as HTMLInputElement).value)"
    />
    <div
      v-if="searchedMethods.length"
      class="grid grow grid-cols-3 content-baseline gap-2 pr-1 pt-1 md:grid-cols-4 lg:grid-cols-6"
    >
      <PaymentMethodTile
        v-for="item of searchedMethods"
        :key="item.name"
        class="justify-between text-center"
        :is-active="item.name === paymentMethod?.name"
        :payment-method="item"
        transaction="withdrawal"
        @select="onSelectPaymentMethod(item)"
      />
    </div>
  </div>
</template>
