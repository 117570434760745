import type { Ref } from "vue";
import { computed } from "vue";

import { i18n } from "@/app/plugins";
import type { ImageGuideContent, PaymentMethod, PaymentMethodWithInstruction } from "@/entities/payment-method";
import FKWalletInstructionOne from "@/shared/assets/images/instructions/payment/fk-wallet/1.png";
import FKWalletInstructionTwo from "@/shared/assets/images/instructions/payment/fk-wallet/2.png";
import FKWalletInstructionThree from "@/shared/assets/images/instructions/payment/fk-wallet/3.png";
import SteamCoinInstructionOne from "@/shared/assets/images/instructions/payment/steam-coin/1.png";

interface Props {
  paymentMethod: Ref<Nullable<PaymentMethod>>;
}

export const usePaymentInstruction = (props: Props) => {
  const instructions = computed<Record<PaymentMethodWithInstruction, ImageGuideContent[]>>(() => ({
    fk_wallet: [
      {
        description: i18n.global.t("paymentInstruction.fk_wallet.step.one.description"),
        image: FKWalletInstructionOne,
        title: i18n.global.t("paymentInstruction.fk_wallet.step.one.title"),
      },
      {
        description: i18n.global.t("paymentInstruction.fk_wallet.step.two.description"),
        image: FKWalletInstructionTwo,
        title: i18n.global.t("paymentInstruction.fk_wallet.step.two.title"),
      },
      {
        description: i18n.global.t("paymentInstruction.fk_wallet.step.three.description"),
        image: FKWalletInstructionThree,
        title: i18n.global.t("paymentInstruction.fk_wallet.step.three.title"),
      },
    ],
    steam_coin: [
      {
        description: i18n.global.t("paymentInstruction.steam_coin.step.one.description"),
        image: SteamCoinInstructionOne,
        title: i18n.global.t("paymentInstruction.steam_coin.step.one.title"),
      },
    ],
  }));

  const instruction = computed(
    () => instructions.value[(props.paymentMethod.value?.name ?? "") as PaymentMethodWithInstruction],
  );

  return {
    instruction,
  };
};
