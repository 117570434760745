<script setup lang="ts">
import { useI18n } from "vue-i18n";

import type { Translations } from "@/features/modal";
import { Button, Modal, Typography } from "@/shared/ui";

type Emits = {
  close: [];
  submit: [];
};

interface Props {
  isOpen?: boolean;
  translations: Translations;
}

const emit = defineEmits<Emits>();

withDefaults(defineProps<Props>(), {
  isOpen: false,
});

const { t } = useI18n();

const onClose = () => emit("close");
const onSubmit = () => emit("submit");
</script>

<template>
  <Modal
    :is-open="isOpen"
    @close="onClose"
  >
    <div
      class="flex w-80 flex-col gap-4 overflow-hidden rounded-2xl bg-white px-6 pb-3 pt-6 text-gray-900 dark:bg-oxford dark:text-white"
    >
      <Typography
        size="xl"
        variant="h3"
        weight="bold"
      >
        {{ translations.title }}
      </Typography>
      <Typography class="text-gray-350 dark:text-pearl">
        {{ translations.text }}
      </Typography>
      <div class="flex justify-end">
        <Button
          color="auto"
          data-testid="close-button"
          size="sm"
          variant="light"
          @click="onClose"
        >
          {{ t("button.remain") }}
        </Button>
        <Button
          color="danger"
          data-testid="submit-button"
          size="sm"
          variant="light"
          @click="onSubmit"
        >
          {{ t("button.complete") }}
        </Button>
      </div>
    </div>
  </Modal>
</template>
