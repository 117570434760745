import type {
  Withdrawal,
  WithdrawalConfirmationPayload,
  WithdrawalConfirmationResponse,
  WithdrawalPayload,
  WithdrawalPrepareResponse,
  WithdrawalPreparePayload,
} from "@/entities/withdrawal";
import { client } from "@/shared/api";

export const createWithdrawal = async (payload: WithdrawalPayload) => {
  const { data } = await client.send<Withdrawal, WithdrawalPayload>("BANKING:withdrawal-create", payload);
  return data;
};

export const confirmWithdrawal = async (payload: WithdrawalConfirmationPayload) => {
  const { data } = await client.send<WithdrawalConfirmationResponse, WithdrawalConfirmationPayload>(
    "BANKING:withdrawal-confirm",
    payload,
  );
  return data;
};

export const prepareWithdrawal = async (payload: WithdrawalPreparePayload) => {
  const { data } = await client.send<WithdrawalPrepareResponse, WithdrawalPreparePayload>(
    "BANKING:withdrawal-prepare",
    payload,
  );
  return data;
};
