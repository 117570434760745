<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, ref } from "vue";

import { transitions } from "@/shared/constants";
import type { Validations } from "@/shared/ui";
import { Divider, Icon, Overlay, Typography } from "@/shared/ui";

type Emits = {
  change: [value: string | number];
};

interface Props {
  items: {
    text: string;
    value: string | number;
  }[];
  label: string;
  validations?: Validations[];
  value?: string | number;
}

interface Coords {
  left: string;
  top: string;
}

const emit = defineEmits<Emits>();

const props = withDefaults(defineProps<Props>(), {
  validations: () => [
    {
      isExists: false,
      message: "",
      regex: "",
    },
  ],
  value: "",
});

const coords = ref<Coords>({
  left: "0px",
  top: "0px",
});
const isActive = ref(false);
const observer = ref<Nullable<MutationObserver>>(null);
const triggerRef = ref<Nullable<HTMLButtonElement>>(null);

const activeItem = computed(() => props.items.find(({ value }) => value === props.value));

const hasError = computed(() => props.validations.some((validation) => validation.isExists));

const onChange = (value: string | number) => emit("change", value);

const changeIsActive = (value: boolean) => {
  isActive.value = value;
};

const changeValue = (ref: Nullable<HTMLButtonElement>, value: string | number) => {
  onChange(value);
  changeIsActive(false);
  handleBlur(ref);
};

const changeCoords = (value: Coords) => {
  coords.value = value;
};

const handleClick = (ref: Nullable<HTMLButtonElement>) => {
  changeIsActive(true);
  handleFocus(ref);
};

const handleBlur = (ref: Nullable<HTMLButtonElement>) => {
  ref?.blur();
};

const handleFocus = (ref: Nullable<HTMLButtonElement>) => {
  ref?.focus();
};

const updateCoords = () => {
  const rect = triggerRef.value?.getBoundingClientRect();
  changeCoords({
    top: `${rect?.bottom ?? 0}px`,
    left: `${rect?.left ?? 0}px`,
  });
};

onBeforeUnmount(() => {
  observer.value?.disconnect();
});

onMounted(() => {
  if (triggerRef.value) {
    observer.value = new MutationObserver(updateCoords);
    observer.value.observe(triggerRef.value, { attributes: true, childList: true, subtree: true });
  }
});
</script>

<template>
  <div class="relative">
    <div class="flex flex-col gap-1">
      <button
        ref="triggerRef"
        class="highlight-none relative flex h-12 w-full items-center justify-between gap-4 rounded-xl border px-4 py-3.25 outline-none"
        :class="[
          ...(!isActive && !hasError ? ['border-gray-50', 'bg-gray-50', 'dark:border-oxford', 'dark:bg-oxford'] : []),
          ...(isActive ? ['border-blue-cyan-500', 'bg-gray-50', 'dark:bg-oxford', 'dark:border-sapphire'] : []),
          ...(hasError ? ['border-orange-525', 'bg-gray-50', 'dark:border-ruby', 'dark:bg-ruby/[.1]'] : []),
        ]"
        data-testid="trigger"
        type="button"
        @click="handleClick(triggerRef)"
      >
        <Typography
          class="absolute max-w-[calc(100vw-6rem)] transform overflow-hidden text-ellipsis whitespace-nowrap text-gray-350 transition-all duration-300 ease-in-out dark:text-pearl"
          :class="[...(isActive || value ? ['top-1.5'] : ['top-1/2', '-translate-y-1/2'])]"
          data-testid="label"
          :size="isActive || value ? 'xs' : 'lg'"
          variant="span"
        >
          {{ label }}
        </Typography>
        <Typography
          class="pt-4"
          data-testid="item-text"
          size="lg"
          variant="span"
        >
          {{ activeItem?.text }}
        </Typography>
        <Icon
          class="transform text-gray-350 transition dark:text-pearl"
          :class="[...(isActive ? ['rotate-180'] : ['rotate-0'])]"
          name="chevron-down"
        />
      </button>
      <template
        v-for="validation of validations"
        :key="validation.message"
      >
        <Typography
          v-if="validation.isExists"
          color="danger"
          data-testid="error-message"
          size="sm"
        >
          {{ validation.message }}
        </Typography>
      </template>
    </div>
    <Teleport to="body">
      <Transition :name="transitions.fade">
        <Overlay
          v-if="isActive"
          @close="changeValue(triggerRef, value)"
        />
      </Transition>
      <Transition :name="transitions.scale">
        <div
          v-if="isActive"
          class="absolute z-30 max-h-[theme(space.60)] w-full max-w-xxs overflow-y-auto bg-transparent text-gray-900 dark:text-white"
          data-testid="list"
          :style="coords"
        >
          <div
            v-for="(item, index) of items"
            :key="item.value"
            class="bg-white transition first:rounded-t-xl last:rounded-b-xl hover:bg-gray-50 dark:bg-pebble dark:hover:bg-dove"
          >
            <button
              class="highlight-none inline-flex w-full items-center justify-between gap-2 px-4 py-2.75 text-left outline-none"
              type="button"
              @click="changeValue(triggerRef, item.value)"
            >
              <Typography
                size="lg"
                variant="span"
              >
                {{ item.text }}
              </Typography>
              <Icon
                v-if="item.value === value"
                class="text-gray-900 dark:text-white"
                name="check"
              />
            </button>
            <Divider
              v-if="index !== items.length - 1"
              class="bg-gray-225 dark:bg-shadow/[.65]"
              size="sm"
            />
          </div>
        </div>
      </Transition>
    </Teleport>
  </div>
</template>
