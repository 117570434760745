<script setup lang="ts">
import { storeToRefs } from "pinia";
import { computed, onUnmounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";

import type { Limits } from "@/entities/payment-method";
import { useUserStore } from "@/entities/user";
import type { User } from "@/entities/user";
import {
  LENGTH_OF_AMOUNT_WITH_EXTRA_CHAR_AT_END,
  LENGTH_OF_NUMBER_WITH_SEPARATOR,
  NUMBER_WITH_COMMAS_REGEX,
  NUMBER_WITH_DOT_REGEX,
  useFormStore,
  useIntlSeparators,
  extractAmount,
} from "@/features/form";
import { formatNumber, getCurrencyFractionDigits } from "@/shared/lib";
import { Input } from "@/shared/ui-v2";

type Emits = {
  focus: [];
  input: [value: string];
};

interface Props {
  currency: User["currency"];
  limits: Limits;
  value: string;
}

const emit = defineEmits<Emits>();

const props = defineProps<Props>();

const { t } = useI18n();

const { user } = storeToRefs(useUserStore());

const { isAmountValid } = storeToRefs(useFormStore());

const caretPosition = ref(0);
const inputRef = ref<Nullable<HTMLInputElement>>(null);

const { decimalSeparator, currencySeparator, isCurrencyAtEnd, isCorrectAmountWithSeparators } = useIntlSeparators(
  user.value.lang,
  props.currency,
);

let caretPositionTimeout: NodeJS.Timeout;

const validators = {
  isLeadingDot: (value: string) => value === ".",
  isNonDecimalLeadingZero: (value: string) => {
    const [first, second] = value;
    return first === "0" && value.length > 1 && second !== ".";
  },
  isInvalidOrExceedsLimit: (value: string) => {
    const isValid = (value: string) => {
      const max = getCurrencyFractionDigits(props.currency);
      const isFullDecimalPart =
        value.replace(amountRegExpWithCurrentSeparators.value, "").split(decimalSeparator)[1]?.length > max;

      return isCorrectAmountWithSeparators(max, value, isFullDecimalPart);
    };

    const numericValue = value.replace(amountRegExpWithCurrentSeparators.value, "");
    const minValueLength = `${Math.floor(
      isCommaDecimalSeparator.value ? +numericValue.replace(/,/g, ".") : +numericValue,
    )}`.length;
    const maxDigitsLength = `${Math.floor(props.limits.max)}`.length + 1;

    return !isValid(value) || minValueLength > maxDigitsLength;
  },
};

const amountRegExpWithCurrentSeparators = computed(() =>
  decimalSeparator === "." ? NUMBER_WITH_COMMAS_REGEX : NUMBER_WITH_DOT_REGEX,
);
const isCommaDecimalSeparator = computed(() => decimalSeparator === ",");

const errorText = computed(() => {
  const [min, max] = modifiedLimits.value;

  if (+props.value < props.limits.min) {
    return t("form.amountInput.limit.min", { amount: min });
  }

  if (+props.value > props.limits.max) {
    return t("form.amountInput.limit.max", { amount: max });
  }

  return "";
});

const hintMessage = computed(() => {
  const [min, max] = modifiedLimits.value;

  return t("form.amountInput.hint", { from: min, to: max });
});

const modifiedAmount = computed(() => {
  if (!props.value) {
    return "";
  }

  const [, fractionalPart = ""] = props.value.split(".");

  const formattedValue = formatNumber(
    {
      currency: props.currency,
      minimumFractionDigits: fractionalPart.length,
    },
    props.value.endsWith(".") ? +props.value.slice(0, -1) : +props.value,
    user.value.lang,
  );

  if (isCurrencyAtEnd()) {
    return props.value.endsWith(".")
      ? `${extractAmount(formattedValue)}${decimalSeparator} ${currencySeparator}`
      : formattedValue;
  }

  return props.value.endsWith(".") ? formattedValue + decimalSeparator : formattedValue;
});

const modifiedLimits = computed(() =>
  [props.limits.min, props.limits.max].map((limit) =>
    formatNumber({ currency: props.currency }, limit, user.value.lang),
  ),
);

const onFocus = () => emit("focus");
const onInput = (value: string) => emit("input", value);

const handleInput = (event: Event) => {
  const value = (event as Event & { target: HTMLInputElement }).target.value;

  caretPosition.value = (event.target as HTMLInputElement).selectionEnd ?? 0;

  if (
    validators.isLeadingDot(value) ||
    validators.isNonDecimalLeadingZero(value) ||
    validators.isInvalidOrExceedsLimit(value)
  ) {
    let slicedValue: string;

    if (isCurrencyAtEnd()) {
      slicedValue = value.split(currencySeparator)[0].trim();

      if (value.split(currencySeparator).filter(Boolean).length === LENGTH_OF_AMOUNT_WITH_EXTRA_CHAR_AT_END) {
        slicedValue = slicedValue + value.slice(-1);
      }

      slicedValue = slicedValue.slice(0, -1);
      // eslint-disable-next-line no-param-reassign
      (event as Event & { target: HTMLInputElement }).target.value =
        extractAmount(slicedValue) + " " + currencySeparator;
    } else {
      slicedValue = value.slice(0, -1);
      // eslint-disable-next-line no-param-reassign
      (event as Event & { target: HTMLInputElement }).target.value = slicedValue;
    }

    sanitizeInput(slicedValue);
    return;
  }

  sanitizeInput(value);
};

const handleKeydown = (event: KeyboardEvent) => {
  const allowedKeys = ["Backspace", "Delete", "ArrowLeft", "ArrowRight", "ArrowUp", "ArrowDown", "Tab", "Home", "End"];
  const shortcutKeys = ["a", "c", "v", "x"];

  const isNumber = new RegExp(
    modifiedAmount.value.includes(decimalSeparator) ? /^[0-9]$/ : `^[0-9${decimalSeparator}]$`,
  ).test(event.key);
  const isShortcut = event.ctrlKey && shortcutKeys.includes(event.key);

  if (!isNumber && !allowedKeys.includes(event.key) && !isShortcut) {
    event.preventDefault();
  }
};

const sanitizeInput = (value: string) => {
  const sanitizedValue = value.replace(amountRegExpWithCurrentSeparators.value, "");

  if (isCommaDecimalSeparator.value) {
    onInput(sanitizedValue.replace(/,/g, "."));
    return;
  }

  onInput(sanitizedValue);
};

const setRef = (el: HTMLInputElement) => {
  inputRef.value = el;
};

const moveCursor = (range: number) => {
  inputRef.value?.setSelectionRange(range, range);
};

watch(
  () => modifiedAmount.value,
  (newValue, oldValue) => {
    let newCaretPosition = caretPosition.value;

    if (newValue.length - LENGTH_OF_NUMBER_WITH_SEPARATOR === oldValue.length) {
      newCaretPosition += 1;
    }

    if (newValue.length + LENGTH_OF_NUMBER_WITH_SEPARATOR === oldValue.length) {
      newCaretPosition -= 1;
    }

    caretPositionTimeout = setTimeout(() => moveCursor(newCaretPosition), 0);
  },
);

onUnmounted(() => {
  clearTimeout(caretPositionTimeout);
});
</script>

<template>
  <Input
    :ref="(el) => setRef((el as any)?.ref)"
    :hint-message="hintMessage"
    input-mode="numeric"
    :label="t('form.amountInput.title')"
    :validations="[
      {
        isExists: !isAmountValid,
        message: errorText,
        regex: '*',
      },
    ]"
    :value="modifiedAmount"
    @focus="onFocus"
    @input="handleInput"
    @keydown="handleKeydown"
  />
</template>
