<script setup lang="ts">
import { useI18n } from "vue-i18n";

import type { Translations } from "@/features/modal";
import { Button, Divider, Modal, Typography } from "@/shared/ui";

type Emits = {
  close: [];
  submit: [];
};

interface Props {
  isOpen?: boolean;
  translations: Translations;
}

const emit = defineEmits<Emits>();

withDefaults(defineProps<Props>(), {
  isOpen: false,
});

const { t } = useI18n();

const onClose = () => emit("close");
const onSubmit = () => emit("submit");
</script>

<template>
  <Modal
    :is-open="isOpen"
    @close="onClose"
  >
    <div class="w-72 overflow-hidden rounded-2xl bg-white text-gray-900 dark:bg-oxford/[.75] dark:text-white">
      <div class="flex flex-col gap-1.5 px-4 py-5 text-center">
        <Typography
          size="lg"
          variant="h3"
          weight="bold"
        >
          {{ translations.title }}
        </Typography>
        <Typography size="sm">
          {{ translations.text }}
        </Typography>
      </div>
      <Divider
        class="bg-gray-225 dark:bg-shadow/[.65]"
        size="sm"
      />
      <div class="flex justify-center">
        <Button
          color="secondary"
          data-testid="close-button"
          full-width
          variant="light"
          @click="onClose"
        >
          {{ t("button.remain") }}
        </Button>
        <Divider
          class="shrink-0 bg-gray-225 dark:bg-shadow/[.65]"
          orientation="vertical"
          size="sm"
        />
        <Button
          color="secondary"
          data-testid="submit-button"
          full-width
          variant="light"
          @click="onSubmit"
        >
          {{ t("button.complete") }}
        </Button>
      </div>
    </div>
  </Modal>
</template>
