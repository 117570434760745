import type { TransactionStatusKey, TransactionStatusValues } from "@/entities/transaction";
import { isProduction } from "@/shared/lib";

export const DEPOSIT_EVENT_TOKEN = isProduction
  ? "a0b6f0c4-4923-470b-a1da-6aa834d5d64d"
  : "5dc83d67-4159-4399-a23c-796dc4463f3e";
export const WITHDRAWAL_EVENT_TOKEN = isProduction
  ? "72d3fca9-3d07-4d66-b47e-a6248ca7345d"
  : "7aa733cd-d1c2-4770-9d0b-ff36629fdd86";

export const transactionStatuses: Readonly<Record<TransactionStatusKey, TransactionStatusKey>> = {
  accepted: "accepted",
  mistaked: "mistaked",
  processed: "processed",
  waiting: "waiting",
};

export const transactionStatusValues: Readonly<TransactionStatusValues> = {
  1: "processed",
  2: "mistaked",
};
