<script setup lang="ts">
import { useI18n } from "vue-i18n";

import { Paragraph } from "@/shared/ui-v2";

const { t } = useI18n();
</script>

<template>
  <div :class="$style.root">
    <Paragraph color="secondary">
      {{ t("search.text") }}
    </Paragraph>
  </div>
</template>

<style module lang="postcss">
.root {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}
</style>
