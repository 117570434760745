import { storeToRefs } from "pinia";

import { useUserStore } from "@/entities/user";
import { bridgeService } from "@/shared/services";

export const isProduction = import.meta.env.MODE.includes("production");

export const changeLocation = (href: string) => {
  window.location.href = href;
};

export const safeJsonParse = (value: string) => {
  try {
    return [null, JSON.parse(value)];
  } catch (err) {
    return [err];
  }
};

export const copyToClipboard = async (copiedMessage: string) => {
  const { isNativePlatform } = storeToRefs(useUserStore());

  if (isNativePlatform.value) {
    bridgeService.notify({ messageType: "onCopyToBuffer", copiedMessage });
    return;
  }

  await navigator.clipboard.writeText(copiedMessage);
};
