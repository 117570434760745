<script setup lang="ts">
import { computed, ref } from "vue";

import type { CssClass } from "@/shared/ui-v2";
import { Button, Caption, IconBox } from "@/shared/ui-v2";

type Emits = {
  change: [value: string];
  clear: [];
  delete: [];
  focus: [];
  input: [event: Event];
  paste: [];
};

interface Props {
  hintMessage?: string;
  inputMode?: "text" | "numeric";
  isClearable?: boolean;
  isDisabled?: boolean;
  label?: string;
  labelClass?: CssClass;
  placeholder?: string;
  type?: "text" | "number";
  validations?: {
    isExists: boolean;
    message: string;
    regex: string;
  }[];
  value: string;
}

interface Slots {
  contentLeft(props: object): unknown;
}

const emit = defineEmits<Emits>();

const props = withDefaults(defineProps<Props>(), {
  hintMessage: "",
  inputMode: "text",
  isClearable: false,
  isDisabled: false,
  label: "",
  labelClass: "",
  placeholder: "",
  type: "text",
  validations: () => [
    {
      isExists: false,
      message: "",
      regex: "",
    },
  ],
});

defineSlots<Slots>();

const inputRef = ref<HTMLInputElement>();
const isFocused = ref(false);
const isHovered = ref(false);

const hasError = computed(() => props.validations.some((validation) => validation.isExists));

const onClear = () => emit("clear");
const onDelete = () => emit("delete");
const onFocus = () => emit("focus");
const onInput = (event: Event) => emit("input", event);
const onPaste = () => emit("paste");

const changeFocus = (value: boolean) => {
  isFocused.value = value;
};

const changeHover = (value: boolean) => {
  isHovered.value = value;
};

const handleFocus = (value: boolean) => {
  onFocus();
  changeFocus(value);
};

defineExpose({
  ref: inputRef,
});
</script>

<template>
  <div :class="$style.root">
    <label
      :class="[
        $style.wrapper,
        isDisabled && $style.disabled,
        isFocused && $style.focus,
        isHovered && !isDisabled && $style.hover,
        hasError && $style.error,
        labelClass,
      ]"
      @mouseenter="changeHover(true)"
      @mouseleave="changeHover(false)"
    >
      <span :class="$style.inner">
        <slot name="contentLeft" />
        <span
          v-if="label"
          :class="[$style.label, (isFocused || value) && $style.active]"
        >
          {{ label }}
        </span>
        <input
          ref="inputRef"
          :class="[$style.input, (isFocused || value) && label && $style.active]"
          :disabled="isDisabled"
          :inputmode="inputMode"
          :placeholder="placeholder"
          :type="type"
          :value="value"
          @blur="handleFocus(false)"
          @focus="handleFocus(true)"
          @input="onInput"
          @keydown.delete="onDelete"
          @paste="onPaste"
        />
      </span>
      <Button
        v-if="value && isClearable"
        radius="max"
        variant="plain"
        @click="onClear"
      >
        <IconBox
          color="tertiary"
          name="circle-close"
          size="s"
        />
      </Button>
    </label>
    <div
      v-if="hintMessage || hasError"
      :class="$style.container"
    >
      <Caption
        v-if="hintMessage"
        color="secondary"
      >
        {{ hintMessage }}
      </Caption>
      <template
        v-for="validation of validations"
        :key="validation.message"
      >
        <Caption
          v-if="validation.isExists"
          color="negative"
        >
          {{ validation.message }}
        </Caption>
      </template>
    </div>
  </div>
</template>

<style module lang="postcss">
.root {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-4);
}

.wrapper {
  position: relative;
  display: flex;
  align-items: center;
  gap: var(--spacing-12);
  height: var(--size-input-base);
  padding: var(--spacing-12) var(--spacing-16);
  border: 1px solid transparent;
  border-radius: var(--rounding-input);
  cursor: text;
  background-color: var(--color-control-neutral);
  color: var(--color-fg-primary);

  &.disabled {
    cursor: default;
    opacity: var(--opacity-item-disabled);
  }

  &.focus {
    opacity: var(--opacity-item-active);
    border: 1px solid var(--color-border-focus);
  }

  &.error {
    border: 1px solid var(--color-negative);
  }

  &.hover {
    background-color: var(--color-control-neutral-hover);
  }
}

.inner {
  display: flex;
  align-items: center;
  gap: var(--spacing-12);
  width: 100%;
}

.label {
  @add-mixin body-regular;
  @add-mixin text-ellipsis;

  position: absolute;
  top: 50%;
  left: var(--spacing-16);
  transform: translateY(-50%);
  max-width: calc(100vw - var(--spacing-16) * 4);
  transition:
    top 0.25s,
    transform 0.25s,
    font-size 0.25s;
  pointer-events: none;
  color: var(--color-fg-secondary);

  &.active {
    @add-mixin caption-xs;

    top: var(--spacing-2);
    transform: none;
  }
}

.input {
  @add-mixin body-regular;

  width: 100%;
  outline: none;
  background-color: transparent;
  color: var(--color-fg-primary);

  &:disabled {
    color: var(--color-fg-secondary);
  }

  &.active {
    padding-top: var(--spacing-12);
  }
}

.container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-4);
}
</style>
