<script setup lang="ts">
import { useI18n } from "vue-i18n";

import type { Balance } from "@/entities/user";
import { formatNumber, getCurrencyFractionDigits } from "@/shared/lib";
import { Card, Icon, Typography } from "@/shared/ui";

interface Props {
  balance?: Balance;
}

defineProps<Props>();

const { t } = useI18n();
</script>

<template>
  <Card class="highlight-none flex items-center justify-between p-4">
    <div class="flex items-center gap-4">
      <Icon
        class="h-8 w-8 text-gray-350 dark:text-pearl"
        name="wallet"
      />
      <div class="grid gap-y-0.5">
        <Typography
          class="text-xs text-gray-350 dark:text-pearl"
          size="auto"
        >
          {{ t("withdrawal.info.withdrawalFrom") }}
        </Typography>
        <Typography>{{ balance?.isPrimary ? t("withdrawal.type.main") : t("withdrawal.type.additional") }}</Typography>
      </div>
    </div>
    <Typography
      v-if="balance"
      variant="span"
    >
      {{
        formatNumber(
          {
            currency: balance.currency,
            minimumFractionDigits: getCurrencyFractionDigits(balance.currency),
          },
          balance.amount,
        )
      }}
    </Typography>
  </Card>
</template>
