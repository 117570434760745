<script setup lang="ts">
import { useI18n } from "vue-i18n";

import { Button, Icon, Input, Typography } from "@/shared/ui";

type Emits = {
  blur: [];
  cancel: [];
  change: [value: string];
  focus: [];
  input: [event: Event];
};

interface Props {
  isSearchActive: boolean;
  items: unknown[];
  search: string;
}

const emit = defineEmits<Emits>();

defineProps<Props>();

const { t } = useI18n();

const onBlur = () => emit("blur");
const onCancel = () => emit("cancel");
const onChange = (value: string) => emit("change", value);
const onFocus = () => emit("focus");
const onInput = (event: Event) => emit("input", event);
</script>

<template>
  <div
    class="flex flex-col gap-4"
    :class="[...(items.length ? [] : ['grow'])]"
  >
    <div class="flex items-center gap-3">
      <Input
        full-width
        is-clearable
        :placeholder="t('deposit.group.search.placeholder')"
        size="sm"
        :value="search"
        variant="flat"
        @blur="onBlur"
        @clear="onChange('')"
        @focus="onFocus"
        @input="onInput"
      >
        <template #content-left>
          <Icon
            class="h-4 w-4 text-gray-350 dark:text-pearl"
            name="magnify"
          />
        </template>
      </Input>
      <Button
        v-if="isSearchActive"
        color="secondary"
        variant="plain"
        @click="onCancel"
      >
        {{ t("button.cancel") }}
      </Button>
    </div>
    <div
      v-if="!items.length"
      class="flex h-full flex-col items-center justify-center gap-3"
    >
      <Icon
        class="text-gray-350 dark:text-pearl/[.64]"
        name="magnify"
      />
      <Typography
        size="lg"
        variant="h3"
        weight="bold"
      >
        {{ t("deposit.group.search.title") }}
      </Typography>
      <Typography class="text-gray-350 dark:text-pearl">
        {{ t("deposit.group.search.text") }}
      </Typography>
    </div>
  </div>
</template>
