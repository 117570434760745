import type { RouteLocationNormalized, Router } from "vue-router";

export const handlePageTransition = async (route: RouteLocationNormalized, router: Router, callback: () => void) => {
  const { backRoute } = route.meta;

  if (!backRoute) {
    callback();
    return;
  }

  await router.push({ name: backRoute });
};
