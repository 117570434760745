<script setup lang="ts">
import { storeToRefs } from "pinia";
import { computed, onBeforeMount } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

import { usePaymentMethodStore } from "@/entities/payment-method";
import { useThemeStore } from "@/entities/theme";
import { transactionStatuses } from "@/entities/transaction";
import { useWithdrawalStore } from "@/entities/withdrawal";
import { CompletePage, CompletePageV2 } from "@/pages";
import { routeNames } from "@/shared/constants";

const { t } = useI18n();
const router = useRouter();

const { paymentGroup } = storeToRefs(usePaymentMethodStore());

const { isLegacyDesign } = storeToRefs(useThemeStore());

const withdrawalStore = useWithdrawalStore();
const { apiResponse, transactionStatus } = storeToRefs(withdrawalStore);
const { changeTransactionStatus } = withdrawalStore;

const translations = computed(() => ({
  amount: t("withdrawal.complete.amount"),
  date: t("withdrawal.complete.date"),
  paymentMethod: t("withdrawal.complete.paymentMethod"),
  status: {
    accepted: {
      additionalButton: t("withdrawal.complete.status.accepted.additionalButton"),
      mainButton: t("withdrawal.complete.status.accepted.mainButton"),
      text: t("withdrawal.complete.status.accepted.text"),
      title: t("withdrawal.complete.status.accepted.title"),
    },
    mistaked: {
      additionalButton: null,
      mainButton: t("withdrawal.complete.status.mistaked.button"),
      text: t("withdrawal.complete.status.mistaked.text"),
      title: t("withdrawal.complete.status.mistaked.title"),
    },
    processed: {
      additionalButton: t("withdrawal.complete.status.processed.additionalButton"),
      mainButton: t("withdrawal.complete.status.processed.mainButton"),
      text: t("withdrawal.complete.status.processed.text"),
      title: t("withdrawal.complete.status.processed.title"),
    },
    waiting: {
      additionalButton: null,
      mainButton: t("withdrawal.complete.status.waiting.button"),
      text: t("withdrawal.complete.status.waiting.text"),
      title: t("withdrawal.complete.status.waiting.title"),
    },
  },
}));

const translationsV2 = computed(() => ({
  accepted: {
    button: t("withdrawal.complete.statusV2.accepted.button"),
    text: t("withdrawal.complete.statusV2.accepted.text"),
    title: t("withdrawal.complete.statusV2.accepted.title"),
  },
  mistaked: {
    button: t("withdrawal.complete.statusV2.mistaked.button"),
    text: t("withdrawal.complete.statusV2.mistaked.text"),
    title: t("withdrawal.complete.statusV2.mistaked.title"),
  },
  processed: {
    button: t("withdrawal.complete.statusV2.processed.button"),
    title: t("withdrawal.complete.statusV2.processed.title"),
  },
  waiting: {
    text: t("withdrawal.complete.statusV2.waiting.text"),
    title: t("withdrawal.complete.statusV2.waiting.title"),
  },
}));

const backToPaymentPage = () => {
  if (paymentGroup.value) {
    return router.replace({ name: routeNames.withdrawalGroupPage });
  }

  router.replace({ name: routeNames.withdrawalBasePage });
};

onBeforeMount(() => changeTransactionStatus(transactionStatuses.accepted));
</script>

<template>
  <CompletePage
    v-if="isLegacyDesign"
    :api-response="apiResponse"
    transaction="withdrawal"
    :transaction-status="transactionStatus"
    :translations="translations"
    @back-to-payment-page="backToPaymentPage"
    @change-transaction-status="changeTransactionStatus"
  />
  <CompletePageV2
    v-else
    transaction="withdrawal"
    :transaction-status="transactionStatus"
    :translations="translationsV2"
    @back-to-payment-page="backToPaymentPage"
    @change-transaction-status="changeTransactionStatus"
  />
</template>
