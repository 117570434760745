<script setup lang="ts">
import { computed } from "vue";

type Emits = {
  input: [event: Event];
};

interface Props {
  max: number;
  min: number;
  step: number;
  value: number;
}

const emit = defineEmits<Emits>();

const props = defineProps<Props>();

const backgroundSizeInput = computed(() => {
  const percentage = ((props.value - props.min) / (props.max - props.min)) * 100;

  if (percentage < 0) {
    return "0% 100%";
  }

  return `${percentage}% 100%`;
});

const onInput = (event: Event) => emit("input", event);
</script>

<template>
  <input
    class="relative bottom-0.5 h-1 cursor-pointer appearance-none rounded-md bg-gray-100 bg-gradient-to-r from-blue-cyan-500 to-blue-cyan-500 bg-no-repeat dark:bg-dove dark:from-sapphire dark:to-sapphire [&::-moz-range-thumb]:h-4 [&::-moz-range-thumb]:w-4 [&::-moz-range-thumb]:border-none [&::-moz-range-thumb]:bg-blue-cyan-500 dark:[&::-moz-range-thumb]:bg-sapphire [&::-moz-range-track]:h-2 [&::-moz-range-track]:rounded-lg [&::-webkit-slider-runnable-track]:h-2 [&::-webkit-slider-runnable-track]:rounded-lg [&::-webkit-slider-thumb]:-mt-1 [&::-webkit-slider-thumb]:h-4 [&::-webkit-slider-thumb]:w-4 [&::-webkit-slider-thumb]:appearance-none [&::-webkit-slider-thumb]:rounded-lg [&::-webkit-slider-thumb]:bg-blue-cyan-500 dark:[&::-webkit-slider-thumb]:bg-sapphire [&::-webkit]:appearance-none"
    :max="max"
    :min="min"
    :step="step"
    :style="{
      backgroundSize: backgroundSizeInput,
    }"
    type="range"
    :value="value"
    @input="onInput"
  />
</template>
