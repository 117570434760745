<script setup lang="ts">
import { storeToRefs } from "pinia";

import { useUserStore } from "@/entities/user";
import type { Color } from "@/shared/ui";
import { Button } from "@/shared/ui";

type Emits = {
  click: [];
};

interface Props {
  color?: Color;
  isDisabled?: boolean;
  isLoading?: boolean;
}

const emit = defineEmits<Emits>();

withDefaults(defineProps<Props>(), {
  color: "primary",
  isDisabled: false,
  isLoading: false,
});

const { user } = storeToRefs(useUserStore());

const onClick = () => emit("click");
</script>

<template>
  <Button
    :class="[...(user.platform === 'ios' ? ['mb-6'] : [])]"
    :color="color"
    full-width
    :is-disabled="isDisabled"
    :is-loading="isLoading"
    @click="onClick"
  >
    <slot />
  </Button>
</template>
