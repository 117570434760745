<script setup lang="ts">
import { computed } from "vue";

import type { Limits } from "@/entities/payment-method";
import type { User } from "@/entities/user";
import { generateAmountTags } from "@/features/form";
import { formatNumber } from "@/shared/lib";
import { Button } from "@/shared/ui-v2";

type Emits = {
  click: [value: string];
};

interface Props {
  currency: User["currency"];
  lang: User["lang"];
  limits: Limits;
  value: string;
}

const emit = defineEmits<Emits>();

const props = defineProps<Props>();

const DEFAULT_TAGS_COUNT = 5;

const amounts = computed(() =>
  generateAmountTags(props.limits.min, props.limits.max, DEFAULT_TAGS_COUNT)
    .filter(Boolean)
    .map((amount) => ({
      label: formatNumber({ currency: props.currency }, amount, props.lang),
      value: amount,
    })),
);

const onClick = (value: string) => emit("click", value);
</script>

<template>
  <div :class="$style.root">
    <Button
      v-for="amount of amounts"
      :key="amount.value"
      :class="$style.button"
      color="neutral"
      radius="max"
      size="s"
      @click="onClick(String(amount.value))"
    >
      {{ amount.label }}
    </Button>
  </div>
</template>

<style module lang="postcss">
.root {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.button {
  margin: var(--spacing-4);
}
</style>
