import type { RouteRecordRaw } from "vue-router";

import {
  EmptyPage,
  EmptyPageV2,
  ErrorPage,
  ErrorPageV2,
  FormPage,
  FormPageV2,
  DepositBasePage,
  DepositCompletePage,
  DepositCompletePageV2,
  DepositCryptoAddressPage,
  DepositCryptoAddressPageV2,
  DepositCryptoCoinPage,
  DepositCryptoListPageV2,
  DepositCryptoNetworkPage,
  DepositCryptoPage,
  DepositCryptoPageV2,
  DepositCustomPage,
  DepositCustomEazypePage,
  DepositCustomUpiQrPage,
  DepositGroupListPageV2,
  DepositGroupPage,
  DepositGroupPageV2,
  DepositHostToHostPageV2,
  DepositIndiaBasePage,
  DepositIndiaPaymentPage,
  DepositIndiaPage,
  DepositListPage,
  DepositListPageV2,
  DepositPage,
  DepositPageV2,
  DepositPeerToPeerBasePage,
  DepositPeerToPeerInstructionPage,
  DepositPeerToPeerInvoicePage,
  DepositPeerToPeerListPage,
  DepositPeerToPeerPage,
  DepositRedirectBasePageV2,
  DepositRedirectInstructionPageV2,
  DepositRedirectPageV2,
  WithdrawalBasePage,
  WithdrawalConfirmPage,
  WithdrawalConfirmPageV2,
  WithdrawalCompletePage,
  WithdrawalCompletePageV2,
  WithdrawalGroupListPageV2,
  WithdrawalGroupPageV2,
  WithdrawalGroupPage,
  WithdrawalListPage,
  WithdrawalListPageV2,
  WithdrawalPreparePage,
  WithdrawalPage,
  WithdrawalPageV2,
  WithdrawalNigerianBanksPage,
  WithdrawalRedirectBasePageV2,
  WithdrawalRedirectInstructionPageV2,
  WithdrawalRedirectPageV2,
} from "@/pages";
import { routeNames, routeNamesV2 } from "@/shared/constants";
import { paymentMethodMiddleware, performanceMarksMiddleware } from "@/shared/routing";

export const routes: RouteRecordRaw[] = [
  {
    path: "",
    redirect: "/deposit",
  },
  {
    component: DepositPage,
    path: "/deposit",
    beforeEnter: [performanceMarksMiddleware],
    children: [
      {
        component: DepositListPage,
        name: routeNames.depositListPage,
        path: "",
        meta: {
          depth: 0,
          transaction: "deposit",
        },
      },
      {
        beforeEnter: [paymentMethodMiddleware],
        component: DepositBasePage,
        name: routeNames.depositBasePage,
        path: "base",
        meta: {
          backRoute: routeNames.depositListPage,
          depth: 1,
          transaction: "deposit",
        },
      },
      {
        beforeEnter: [paymentMethodMiddleware],
        component: DepositCryptoPage,
        path: "crypto",
        children: [
          {
            component: DepositCryptoCoinPage,
            name: routeNames.depositCryptoCoinPage,
            path: "",
            meta: {
              backRoute: routeNames.depositListPage,
              depth: 1,
              transaction: "deposit",
            },
          },
          {
            component: DepositCryptoNetworkPage,
            name: routeNames.depositCryptoNetworkPage,
            path: "network",
            meta: {
              backRoute: routeNames.depositCryptoCoinPage,
              depth: 2,
              transaction: "deposit",
            },
          },
          {
            component: DepositCryptoAddressPage,
            name: routeNames.depositCryptoAddressPage,
            path: "address",
            meta: {
              backRoute: routeNames.depositCryptoNetworkPage,
              depth: 3,
              transaction: "deposit",
            },
          },
        ],
      },
      {
        beforeEnter: [paymentMethodMiddleware],
        component: DepositGroupPage,
        name: routeNames.depositGroupPage,
        path: "group",
        meta: {
          backRoute: routeNames.depositListPage,
          depth: 1,
          transaction: "deposit",
        },
      },
      {
        beforeEnter: [paymentMethodMiddleware],
        component: DepositIndiaPage,
        path: "india",
        children: [
          {
            component: DepositIndiaBasePage,
            name: routeNames.depositIndiaPage,
            path: "",
            meta: {
              backRoute: routeNames.depositListPage,
              depth: 1,
              transaction: "deposit",
            },
          },
          {
            component: DepositIndiaPaymentPage,
            name: routeNames.depositIndiaPaymentPage,
            path: "payment",
            meta: {
              backRoute: routeNames.depositIndiaPage,
              depth: 2,
              transaction: "deposit",
            },
          },
        ],
      },
      {
        beforeEnter: [paymentMethodMiddleware],
        component: DepositCustomPage,
        path: "custom",
        children: [
          {
            component: DepositCustomEazypePage,
            name: routeNames.depositCustomEazypePage,
            path: "eazype",
            meta: {
              backRoute: routeNames.depositListPage,
              depth: 2,
              transaction: "deposit",
            },
          },
          {
            component: DepositCustomUpiQrPage,
            name: routeNames.depositCustomUpiQrPage,
            path: "upi-qr",
            meta: {
              backRoute: routeNames.depositBasePage,
              depth: 2,
              transaction: "deposit",
            },
          },
        ],
      },
      {
        beforeEnter: [paymentMethodMiddleware],
        component: DepositCompletePage,
        name: routeNames.depositCompletePage,
        path: "complete",
        meta: {
          depth: 4,
          transaction: "deposit",
        },
      },
    ],
  },
  {
    component: WithdrawalPage,
    path: "/withdrawal",
    beforeEnter: [performanceMarksMiddleware],
    children: [
      {
        component: WithdrawalListPage,
        name: routeNames.withdrawalListPage,
        path: "",
        meta: {
          depth: 1,
          transaction: "withdrawal",
        },
      },
      {
        beforeEnter: [paymentMethodMiddleware],
        component: WithdrawalPreparePage,
        name: routeNames.withdrawalPreparePage,
        path: "prepare",
        meta: {
          depth: 0,
          transaction: "withdrawal",
          transition: "none",
        },
      },
      {
        beforeEnter: [paymentMethodMiddleware],
        component: WithdrawalBasePage,
        name: routeNames.withdrawalBasePage,
        path: "base",
        meta: {
          backRoute: routeNames.withdrawalListPage,
          depth: 2,
          transaction: "withdrawal",
        },
      },
      {
        beforeEnter: [paymentMethodMiddleware],
        component: WithdrawalGroupPage,
        name: routeNames.withdrawalGroupPage,
        path: "group",
        meta: {
          backRoute: routeNames.withdrawalListPage,
          depth: 2,
          transaction: "withdrawal",
        },
      },
      {
        beforeEnter: [paymentMethodMiddleware],
        component: WithdrawalConfirmPage,
        name: routeNames.withdrawalConfirmPage,
        path: "confirm",
        meta: {
          backRoute: routeNames.withdrawalListPage,
          depth: 3,
          transaction: "withdrawal",
        },
      },
      {
        beforeEnter: [paymentMethodMiddleware],
        component: WithdrawalCompletePage,
        name: routeNames.withdrawalCompletePage,
        path: "complete",
        meta: {
          depth: 4,
          transaction: "withdrawal",
        },
      },
    ],
  },
  {
    path: "/empty",
    component: EmptyPage,
    name: routeNames.emptyPage,
  },
  {
    path: "/error",
    component: ErrorPage,
    name: routeNames.errorPage,
  },
  {
    path: "/form",
    component: FormPage,
    name: routeNames.formPage,
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/",
  },
];

export const routesV2: RouteRecordRaw[] = [
  {
    path: "",
    redirect: "/deposit",
  },
  {
    component: DepositPageV2,
    path: "/deposit",
    beforeEnter: [performanceMarksMiddleware],
    children: [
      {
        component: DepositListPageV2,
        name: routeNamesV2.depositListPage,
        path: "",
        meta: {
          depth: 0,
          transaction: "deposit",
        },
      },
      {
        beforeEnter: [paymentMethodMiddleware],
        component: DepositRedirectPageV2,
        path: "redirect",
        children: [
          {
            component: DepositRedirectBasePageV2,
            name: routeNamesV2.depositRedirectPage,
            path: "",
            meta: {
              backRoute: routeNamesV2.depositListPage,
              depth: 1,
              transaction: "deposit",
            },
          },
          {
            component: DepositRedirectInstructionPageV2,
            name: routeNamesV2.depositRedirectInstructionPage,
            path: "instruction",
            meta: {
              backRoute: routeNamesV2.depositRedirectPage,
              depth: 2,
              transaction: "deposit",
            },
          },
        ],
      },
      {
        beforeEnter: [paymentMethodMiddleware],
        component: DepositCryptoPageV2,
        path: "crypto",
        children: [
          {
            component: DepositCryptoListPageV2,
            name: routeNamesV2.depositCryptoListPage,
            path: "",
            meta: {
              backRoute: routeNamesV2.depositListPage,
              depth: 1,
              transaction: "deposit",
            },
          },
          {
            component: DepositCryptoAddressPageV2,
            name: routeNamesV2.depositCryptoAddressPage,
            path: "address",
            meta: {
              backRoute: routeNamesV2.depositCryptoListPage,
              depth: 2,
              transaction: "deposit",
            },
          },
        ],
      },
      {
        beforeEnter: [paymentMethodMiddleware],
        component: DepositGroupPageV2,
        path: "group",
        children: [
          {
            component: DepositGroupListPageV2,
            name: routeNamesV2.depositGroupListPage,
            path: "",
            meta: {
              backRoute: routeNamesV2.depositListPage,
              depth: 1,
              transaction: "deposit",
            },
          },
          {
            component: DepositRedirectBasePageV2,
            name: routeNamesV2.depositGroupBasePage,
            path: "redirect",
            meta: {
              backRoute: routeNamesV2.depositGroupListPage,
              depth: 2,
              transaction: "deposit",
            },
          },
        ],
      },
      {
        beforeEnter: [paymentMethodMiddleware],
        component: DepositPeerToPeerPage,
        path: "peer-to-peer",
        children: [
          {
            component: DepositPeerToPeerListPage,
            name: routeNamesV2.depositPeerToPeerListPage,
            path: "",
            meta: {
              backRoute: routeNamesV2.depositListPage,
              depth: 1,
              transaction: "deposit",
            },
          },
          {
            component: DepositPeerToPeerBasePage,
            name: routeNamesV2.depositPeerToPeerPage,
            path: "base",
            meta: {
              backRoute: routeNamesV2.depositListPage,
              depth: 2,
              transaction: "deposit",
            },
          },
          {
            component: DepositPeerToPeerInvoicePage,
            name: routeNamesV2.depositPeerToPeerInvoicePage,
            path: "invoice",
            meta: {
              backRoute: routeNamesV2.depositPeerToPeerPage,
              depth: 3,
              transaction: "deposit",
            },
          },
          {
            component: DepositPeerToPeerInstructionPage,
            name: routeNamesV2.depositPeerToPeerInstructionPage,
            path: "instruction",
            meta: {
              backRoute: routeNamesV2.depositPeerToPeerInvoicePage,
              depth: 4,
              transaction: "deposit",
            },
          },
        ],
      },
      {
        beforeEnter: [paymentMethodMiddleware],
        component: DepositHostToHostPageV2,
        name: routeNamesV2.depositHostToHostPage,
        path: "host-to-host",
        meta: {
          backRoute: routeNamesV2.depositListPage,
          depth: 2,
          transaction: "deposit",
        },
      },
      {
        beforeEnter: [paymentMethodMiddleware],
        component: DepositCompletePageV2,
        name: routeNamesV2.depositCompletePage,
        path: "complete",
        meta: {
          depth: 3,
          transaction: "deposit",
        },
      },
    ],
  },
  {
    component: WithdrawalPageV2,
    path: "/withdrawal",
    beforeEnter: [performanceMarksMiddleware],
    children: [
      {
        component: WithdrawalListPageV2,
        name: routeNamesV2.withdrawalListPage,
        path: "",
        meta: {
          depth: 0,
          transaction: "withdrawal",
        },
      },
      {
        beforeEnter: [paymentMethodMiddleware],
        component: WithdrawalRedirectPageV2,
        path: "redirect",
        children: [
          {
            component: WithdrawalRedirectBasePageV2,
            name: routeNamesV2.withdrawalRedirectPage,
            path: "",
            meta: {
              backRoute: routeNamesV2.withdrawalListPage,
              depth: 1,
              transaction: "deposit",
            },
          },
          {
            component: WithdrawalRedirectInstructionPageV2,
            name: routeNamesV2.withdrawalRedirectInstructionPage,
            path: "instruction",
            meta: {
              backRoute: routeNamesV2.withdrawalRedirectPage,
              depth: 2,
              transaction: "deposit",
            },
          },
        ],
      },
      {
        beforeEnter: [paymentMethodMiddleware],
        component: WithdrawalNigerianBanksPage,
        name: routeNamesV2.withdrawalNigerianBanksPage,
        path: "nigerian-banks",
        meta: {
          backRoute: routeNamesV2.withdrawalListPage,
          depth: 1,
          transaction: "withdrawal",
        },
      },
      {
        beforeEnter: [paymentMethodMiddleware],
        component: WithdrawalGroupPageV2,
        path: "group",
        children: [
          {
            component: WithdrawalGroupListPageV2,
            name: routeNamesV2.withdrawalGroupListPage,
            path: "",
            meta: {
              backRoute: routeNamesV2.withdrawalListPage,
              depth: 1,
              transaction: "withdrawal",
            },
          },
          {
            component: WithdrawalRedirectBasePageV2,
            name: routeNamesV2.withdrawalGroupBasePage,
            path: "redirect",
            meta: {
              backRoute: routeNamesV2.withdrawalGroupListPage,
              depth: 2,
              transaction: "withdrawal",
            },
          },
        ],
      },
      {
        beforeEnter: [paymentMethodMiddleware],
        component: WithdrawalConfirmPageV2,
        name: routeNamesV2.withdrawalConfirmPage,
        path: "confirm",
        meta: {
          backRoute: routeNamesV2.withdrawalListPage,
          depth: 3,
          transaction: "withdrawal",
        },
      },
      {
        beforeEnter: [paymentMethodMiddleware],
        component: WithdrawalCompletePageV2,
        name: routeNamesV2.withdrawalCompletePage,
        path: "complete",
        meta: {
          depth: 4,
          transaction: "withdrawal",
        },
      },
    ],
  },
  {
    path: "/empty",
    component: EmptyPageV2,
    name: routeNamesV2.emptyPage,
  },
  {
    path: "/error",
    component: ErrorPageV2,
    name: routeNamesV2.errorPage,
  },
  {
    path: "/form",
    component: FormPageV2,
    name: routeNamesV2.formPage,
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/",
  },
];
