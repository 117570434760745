import { defineStore } from "pinia";
import { ref } from "vue";

const NAMESPACE = "modal";

export const useModalStore = defineStore(NAMESPACE, () => {
  const isOpen = ref(false);

  const changeIsOpen = (value: boolean) => {
    isOpen.value = value;
  };

  return {
    isOpen,
    changeIsOpen,
  };
});
