<script setup lang="ts">
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";

import { SectionButton, SectionTitle, SectionWrapper } from "@/entities/section";
import { useWithdrawalStore } from "@/entities/withdrawal";
import { FormFields, useFormStore } from "@/features/form";
import { Typography } from "@/shared/ui";

const { t } = useI18n();

const withdrawalStore = useWithdrawalStore();
const { isLoadingPrepareWithdrawal, isUpdatingProfile } = storeToRefs(withdrawalStore);
const { onPrepareSubmit } = withdrawalStore;

const { fields, isSomeFieldTriggered, isFieldsValid } = storeToRefs(useFormStore());
</script>

<template>
  <SectionWrapper>
    <div class="flex flex-col gap-3">
      <SectionTitle>
        {{ t("withdrawal.prepare.title") }}
      </SectionTitle>
      <Typography class="text-gray-900/[.8] dark:text-white/[.8]">
        {{ t("withdrawal.prepare.text") }}
      </Typography>
    </div>
    <FormFields v-if="fields.length" />
    <SectionButton
      class="mt-auto"
      color="secondary"
      full-width
      :is-disabled="isSomeFieldTriggered && !isFieldsValid"
      :is-loading="isLoadingPrepareWithdrawal || isUpdatingProfile"
      @click="onPrepareSubmit"
    >
      {{ t("button.continue") }}
    </SectionButton>
  </SectionWrapper>
</template>
