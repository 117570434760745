<script setup lang="ts">
import { storeToRefs } from "pinia";
import { computed } from "vue";

import { useUserStore } from "@/entities/user";
import { AndroidExitModal, IosExitModal } from "@/features/modal";
import type { Translations } from "@/features/modal";

type Emits = {
  close: [];
  submit: [];
};

interface Props {
  isOpen: boolean;
  translations: Translations;
}

const emit = defineEmits<Emits>();

defineProps<Props>();

const { user } = storeToRefs(useUserStore());

const component = computed(() => (user.value.platform === "android" ? AndroidExitModal : IosExitModal));

const onClose = () => emit("close");
const onSubmit = () => emit("submit");
</script>

<template>
  <component
    :is="component"
    :is-open="isOpen"
    :translations="translations"
    @close="onClose"
    @submit="onSubmit"
  />
</template>
