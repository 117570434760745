<script setup lang="ts">
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

import { PaymentHeader, useAnnotation, usePaymentMethodStore, usePaymentInstruction } from "@/entities/payment-method";
import { SectionWrapperV2 } from "@/entities/section";
import { useWithdrawalStore } from "@/entities/withdrawal";
import { SubmitButtonV2 } from "@/features/form";
import { routeNamesV2 } from "@/shared/constants";
import { ScrollShadow } from "@/shared/ui";
import { Alert, Button, IconBox } from "@/shared/ui-v2";
import { WithdrawalFormV2 } from "@/widgets/withdrawal";

const { t } = useI18n();
const router = useRouter();

const { paymentLabel, paymentMethod } = storeToRefs(usePaymentMethodStore());

const { instruction } = usePaymentInstruction({ paymentMethod });

const withdrawalStore = useWithdrawalStore();
const { isLoadingWithdrawal } = storeToRefs(withdrawalStore);
const { onSubmit } = withdrawalStore;

const { annotation } = useAnnotation({
  paymentMethod,
  transaction: "withdrawal",
});
</script>

<template>
  <SectionWrapperV2>
    <ScrollShadow :class="$style.container">
      <PaymentHeader
        :class="$style.space"
        :name="paymentMethod?.name"
        transaction="withdrawal"
      >
        {{ paymentLabel }}
      </PaymentHeader>
      <Alert
        v-if="annotation"
        type="warning"
      >
        {{ annotation }}
      </Alert>
      <WithdrawalFormV2 :class="$style.space" />
    </ScrollShadow>
    <div :class="[$style.wrapper, $style.space]">
      <SubmitButtonV2
        :is-loading="isLoadingWithdrawal"
        @click="onSubmit({}, routeNamesV2.withdrawalCompletePage)"
      >
        {{ t("withdrawal.button") }}
      </SubmitButtonV2>
      <Button
        v-if="instruction"
        color="neutral"
        @click="router.push({ name: routeNamesV2.withdrawalRedirectInstructionPage })"
      >
        <IconBox
          color="tertiary"
          name="circle-warning"
        />
        {{ t("paymentInstruction.button") }}
      </Button>
    </div>
  </SectionWrapperV2>
</template>

<style module lang="postcss">
.container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-16);
}

.space {
  margin: var(--spacing-0) var(--spacing-4);
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-8);
}
</style>
