<script setup lang="ts">
import type { Color, Size, TailwindClass } from "@/shared/ui";
import { Spinner } from "@/shared/ui";

type Emits = {
  click: [];
};

interface Props {
  color?: Color;
  fullWidth?: boolean;
  hasClickAnimation?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  size?: Extract<Size, "auto" | "sm" | "md">;
  variant?: "bordered" | "flat" | "light" | "solid" | "plain";
}

interface Slots {
  default(props: object): unknown;
}

const emit = defineEmits<Emits>();

withDefaults(defineProps<Props>(), {
  color: "primary",
  fullWidth: false,
  hasClickAnimation: true,
  isDisabled: false,
  isLoading: false,
  size: "md",
  variant: "solid",
});

defineSlots<Slots>();

const colors: Record<NotUndefined<Props["color"]>, Record<NotUndefined<Props["variant"]>, TailwindClass>> = {
  auto: {
    bordered: "border border-gray-100 dark:border-dove",
    flat: "",
    light: "",
    solid: "",
    plain: "",
  },
  primary: {
    bordered: "border border-mint-700 dark:border-sage",
    flat: "bg-mint-700/[.1] text-mint-700 dark:bg-sage/[.1] dark:text-sage",
    light: "bg-transparent text-mint-700 dark:text-sage",
    solid: "bg-mint-700 text-white dark:bg-sage-gradient disabled:opacity-50",
    plain: "bg-transparent text-mint-700 dark:text-sage",
  },
  secondary: {
    bordered: "border border-blue-cyan-500 dark:border-sapphire",
    flat: "bg-blue-cyan-500/[.1] text-blue-cyan-500 dark:bg-sapphire/[.1] dark:text-sapphire",
    light: "bg-transparent text-blue-cyan-500 dark:text-sapphire",
    solid: "bg-blue-cyan-500 text-white dark:bg-sapphire-gradient disabled:opacity-50",
    plain: "bg-transparent text-blue-cyan-500 dark:text-sapphire",
  },
  warning: {
    bordered: "border border-yellow-amber-500 dark:border-gold",
    flat: "bg-yellow-amber-500/[.1] text-yellow-amber-500 dark:bg-gold/[.1] dark:text-gold",
    light: "bg-transparent text-yellow-amber-500 dark:text-gold",
    solid: "bg-yellow-amber-500 text-white dark:bg-gold disabled:opacity-50",
    plain: "bg-transparent text-yellow-amber-500 dark:text-gold",
  },
  danger: {
    bordered: "border border-orange-525 dark:border-ruby",
    flat: "bg-orange-525/[.1] text-orange-525 dark:bg-ruby/[.1] dark:text-ruby",
    light: "bg-transparent text-orange-525 dark:text-ruby",
    solid: "bg-orange-525 text-white dark:bg-ruby disabled:opacity-50",
    plain: "bg-transparent text-orange-525 dark:text-ruby",
  },
};

const sizes: Record<NotUndefined<Props["size"]>, Record<NotUndefined<Props["variant"]>, TailwindClass>> = {
  auto: {
    bordered: "",
    flat: "",
    light: "",
    solid: "",
    plain: "",
  },
  sm: {
    bordered: "h-10 py-3 px-4 text-md leading-5 tracking-normal",
    flat: "h-10 py-3 px-4 text-md leading-5 tracking-normal",
    light: "h-10 py-3 px-4 text-md leading-5 tracking-normal",
    solid: "h-10 py-3 px-4 text-md leading-5 tracking-normal",
    plain: "text-md leading-5 tracking-normal",
  },
  md: {
    bordered: "h-12 py-3.25 px-7 text-2md leading-5.5 tracking-wide",
    flat: "h-12 py-3.25 px-7 text-2md leading-5.5 tracking-wide",
    light: "h-12 py-3.25 px-7 text-2md leading-5.5 tracking-wide",
    solid: "h-12 py-3.25 px-7 text-2md leading-5.5 tracking-wide",
    plain: "text-2md leading-5.5 tracking-wide",
  },
};

const onClick = () => emit("click");
</script>

<template>
  <button
    class="highlight-none inline-flex transform select-none items-center justify-center gap-2 rounded-xl font-medium outline-none transition"
    :class="[
      ...(fullWidth ? ['w-full'] : ['w-auto']),
      colors[color][variant],
      sizes[size][variant],
      hasClickAnimation && ['enabled:hover:opacity-90', 'enabled:active:scale-95'],
    ]"
    :disabled="isDisabled || isLoading"
    type="button"
    @click="onClick"
  >
    <Spinner
      v-if="isLoading"
      background-color="none"
      class="text-white dark:text-oxford"
      color="auto"
    />
    <slot v-else />
  </button>
</template>
