<script setup lang="ts">
import type defaultTheme from "tailwindcss/defaultTheme";

import type { Color, Size, TailwindClass, TextVariant } from "@/shared/ui";

interface Props {
  color?: Color;
  size?: Size;
  variant?: TextVariant;
  weight?: keyof typeof defaultTheme.fontWeight;
}

interface Slots {
  default(props: object): unknown;
}

withDefaults(defineProps<Props>(), {
  color: "auto",
  size: "md",
  variant: "p",
  weight: "normal",
});

defineSlots<Slots>();

const colors: Record<NotUndefined<Props["color"]>, TailwindClass> = {
  auto: "",
  danger: "text-orange-525 dark:text-ruby",
  primary: "text-mint-700 dark:text-sage",
  secondary: "text-blue-cyan-500 dark:text-sapphire",
  warning: "text-yellow-amber-500 dark:text-gold",
};

const sizes: Record<NotUndefined<Props["size"]>, TailwindClass> = {
  auto: "",
  xs: "text-xxs leading-3.25",
  sm: "text-2sm leading-4.25",
  md: "text-md leading-5",
  lg: "text-2md leading-5.5",
  xl: "text-xxl leading-6.5",
};

const weights: Record<NotUndefined<Props["weight"]>, TailwindClass> = {
  black: "font-black",
  bold: "font-bold",
  extrabold: "font-extrabold",
  extralight: "font-extralight",
  light: "font-light",
  medium: "font-medium",
  normal: "font-normal",
  semibold: "font-semibold",
  thin: "font-thin",
};
</script>

<template>
  <component
    :is="variant"
    :class="[colors[color], sizes[size], weights[weight]]"
  >
    <slot />
  </component>
</template>
