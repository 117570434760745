import type { PeerToPeerCard } from "@/entities/peer-to-peer";
import type { Theme } from "@/entities/theme";
import type { User } from "@/entities/user";
import { formatCardNumber } from "@/shared/lib";

const hexStringToArrayBuffer = (hexString: string) => {
  const replacedHexString = hexString.replace(/^0x/, "");
  const pairs = replacedHexString.match(/[\dA-F]{2}/gi);
  if (pairs) {
    const integers = pairs.map((s) => Number.parseInt(s, 16));
    const array = new Uint8Array(integers);
    return array.buffer;
  }
  return new ArrayBuffer(0);
};

const decryptArrayBuffer = async (data: NotUndefined<PeerToPeerCard["encoded"]>) => {
  try {
    const key = hexStringToArrayBuffer(data.key);
    const iv = hexStringToArrayBuffer(data.iv);
    const encrypted = hexStringToArrayBuffer(data.encrypted + data.tag);

    const cryptoKey = await crypto.subtle.importKey("raw", key, "AES-GCM", true, ["decrypt"]);

    const decryptedArrayBuffer = await crypto.subtle.decrypt({ name: "AES-GCM", iv }, cryptoKey, encrypted);

    return new TextDecoder("utf8").decode(decryptedArrayBuffer);
  } catch (e) {
    throw new Error(`Error while decrypting data: ${e}`);
  }
};

const getCanvasTextStyles = (platform: User["platform"]) => {
  const styles = {
    android: {
      fontFamily: "Roboto, sans-serif",
      fontSize: 17,
      fontWeight: 400,
      lineHeight: 22,
    },
    ios: {
      fontFamily: "SF Pro, sans-serif",
      fontSize: 17,
      fontWeight: 400,
      lineHeight: 22,
    },
    desktop: {
      fontFamily: "Inter, sans-serif",
      fontSize: 14,
      fontWeight: 400,
      lineHeight: 20,
    },
    mobile: {
      fontFamily: "Inter, sans-serif",
      fontSize: 14,
      fontWeight: 400,
      lineHeight: 20,
    },
  };

  return styles[platform];
};

const getCanvasTextVerticalOffset = (canvas: HTMLCanvasElement, context: CanvasRenderingContext2D, text: string) => {
  const metrics = context.measureText(text);
  const height = metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent;
  return (canvas.height - height) / 2;
};

const convertCardNumberToImageUrl = async (
  cardNumber: string,
  options: {
    platform: User["platform"];
    theme: Theme;
  },
) => {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d") as CanvasRenderingContext2D;

  const style = getCanvasTextStyles(options.platform);
  const scale = 2;
  const text = formatCardNumber(cardNumber);

  canvas.width = 200 * scale;
  canvas.height = 20 * scale;

  context.font = `${style.fontWeight} ${style.fontSize * scale}px/${style.lineHeight} -apple-system, ${
    style.fontFamily
  }`;
  context.fillStyle = options.theme === "light" ? "#141415" : "#f0f2f5";
  context.textBaseline = "top";
  context.imageSmoothingEnabled = true;

  context.fillText(text, 0, getCanvasTextVerticalOffset(canvas, context, text));

  return canvas.toDataURL("image/png");
};

export const getBankBin = async (card: PeerToPeerCard | undefined) => {
  if (!card) {
    return undefined;
  }

  const { bank_type, encoded, number } = card;

  if (encoded) {
    return await decryptArrayBuffer(encoded);
  }

  return number ?? bank_type;
};

export const getDecryptedCard = async (
  card: PeerToPeerCard | undefined,
  options: {
    platform: User["platform"];
    theme: Theme;
  },
) => {
  if (!card?.encoded) {
    return undefined;
  }

  const cardNumber = await decryptArrayBuffer(card.encoded);

  if (!cardNumber) {
    return undefined;
  }

  const imageUrl = await convertCardNumberToImageUrl(cardNumber, options);

  return {
    cardNumber,
    imageUrl,
  };
};
