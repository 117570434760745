<script setup lang="ts">
type Emits = {
  click: [];
};

interface Props {
  isActive?: boolean;
}

interface Slots {
  default(props: object): unknown;
}

const emit = defineEmits<Emits>();

withDefaults(defineProps<Props>(), {
  isActive: false,
});

defineSlots<Slots>();

const onClick = () => emit("click");
</script>

<template>
  <button
    class="highlight-none transform rounded-5.25xl border-0.5 bg-gray-50 px-4 py-1.5 text-md leading-5 tracking-dense text-gray-900 outline-none transition duration-400 active:scale-95 dark:bg-oxford dark:text-white"
    :class="[...(isActive ? ['border-blue-cyan-500', 'dark:border-sapphire'] : ['border-gray-50', 'dark:border-dove'])]"
    type="button"
    @click="onClick"
  >
    <slot />
  </button>
</template>
