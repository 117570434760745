import { omit, pick, toString } from "lodash";
import { defineStore, storeToRefs } from "pinia";
import { computed, ref } from "vue";
import { useRouter } from "vue-router";

import { i18n } from "@/app/plugins";
import type { AdditionalField } from "@/entities/payment-method";
import { usePaymentMethodStore } from "@/entities/payment-method";
import { useThemeStore } from "@/entities/theme";
import type { TransactionStatusKey } from "@/entities/transaction";
import { transactionStatuses, openBrowser } from "@/entities/transaction";
import { updateProfile, useUserStore } from "@/entities/user";
import type {
  Withdrawal,
  WithdrawalConfirmation,
  WithdrawalPayload,
  WithdrawalPreparationData,
  WithdrawalPreparePayload,
} from "@/entities/withdrawal";
import {
  createWithdrawal as createWithdrawalRequest,
  prepareWithdrawal as prepareWithdrawalRequest,
} from "@/entities/withdrawal";
import { useFormStore, useValidation } from "@/features/form";
import { useNotificationStore } from "@/features/notification";
import type { RequestError } from "@/shared/api";
import { useRequest } from "@/shared/composables";
import { deviceTypes, routeNames, routeNamesV2 } from "@/shared/constants";
import { bridgeService } from "@/shared/services";

const NAMESPACE = "withdrawal";

export const useWithdrawalStore = defineStore(NAMESPACE, () => {
  const router = useRouter();

  const { paymentMethod } = storeToRefs(usePaymentMethodStore());

  const { isLegacyDesign } = storeToRefs(useThemeStore());

  const { country, user, isNativePlatform } = storeToRefs(useUserStore());

  const formStore = useFormStore();
  const { amount, fields, validationState } = storeToRefs(formStore);
  const { mapAdditionalFields, mapFields, setFields } = formStore;

  const { handleError, validate } = useValidation();

  const { createNotification } = useNotificationStore();

  const {
    isLoading: isLoadingWithdrawal,
    data: withdrawalData,
    execute: createWithdrawal,
  } = useRequest(() =>
    createWithdrawalRequest({
      amount: +amount.value,
      currency: user.value.currency,
      fields: {
        ...(!isLegacyDesign.value && { amount: +amount.value }),
        ...omit(validationState.value, "customWallet"),
      },
      payment_system: paymentMethod.value!.name,
      wallet: toString(validationState.value?.customWallet),
      ...(isNativePlatform.value && { deviceType: deviceTypes[user.value.platform] }),
    }),
  );

  const {
    isLoading: isLoadingPrepareWithdrawal,
    data: prepareWithdrawalData,
    execute: executePrepareWithdrawal,
  } = useRequest(prepareWithdrawalRequest);

  const { isLoading: isUpdatingProfile, execute: updateProfileRequest } = useRequest(() =>
    updateProfile({
      birthday: Number.parseInt(String(user.value.birthday), 10),
      country: country.value,
      is_withdrawal: true,
      timezone: user.value.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone,
      ...pick(user.value, ["email", "name", "phone"]),
      ...preparationData.value.profile,
      ...(preparationData.value.advanced && {
        ...omit(preparationData.value.advanced, ["firstName"]),
        name: preparationData.value.advanced.firstName,
      }),
    }),
  );

  const apiResponse = ref<Withdrawal["apiResponse"]>();
  const confirmationData = ref<WithdrawalConfirmation>({
    confirmationRequested: false,
    email: "",
  });
  const isRuleShown = ref(false);
  const transactionStatus = ref<TransactionStatusKey>(transactionStatuses.accepted);

  const preparationData = computed(() =>
    fields.value.reduce((acc, field) => {
      const data = {
        [field.name]: field.props.value,
        ...(field.name === "email" && { email_confirmation_code: "" }),
      };
      const type = field.props.isAdvanced ? "advanced" : "profile";
      return {
        ...acc,
        [type]: {
          ...acc[type],
          ...data,
        },
      };
    }, {} as WithdrawalPreparationData),
  );

  const changeApiResponse = (data: Withdrawal["apiResponse"]) => {
    apiResponse.value = data;
  };

  const changeConfirmationData = (data: WithdrawalConfirmation) => {
    confirmationData.value = data;
  };

  const changeIsRuleShown = (value: boolean) => {
    isRuleShown.value = value;
  };

  const changeTransactionStatus = (status: TransactionStatusKey) => {
    transactionStatus.value = status;
  };

  const handleWithdrawal = async (payload: Partial<WithdrawalPayload>, routeName: string) => {
    try {
      await createWithdrawal(payload);
      await handleResponse(withdrawalData.value as Withdrawal, routeName);
    } catch (e) {
      handleError(e as RequestError);
    }
  };

  const prepareWithdrawal = async (payload?: WithdrawalPreparePayload) => {
    try {
      await executePrepareWithdrawal(payload);
      return (prepareWithdrawalData.value?.fields ?? []).filter(
        (field) => field.errors.length > 0,
      ) as AdditionalField[];
    } catch (e) {
      handleError(e as RequestError);
    }
  };

  const handleResponse = async (response: Withdrawal, routeName: string) => {
    const {
      apiResponse,
      confirmationRequested = false,
      email = "",
      fields: additionalFields,
      withdrawalBlocked = false,
      withdrawalId,
    } = response;

    changeApiResponse(apiResponse);
    changeIsRuleShown(withdrawalBlocked);

    if (withdrawalBlocked) {
      createNotification({ color: "danger", content: i18n.global.t("withdrawal.blockingNotice.notification") });
      return;
    }

    if (confirmationRequested) {
      changeConfirmationData({
        confirmationRequested,
        email,
      });
      await router.push({
        name: isLegacyDesign.value ? routeNames.withdrawalConfirmPage : routeNamesV2.withdrawalConfirmPage,
      });
      return;
    }

    if (apiResponse?.isFieldsRequired) {
      setFields([...fields.value, ...mapFields(mapAdditionalFields(additionalFields as AdditionalField[]))]);
      return;
    }

    if (apiResponse?.source) {
      openBrowser(apiResponse);
    }

    bridgeService.notify({
      eventType: "withdrawal",
      messageType: "onCreateTransaction",
      payload: {
        eventId: withdrawalId,
      },
    });

    await router.push({ name: routeName });
  };

  const onPrepareSubmit = async () => {
    const isValid = await validate();

    if (!isValid) {
      return;
    }

    await updateProfileRequest();
    await prepareWithdrawal({
      fields: preparationData.value.advanced,
    });
    await router.push({ name: routeNames.withdrawalListPage });
  };

  const onSubmit = async (payload: Partial<WithdrawalPayload> = {}, routeName = routeNames.withdrawalCompletePage) => {
    const isValid = await validate();

    if (!isValid) {
      return;
    }

    await handleWithdrawal(payload, routeName);
  };

  return {
    apiResponse,
    confirmationData,
    isRuleShown,
    transactionStatus,
    isLoadingPrepareWithdrawal,
    isLoadingWithdrawal,
    isUpdatingProfile,
    changeConfirmationData,
    changeIsRuleShown,
    changeTransactionStatus,
    onPrepareSubmit,
    onSubmit,
  };
});
