<script setup lang="ts">
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

import type { PaymentGroup, PaymentMethod } from "@/entities/payment-method";
import { PaymentMethodCard, getPaymentIcon } from "@/entities/payment-method";
import { useThemeStore } from "@/entities/theme";
import { useUserStore } from "@/entities/user";
import { routeNames } from "@/shared/constants";

interface Props {
  icon?: PaymentGroup["name"] | PaymentMethod["name"];
  path?: string;
}

interface Slots {
  text(props: object): unknown;
}

withDefaults(defineProps<Props>(), {
  icon: "none",
  path: routeNames.depositListPage,
});

defineSlots<Slots>();

const { t } = useI18n();
const router = useRouter();

const { country } = storeToRefs(useUserStore());

const { theme } = storeToRefs(useThemeStore());
</script>

<template>
  <PaymentMethodCard
    :icon="
      getPaymentIcon({
        country,
        name: icon,
        theme,
        transaction: 'deposit',
        type: 'card',
      })
    "
    @change="router.push({ name: path })"
  >
    <template #text>
      <slot name="text" />
    </template>
    <template #title>
      {{ t("deposit.title") }}
    </template>
  </PaymentMethodCard>
</template>
