<script setup lang="ts">
import type { Component } from "vue";

import { Button, Caption, Divider, IconBox, Paragraph } from "@/shared/ui-v2";

type Emits = {
  copy: [value: unknown];
};

interface Props {
  icon: {
    component: Component;
    props: {
      color?: string;
      name: string;
      size: string;
    };
  };
  imageUrl?: string;
  isCopyable?: boolean;
  text: string;
  value: unknown;
  withDivider?: boolean;
}

const emit = defineEmits<Emits>();

withDefaults(defineProps<Props>(), {
  isCopyable: false,
  imageUrl: "",
  withDivider: false,
});

const onCopy = (value: unknown) => emit("copy", value);
</script>

<template>
  <div :class="$style.root">
    <component
      :is="icon.component"
      v-bind="icon.props"
      :class="$style.icon"
    />
    <div :class="$style.container">
      <Divider v-if="withDivider" />
      <div :class="$style.inner">
        <div :class="$style.wrapper">
          <Caption color="tertiary">
            {{ text }}
          </Caption>
          <img
            v-if="imageUrl"
            :alt="text"
            :class="$style.image"
            :src="imageUrl"
          />
          <Paragraph
            v-else
            color="primary"
          >
            {{ value }}
          </Paragraph>
        </div>
        <Button
          v-if="isCopyable"
          :class="$style.copyButton"
          color="neutral"
          size="s"
          @click="onCopy(value)"
        >
          <IconBox
            color="tertiary"
            name="copy"
            size="s"
          />
        </Button>
      </div>
    </div>
  </div>
</template>

<style module lang="postcss">
.root {
  display: flex;
  align-items: center;
  gap: var(--spacing-12);
}

.container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--spacing-12);
  padding: var(--spacing-8) var(--spacing-0);
}

.icon {
  margin: var(--spacing-0) var(--spacing-8);
}

.wrapper {
  display: flex;
  flex-direction: column;
  text-align: start;
}

.image {
  height: 20px;
}

.copyButton {
  padding: var(--spacing-8);
}
</style>
