import type { UnwrapRef } from "vue";
import { onBeforeMount, reactive, ref, toRefs } from "vue";

import { i18n, socket } from "@/app/plugins";

interface State<T> {
  data: Nullable<T>;
  error: Nullable<Error>;
  isLoading: boolean;
}

export const useRequest = <T>(callback: (payload: object) => Promise<UnwrapRef<T>>) => {
  const state = reactive<State<T>>({
    data: null,
    error: null,
    isLoading: false,
  });

  const hasSocketConnection = ref<boolean>();

  const execute = async (payload: object = {}) => {
    try {
      state.isLoading = true;

      if (hasSocketConnection.value === false) {
        return Promise.reject({ message: i18n.global.t("socket.networkError") });
      }

      state.data = await callback(payload);
    } catch (error) {
      state.error = error as Error;

      return Promise.reject(error);
    } finally {
      state.isLoading = false;
    }
  };

  const changeSocketConnection = (value: boolean) => {
    hasSocketConnection.value = value;
  };

  const disconnect = () => {
    state.isLoading = false;
    changeSocketConnection(false);
  };

  const reconnect = () => {
    const interval = setInterval(() => {
      if (hasSocketConnection.value) {
        clearInterval(interval);
      }

      socket.connect();
    }, 5_000);
  };

  onBeforeMount(() => {
    socket.on("connect", () => changeSocketConnection(true));
    socket.on("disconnect", () => {
      disconnect();
      reconnect();
    });
  });

  return {
    ...toRefs(state),
    execute,
  };
};
