<script setup lang="ts">
import { watch } from "vue";

import { transitions } from "@/shared/constants";
import type { TailwindClass } from "@/shared/ui";
import { Icon } from "@/shared/ui";

type Emits = {
  change: [value: boolean];
  close: [];
};

interface Props {
  duration?: number;
  isOpen?: boolean;
  placement?: "top" | "top-start";
}

interface Slots {
  content(props: object): unknown;
  default(props: object): unknown;
}

const emit = defineEmits<Emits>();

const props = withDefaults(defineProps<Props>(), {
  duration: 0,
  isOpen: false,
  placement: "top",
});

defineSlots<Slots>();

const placements: Record<"arrow" | "tooltip", Record<NotUndefined<Props["placement"]>, TailwindClass>> = {
  arrow: {
    top: "top-full left-1/2 -translate-x-1/2",
    "top-start": "top-full left-4",
  },
  tooltip: {
    top: "bottom-[calc(100%+var(--tooltip-arrow-width))] left-1/2 -translate-x-1/2",
    "top-start": "bottom-[calc(100%+var(--tooltip-arrow-width))]",
  },
};

const onChange = (value: boolean) => emit("change", value);
const onClose = () => emit("close");

watch(
  () => props.isOpen,
  () => {
    if (props.isOpen && props.duration) {
      setTimeout(onClose, props.duration);
    }
  },
);
</script>

<template>
  <div class="relative">
    <Transition :name="transitions.slideDown">
      <div
        v-if="isOpen"
        class="absolute z-10 w-max rounded-xl bg-gray-900 px-3 py-2 text-white dark:bg-white dark:text-onyx"
        :class="placements.tooltip[placement]"
      >
        <Icon
          class="absolute text-gray-900 dark:text-white"
          :class="placements.arrow[placement]"
          name="tooltip-arrow"
        />
        <slot name="content" />
      </div>
    </Transition>
    <div
      @mouseleave="onChange(false)"
      @mouseover="onChange(true)"
    >
      <slot name="default" />
    </div>
  </div>
</template>
