<script setup lang="ts">
import { storeToRefs } from "pinia";
import { computed, onBeforeUnmount } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

import { useDepositStore } from "@/entities/deposit";
import { usePaymentMethodStore } from "@/entities/payment-method";
import { transactionStatuses } from "@/entities/transaction";
import { CompletePage } from "@/pages";
import { routeNames } from "@/shared/constants";

const { t } = useI18n();
const router = useRouter();

const depositStore = useDepositStore();
const { apiResponse, transactionStatus } = storeToRefs(depositStore);
const { changeTransactionStatus } = depositStore;

const { paymentGroup } = storeToRefs(usePaymentMethodStore());

const translations = computed(() => ({
  amount: t("deposit.complete.amount"),
  date: t("deposit.complete.date"),
  paymentMethod: t("deposit.complete.paymentMethod"),
  status: {
    accepted: {
      additionalButton: t("deposit.complete.status.accepted.additionalButton"),
      mainButton: t("deposit.complete.status.accepted.mainButton"),
      text: t("deposit.complete.status.accepted.text"),
      title: t("deposit.complete.status.accepted.title"),
    },
    mistaked: {
      additionalButton: null,
      mainButton: t("deposit.complete.status.mistaked.button"),
      text: t("deposit.complete.status.mistaked.text"),
      title: t("deposit.complete.status.mistaked.title"),
    },
    processed: {
      additionalButton: t("deposit.complete.status.processed.additionalButton"),
      mainButton: t("deposit.complete.status.processed.mainButton"),
      text: t("deposit.complete.status.processed.text"),
      title: t("deposit.complete.status.processed.title"),
    },
    waiting: {
      additionalButton: null,
      mainButton: t("deposit.complete.status.waiting.button"),
      text: t("deposit.complete.status.waiting.text"),
      title: t("deposit.complete.status.waiting.title"),
    },
  },
}));

const backToPaymentPage = () => {
  if (paymentGroup.value) {
    router.replace({ name: routeNames.depositGroupPage });
    return;
  }

  router.replace({ name: routeNames.depositBasePage });
};

onBeforeUnmount(() => changeTransactionStatus(transactionStatuses.waiting));
</script>

<template>
  <CompletePage
    :api-response="apiResponse"
    transaction="deposit"
    :transaction-status="transactionStatus"
    :translations="translations"
    @back-to-payment-page="backToPaymentPage"
    @change-transaction-status="changeTransactionStatus"
  />
</template>
