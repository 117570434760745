import { nanoid } from "nanoid";
import { defineStore } from "pinia";
import { ref } from "vue";

import type { Notification, NotificationProps } from "@/features/notification";

const NAMESPACE = "notification";

export const useNotificationStore = defineStore(NAMESPACE, () => {
  const notifications = ref<Notification[]>([]);

  const createNotification = (options: NotificationProps) => {
    notifications.value = [
      ...notifications.value,
      {
        id: nanoid(),
        ...options,
      },
    ];
  };

  const removeNotification = (id: Notification["id"]) => {
    const index = notifications.value.findIndex((item) => item.id === id);

    if (index === -1) {
      return;
    }

    notifications.value = [...notifications.value.slice(0, index), ...notifications.value.slice(index + 1)];
  };

  return {
    notifications,
    createNotification,
    removeNotification,
  };
});
