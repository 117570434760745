import type { Ref } from "vue";
import { computed, ref } from "vue";

import type { PaymentMethod } from "@/entities/payment-method";
import { paymentMethodsWithConfirmationIdField } from "@/entities/peer-to-peer";

interface Props {
  paymentMethod: Ref<Nullable<PaymentMethod>>;
}

export const useConfirmationId = (props: Props) => {
  const confirmationId = ref("");
  const isConfirmationIdValid = ref(true);

  const hasConfirmationId = computed(() =>
    paymentMethodsWithConfirmationIdField.includes(props.paymentMethod.value?.name ?? ""),
  );

  const changeConfirmationId = (value: string) => {
    confirmationId.value = value;
  };

  const changeConfirmationIdValidity = (value: boolean) => {
    isConfirmationIdValid.value = value;
  };

  const handleChangeConfirmationId = (value: string) => {
    changeConfirmationId(value);
    changeConfirmationIdValidity(true);
  };

  return {
    confirmationId,
    isConfirmationIdValid,
    hasConfirmationId,
    changeConfirmationIdValidity,
    handleChangeConfirmationId,
  };
};
