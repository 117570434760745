<script setup lang="ts">
import { storeToRefs } from "pinia";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

import type { PaymentMethod } from "@/entities/payment-method";
import { mapPaymentMethod, usePaymentMethodStore } from "@/entities/payment-method";
import { SectionWrapper } from "@/entities/section";
import { useUserStore } from "@/entities/user";
import { useWithdrawalStore } from "@/entities/withdrawal";
import { SubmitButton, useFormStore } from "@/features/form";
import { useSearch } from "@/features/search";
import { transitions } from "@/shared/constants";
import { ScrollShadow } from "@/shared/ui";
import { WithdrawalForm, WithdrawalInfo, WithdrawalSearch } from "@/widgets/withdrawal";

const { t } = useI18n();

const paymentMethodStore = usePaymentMethodStore();
const { limits, paymentGroup, paymentMethod, sortedPaymentMethods } = storeToRefs(paymentMethodStore);
const { changePaymentMethod } = paymentMethodStore;

const { country } = storeToRefs(useUserStore());

const withdrawalStore = useWithdrawalStore();
const { isLoadingWithdrawal } = storeToRefs(withdrawalStore);
const { onSubmit } = withdrawalStore;

const { changeAmount, changeFields } = useFormStore();

const { isSearchActive, search, cancelSearch, changeSearch, setIsSearchActive } = useSearch();

const searchedMethods = computed(() =>
  sortedPaymentMethods.value.filter(({ text }) =>
    (text[country.value] ?? text.en ?? "").toLowerCase().includes(search.value.toLowerCase()),
  ),
);

const handleSearch = (search: string) => {
  changeSearch(search);
  changePaymentMethod(null);
};

const selectPaymentMethod = (selectedMethod: PaymentMethod) => {
  const paymentMethod = mapPaymentMethod(selectedMethod);
  changePaymentMethod(paymentMethod);
  changeFields(paymentMethod.fields);
  changeAmount(String(limits.value.min));
  setIsSearchActive(false);
};
</script>

<template>
  <SectionWrapper>
    <ScrollShadow
      class="flex flex-col gap-6"
      :class="[...(searchedMethods.length ? [] : ['grow'])]"
    >
      <WithdrawalInfo
        v-if="!isSearchActive"
        :icon="paymentGroup?.name"
      >
        <template #text>
          {{ paymentGroup?.label ?? "" }}
        </template>
      </WithdrawalInfo>
      <WithdrawalSearch
        :is-search-active="isSearchActive"
        :payment-method="paymentMethod"
        :search="search"
        :searched-methods="searchedMethods"
        @cancel-search="cancelSearch"
        @change-search="handleSearch"
        @select-payment-method="selectPaymentMethod"
        @set-is-search-active="setIsSearchActive"
      />
    </ScrollShadow>
    <Transition :name="transitions.slideDown">
      <div
        v-if="paymentMethod"
        class="flex flex-1 flex-col gap-6"
      >
        <WithdrawalForm />
        <SubmitButton
          class="mt-auto"
          :is-loading="isLoadingWithdrawal"
          @click="onSubmit"
        >
          {{ t("withdrawal.button") }}
        </SubmitButton>
      </div>
    </Transition>
  </SectionWrapper>
</template>
