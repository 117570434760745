<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";

import type { TransactionStatusKey } from "@/entities/transaction";
import type { TailwindClass } from "@/shared/ui";
import { Icon, Typography } from "@/shared/ui";

interface Props {
  status: TransactionStatusKey;
}

interface Status {
  icon: {
    classes: TailwindClass;
    name: string;
  };
  text: string;
}

const props = defineProps<Props>();

const { t } = useI18n();

const statusesInfo: Readonly<Record<TransactionStatusKey, Status>> = {
  accepted: {
    icon: {
      classes: "text-gray-350 dark:text-pearl/[.64]",
      name: "circle-clock",
    },
    text: t("statuses.accepted"),
  },
  mistaked: {
    icon: {
      classes: "text-orange-525 dark:text-ruby",
      name: "circle-attention",
    },
    text: t("statuses.mistaked"),
  },
  processed: {
    icon: {
      classes: "text-mint-700 dark:text-emerald",
      name: "circle-check",
    },
    text: t("statuses.processed"),
  },
  waiting: {
    icon: {
      classes: "text-gray-350 dark:text-pearl/[.64]",
      name: "circle-clock",
    },
    text: t("statuses.accepted"),
  },
};

const statusInfo = computed(() => statusesInfo[props.status]);
</script>

<template>
  <div class="flex items-center gap-1.5 rounded-full border border-silver px-3 py-1.75">
    <Typography
      class="text-xs text-gray-350 dark:text-pearl"
      size="auto"
      weight="medium"
    >
      {{ statusInfo.text }}
    </Typography>
    <Icon
      :key="statusInfo.icon.name"
      class="h-4 w-4"
      :class="statusInfo.icon.classes"
      :name="statusInfo.icon.name"
    />
  </div>
</template>
