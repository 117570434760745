<script setup lang="ts">
import { storeToRefs } from "pinia";

import { useUserStore } from "@/entities/user";
import { Button } from "@/shared/ui-v2";

type Emits = {
  click: [];
};

interface Props {
  color?: "accent" | "neutral" | "primary";
  isDisabled?: boolean;
  isLoading?: boolean;
}

const emit = defineEmits<Emits>();

withDefaults(defineProps<Props>(), {
  color: "primary",
  isDisabled: false,
  isLoading: false,
});

const { user } = storeToRefs(useUserStore());

const onClick = () => emit("click");
</script>

<template>
  <Button
    :class="[user.platform === 'ios' && $style.rootMargin]"
    :color="color"
    :is-disabled="isDisabled"
    :is-loading="isLoading"
    @click="onClick"
  >
    <slot />
  </Button>
</template>

<style module lang="postcss">
.rootMargin {
  margin-bottom: var(--spacing-24);
}
</style>
