<script setup lang="ts">
import { onBeforeMount, ref } from "vue";

import { fetchIcon } from "@/shared/lib";
import type { TailwindClass } from "@/shared/ui";

interface Props {
  name: Nullable<string>;
  radius?: "none" | "full";
}

const props = withDefaults(defineProps<Props>(), {
  radius: "none",
});

const radiuses: Record<NotUndefined<Props["radius"]>, TailwindClass> = {
  none: "",
  full: "rounded-full",
};

const html = ref("");

const changeHtml = (data: string) => {
  html.value = data;
};

onBeforeMount(async () => {
  const icon = await fetchIcon(props.name ?? "none");
  changeHtml(icon);
});
</script>

<template>
  <div
    v-sanitize-html="html"
    class="[&>svg]:rounded-inherit [&>svg]:w-inherit inline-flex items-center justify-center"
    :class="[radiuses[radius]]"
  />
</template>
