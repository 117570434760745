<script setup lang="ts">
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";

import { useDepositStore } from "@/entities/deposit";
import { usePaymentMethodStore } from "@/entities/payment-method";
import { SectionWrapper } from "@/entities/section";
import { SubmitButton } from "@/features/form";
import { ScrollShadow } from "@/shared/ui";
import { DepositInfo } from "@/widgets/deposit";
import { PaymentCopy, PaymentForm } from "@/widgets/india";

const { t } = useI18n();

const depositStore = useDepositStore();
const { isLoadingDeposit } = storeToRefs(depositStore);
const { onSubmit } = depositStore;

const { paymentLabel, paymentMethod } = storeToRefs(usePaymentMethodStore());
</script>

<template>
  <SectionWrapper>
    <ScrollShadow class="flex flex-col gap-6">
      <DepositInfo :icon="paymentMethod?.name">
        <template #text>
          {{ paymentLabel }}
        </template>
      </DepositInfo>
      <PaymentCopy class="mb-2" />
      <PaymentForm />
    </ScrollShadow>
    <SubmitButton
      class="mt-auto"
      :is-loading="isLoadingDeposit"
      @click="onSubmit"
    >
      {{ t("button.confirm") }}
    </SubmitButton>
  </SectionWrapper>
</template>
