<script setup lang="ts">
import { storeToRefs } from "pinia";

import { usePaymentMethodStore } from "@/entities/payment-method";
import { useUserStore } from "@/entities/user";
import { AmountInputV2, AmountTagsV2, FormFieldsV2, useFormStore } from "@/features/form";

const { limits } = storeToRefs(usePaymentMethodStore());

const { user } = storeToRefs(useUserStore());

const formStore = useFormStore();
const { amount, fields } = storeToRefs(formStore);
const { changeAmount, changeAmountValidity } = formStore;

const handleClick = (amount: string) => {
  changeAmount(amount);
  changeAmountValidity(true);
};
</script>

<template>
  <div :class="$style.root">
    <FormFieldsV2 v-if="fields.length" />
    <AmountInputV2
      :currency="user.currency"
      :limits="limits"
      :value="amount"
      @focus="changeAmountValidity(true)"
      @input="changeAmount"
    />
    <AmountTagsV2
      :currency="user.currency"
      :lang="user.lang"
      :limits="limits"
      :value="amount"
      @click="handleClick"
    />
  </div>
</template>

<style module lang="postcss">
.root {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-16);
}
</style>
