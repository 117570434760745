<script setup lang="ts">
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";

import { DEFAULT_DEPOSIT_TIME } from "@/entities/crypto";
import { depositTimes } from "@/entities/deposit";
import type { PaymentMethodNetwork } from "@/entities/payment-method";
import { usePaymentMethodStore } from "@/entities/payment-method";
import { CRYPTO_NETWORK_FIELD, useFormStore } from "@/features/form";
import { Button, Icon, Typography } from "@/shared/ui";

interface Props {
  networks: PaymentMethodNetwork[];
}

defineProps<Props>();

const { t } = useI18n();

const { paymentLabel } = storeToRefs(usePaymentMethodStore());

const formStore = useFormStore();
const { validationState } = storeToRefs(formStore);
const { changeFieldProps } = formStore;
</script>

<template>
  <div class="flex flex-col gap-3">
    <Button
      v-for="network of networks"
      :key="network"
      class="h-full justify-between bg-gray-50 px-4 py-3 dark:bg-oxford"
      color="auto"
      :has-click-animation="false"
      size="sm"
      @click="changeFieldProps(CRYPTO_NETWORK_FIELD, { value: network })"
    >
      <span class="flex flex-col items-start">
        <Typography>{{ paymentLabel }} ({{ network }})</Typography>
        <Typography
          class="text-xs text-gray-350 dark:text-pearl"
          size="auto"
        >
          {{
            t("deposit.crypto.network.time", {
              time: depositTimes?.[network] ?? DEFAULT_DEPOSIT_TIME,
            })
          }}
        </Typography>
      </span>
      <div
        class="flex h-5 w-5 justify-center rounded-full border-0.5"
        :class="[
          ...(network === validationState.network
            ? ['border-onyx', 'bg-blue-cyan-500', 'dark:border-anchor', 'dark:bg-sapphire']
            : ['border-gray-350', 'bg-gray-50', 'dark:border-anchor', 'dark:bg-dove']),
        ]"
      >
        <Icon
          v-if="network === validationState.network"
          class="text-white"
          name="check"
        />
      </div>
    </Button>
  </div>
</template>
