<script setup lang="ts">
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";

import { usePaymentMethodStore } from "@/entities/payment-method";
import { useUserStore } from "@/entities/user";
import { WithdrawalRule, useWithdrawalStore } from "@/entities/withdrawal";
import { AmountInput, FormFields, useFormStore } from "@/features/form";
import { transitions } from "@/shared/constants";

const { t } = useI18n();

const { limits } = storeToRefs(usePaymentMethodStore());

const { country } = storeToRefs(useUserStore());

const { isRuleShown } = storeToRefs(useWithdrawalStore());

const formStore = useFormStore();
const { amount, fields, isAmountValid } = storeToRefs(formStore);
const { changeAmount, changeAmountValidity } = formStore;
</script>

<template>
  <div class="flex flex-col gap-4">
    <FormFields v-if="fields.length" />
    <AmountInput
      :has-error-message="!isAmountValid"
      :limits="limits"
      :value="amount"
      @focus="changeAmountValidity(true)"
      @input="changeAmount"
    >
      <template #label>
        {{ t("withdrawal.text") }}
      </template>
    </AmountInput>
    <Transition :name="transitions.slideDown">
      <WithdrawalRule
        v-if="isRuleShown"
        :country="country"
      />
    </Transition>
  </div>
</template>
