<script setup lang="ts">
import { onMounted } from "vue";

import type { NotificationProps } from "@/features/notification";
import type { TailwindClass } from "@/shared/ui";

type Emits = {
  close: [];
};

const emit = defineEmits<Emits>();

const props = withDefaults(defineProps<NotificationProps>(), {
  color: "primary",
  customClass: "",
  duration: 3_000,
});

const colors: Record<NotUndefined<NotificationProps["color"]>, TailwindClass> = {
  auto: "",
  danger: "bg-orange-525 dark:bg-ruby",
  primary: "bg-mint-700 dark:bg-sage",
  secondary: "bg-blue-cyan-500 dark:bg-sapphire",
  warning: "bg-yellow-amber-500 dark:bg-gold",
};

const onClose = () => emit("close");

onMounted(() => setTimeout(onClose, props.duration));
</script>

<template>
  <div
    v-sanitize-html="content"
    class="fixed left-0 top-0 w-full px-4 py-3 text-center text-white"
    :class="[colors[color], customClass]"
  />
</template>
