<script setup lang="ts">
import type { PaymentGroup, PaymentMethod } from "@/entities/payment-method";
import { PaymentGroupTile, PaymentMethodTile } from "@/entities/payment-method";
import type { Transaction } from "@/entities/transaction";

type Emits = {
  selectPaymentGroup: [paymentGroup: PaymentGroup];
  selectPaymentMethod: [paymentMethod: PaymentMethod];
};

interface Props {
  paymentGroup: Nullable<PaymentGroup>;
  paymentGroups: PaymentGroup[];
  paymentMethod: Nullable<PaymentMethod>;
  paymentMethods: PaymentMethod[];
  transaction: Transaction;
}

const emit = defineEmits<Emits>();

defineProps<Props>();

const onSelectPaymentGroup = (paymentGroup: PaymentGroup) => emit("selectPaymentGroup", paymentGroup);
const onSelectPaymentMethod = (paymentMethod: PaymentMethod) => emit("selectPaymentMethod", paymentMethod);
</script>

<template>
  <div class="grid grid-cols-2 content-baseline gap-2 sm:grid-cols-3 md:grid-cols-4">
    <PaymentGroupTile
      v-for="item of paymentGroups"
      :key="item.name"
      :is-active="item.name === paymentGroup?.name"
      :payment-group="item"
      :transaction="transaction"
      @select="onSelectPaymentGroup"
    />
    <PaymentMethodTile
      v-for="item of paymentMethods"
      :key="item.name"
      :is-active="item.name === paymentMethod?.name"
      :payment-method="item"
      size="lg"
      :transaction="transaction"
      @select="onSelectPaymentMethod"
    />
  </div>
</template>
