<script setup lang="ts">
import { ref } from "vue";
import { useI18n } from "vue-i18n";

import type { ImageGuideContent } from "@/entities/payment-method";
import { ImageGuide } from "@/entities/payment-method";
import { SectionWrapperV2 } from "@/entities/section";
import { ScrollShadow } from "@/shared/ui";
import { Button, Title } from "@/shared/ui-v2";

interface Props {
  content: ImageGuideContent[];
}

defineProps<Props>();

const { t } = useI18n();

const activeSlideIndex = ref(0);
const swiper = ref();

const changeActiveSlideIndex = (index: number) => {
  activeSlideIndex.value = index;
};

const changeSlide = (index: number) => {
  swiper.value?.slideTo(index);
};

const changeSwiper = (instance: unknown) => {
  swiper.value = instance;
};

const handleChangeSlide = (index: number) => {
  changeActiveSlideIndex(index);
  changeSlide(index);
};
</script>

<template>
  <SectionWrapperV2>
    <ScrollShadow :class="$style.container">
      <Title size="l">
        {{ t("instruction.title") }}
      </Title>
      <ImageGuide
        :ref="(el) => changeSwiper((el as any)?.swiper)"
        :active-slide-index="activeSlideIndex"
        :content="content"
        @change="changeActiveSlideIndex"
      />
    </ScrollShadow>
    <div
      v-if="content.length > 1"
      :class="$style.buttons"
    >
      <Button
        :is-disabled="activeSlideIndex === content.length - 1"
        @click="handleChangeSlide(activeSlideIndex + 1)"
      >
        {{ t("instruction.button.next") }}
      </Button>
      <Button
        color="neutral"
        :is-disabled="activeSlideIndex === 0"
        @click="handleChangeSlide(activeSlideIndex - 1)"
      >
        {{ t("instruction.button.prev") }}
      </Button>
    </div>
  </SectionWrapperV2>
</template>

<style module lang="postcss">
.container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-16);
}

.buttons {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-8);
  margin-top: auto;
}
</style>
