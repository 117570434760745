<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";

import { SectionButton, SectionWrapper } from "@/entities/section";
import { changeLocation } from "@/shared/lib";
import { Container, Icon, Typography } from "@/shared/ui";

const { t } = useI18n();
const route = useRoute();
</script>

<template>
  <section>
    <Container>
      <SectionWrapper>
        <div class="flex grow flex-col items-center justify-center text-center">
          <Icon
            class="mb-2 [&>svg]:h-12 [&>svg]:w-12"
            name="danger"
          />
          <Typography
            class="mb-3 max-w-[360px] text-3xl"
            size="auto"
            variant="h3"
            weight="bold"
          >
            {{ t("empty.title") }}
          </Typography>
          <Typography
            class="max-w-[280px] text-gray-900/[.8] dark:text-white/[.8]"
            size="lg"
          >
            {{ t("empty.text") }}
          </Typography>
        </div>
        <SectionButton
          color="secondary"
          @click="changeLocation(route.query.refreshUrl as string)"
        >
          {{ t("button.refresh") }}
        </SectionButton>
      </SectionWrapper>
    </Container>
  </section>
</template>
