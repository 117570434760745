import { ref } from "vue";

import type { CreateEventPayload, Event, DEPOSIT_EVENT_TOKEN, WITHDRAWAL_EVENT_TOKEN } from "@/entities/transaction";
import { createEvent } from "@/entities/transaction";
import { useRequest } from "@/shared/composables";
import { bridgeService } from "@/shared/services";

type TrackTimeEventToken = typeof DEPOSIT_EVENT_TOKEN | typeof WITHDRAWAL_EVENT_TOKEN;

const formatMillisecondsToSeconds = (milliseconds: number, digits = 0): number =>
  Number((milliseconds / 1000).toFixed(digits));

export const useTrackTime = (token: TrackTimeEventToken) => {
  const { execute } = useRequest(() =>
    createEvent({
      events: events.value,
    }),
  );

  const events = ref<CreateEventPayload["events"]>([]);

  const addEvent = (event: Event) => {
    events.value = [...events.value, event];
  };

  const track = async () => {
    performance.mark("endLoadWidget");
    const { duration } = performance.measure("loadWidget", "routeStartLoading", "endLoadWidget");

    const timestamp = formatMillisecondsToSeconds(Date.now() - duration);

    performance.clearMarks("loadWidget");
    performance.clearMeasures("loadWidget");

    addEvent({
      key: token,
      timestamp,
      value: formatMillisecondsToSeconds(duration, 3),
    });

    bridgeService.notify({ messageType: "FullLoaded" });

    await execute();
  };

  return {
    track,
  };
};
