<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, reactive, ref } from "vue";

interface Props {
  axis?: "x" | "y";
  maxTintSize?: number;
}

interface Slots {
  default(props: object): unknown;
}

const props = withDefaults(defineProps<Props>(), {
  axis: "y",
  maxTintSize: 20,
});

defineSlots<Slots>();

const containerProps = reactive({
  scrollSize: 0,
  scrollOffset: 0,
  height: 0,
  width: 0,
});

const containerRef = ref<Nullable<HTMLElement>>(null);
const observer = ref<Nullable<ResizeObserver>>(null);

const styles = computed(() => {
  const { start, end } = getTintSizes();

  const endSize = props.axis === "y" ? end : start;

  return {
    maskImage: `linear-gradient(
      ${gradientDirection.value},
      transparent 0%,
      #000 0px,
      #000 calc(100% - ${endSize}px),
      transparent 100%
    )`,
    [`overflow-${props.axis}`]: "auto",
  };
});

const gradientDirection = computed(() => (props.axis === "y" ? "to bottom" : "to left"));

const getTintSizes = () => {
  const size = props.axis === "y" ? containerProps.height : containerProps.width;
  return {
    end: Math.min(containerProps.scrollSize - size - containerProps.scrollOffset, props.maxTintSize),
    start: Math.min(containerProps.scrollOffset, props.maxTintSize),
  };
};

const updateSizes = () => {
  const element = containerRef.value as HTMLDivElement;
  containerProps.scrollSize = props.axis === "y" ? element.scrollHeight : element.scrollWidth;
  containerProps.scrollOffset = props.axis === "y" ? element.scrollTop : element.scrollLeft;
  containerProps.height = element.clientHeight;
  containerProps.width = element.clientWidth;
};

onBeforeUnmount(() => {
  const element = containerRef.value as HTMLDivElement;
  observer.value?.unobserve(element);
  element.removeEventListener("scroll", updateSizes);
});

onMounted(() => {
  const element = containerRef.value as HTMLDivElement;
  observer.value = new ResizeObserver(updateSizes);
  observer.value.observe(element);
  element.addEventListener("scroll", updateSizes);
});
</script>

<template>
  <div
    ref="containerRef"
    class="scrollbar-hidden"
    :style="styles"
  >
    <slot />
  </div>
</template>
