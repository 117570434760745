<script setup lang="ts">
import { onBeforeUnmount, onMounted } from "vue";
import { useI18n } from "vue-i18n";

import type { NotificationProps } from "@/features/notification";
import { Button, IconBox, Paragraph } from "@/shared/ui-v2";

type Emits = {
  close: [];
};

const emit = defineEmits<Emits>();

const props = withDefaults(defineProps<NotificationProps>(), {
  duration: 5_000,
});

const { t } = useI18n();

let timeout: ReturnType<typeof setTimeout>;

const onClose = () => emit("close");

onBeforeUnmount(() => clearTimeout(timeout));

onMounted(() => {
  timeout = setTimeout(onClose, props.duration);
});
</script>

<template>
  <div :class="$style.root">
    <div :class="$style.container">
      <div :class="$style.wrapper">
        <div :class="$style.textWrapper">
          <IconBox
            color="tertiary"
            name="circle-warning"
            size="m"
          />
          <Paragraph size="m-sb">
            {{ t("error.snackbar.title") }}
          </Paragraph>
        </div>
        <Button
          variant="plain"
          @click="onClose"
        >
          <IconBox
            color="secondary"
            name="close"
          />
        </Button>
      </div>
      <Paragraph
        :class="$style.text"
        color="secondary"
      >
        <span v-sanitize-html="content" />
      </Paragraph>
    </div>
  </div>
</template>

<style module lang="postcss">
.root {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  padding: var(--spacing-8);
  background-image: var(--color-snackbar-gradient);
}

.container {
  padding: var(--spacing-16);
  border-radius: var(--rounding-16);
  box-shadow: var(--box-shadow-m);
  background-color: var(--color-bg-elevated);
}

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--spacing-12);
}

.textWrapper {
  display: flex;
  align-items: center;
  gap: var(--spacing-12);
}

.text {
  margin-top: var(--spacing-6);
  margin-left: calc(var(--spacing-40) - var(--spacing-4));
}
</style>
