<script setup lang="ts">
import type { Size, TailwindClass } from "@/shared/ui";

type Emits = {
  click: [];
};

interface Props {
  isActive?: boolean;
  isScalable?: boolean;
  size?: Extract<Size, "auto" | "md" | "lg">;
}

interface Slots {
  default(props: object): unknown;
}

const emit = defineEmits<Emits>();

withDefaults(defineProps<Props>(), {
  isActive: false,
  isScalable: false,
  size: "auto",
});

defineSlots<Slots>();

const sizes: Record<NotUndefined<Props["size"]>, TailwindClass> = {
  auto: "",
  md: "py-4 px-2",
  lg: "pt-6 pb-4 px-2",
};

const onClick = () => emit("click");
</script>

<template>
  <div
    class="rounded-2xl bg-gray-50 transition duration-400 dark:bg-oxford"
    :class="[
      ...(isActive ? ['border-blue-cyan-500', 'dark:border-sapphire'] : ['border-gray-50', 'dark:border-dove']),
      ...(isScalable ? ['cursor-pointer', 'active:scale-95'] : []),
      sizes[size],
    ]"
    @click="onClick"
  >
    <slot />
  </div>
</template>
