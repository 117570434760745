import { ref } from "vue";

export const useSearch = () => {
  const isSearchActive = ref(false);
  const search = ref("");

  const cancelSearch = () => {
    changeSearch("");
    setIsSearchActive(false);
  };

  const changeSearch = (value: string) => {
    search.value = value;
  };

  const setIsSearchActive = (value: boolean) => {
    isSearchActive.value = value;
  };

  return {
    isSearchActive,
    search,
    cancelSearch,
    changeSearch,
    setIsSearchActive,
  };
};
