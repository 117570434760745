<script setup lang="ts">
import { useI18n } from "vue-i18n";

import type { WithdrawalConfirmation } from "@/entities/withdrawal";
import { Button, ConfirmationInput, Paragraph } from "@/shared/ui-v2";

type Emits = {
  changeCode: [value: string[]];
  changeCodeByIndex: [index: number, value: string];
  changeError: [value: boolean];
  resendCode: [];
};

interface Props {
  code: string[];
  confirmationData: WithdrawalConfirmation;
  count: number;
  error: {
    isExists: boolean;
    message: string;
  };
  formattedTime: string;
  isSubmitButtonVisible: boolean;
}

const emit = defineEmits<Emits>();

defineProps<Props>();

const { t } = useI18n();

const onChangeCode = (value: string[]) => emit("changeCode", value);
const onChangeCodeByIndex = (index: number, value: string) => emit("changeCodeByIndex", index, value);
const onChangeError = (value: boolean) => emit("changeError", value);
const onResendCode = () => emit("resendCode");
</script>

<template>
  <div :class="$style.root">
    <Paragraph color="secondary">
      {{ t("withdrawal.confirm.sendText", { email: confirmationData.email }) }}
    </Paragraph>
    <ConfirmationInput
      :count="count"
      :error="error"
      :value="code"
      @change="onChangeCode"
      @change-item-by-index="onChangeCodeByIndex"
      @focus="onChangeError(false)"
    />
    <div>
      <Button
        v-if="isSubmitButtonVisible"
        variant="plain"
        @click="onResendCode"
      >
        {{ t("withdrawal.confirm.buttonText") }}
      </Button>
      <Paragraph
        v-else
        color="tertiary"
      >
        {{ t("withdrawal.confirm.repeatText", { time: formattedTime }) }}
      </Paragraph>
    </div>
  </div>
</template>

<style module lang="postcss">
.root {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-16);
}
</style>
