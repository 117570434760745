<script setup lang="ts">
import { computed } from "vue";

import type { DecryptedCard, DepositCreateResponse, PeerToPeerBank } from "@/entities/peer-to-peer";
import {
  InvoiceFields,
  InvoiceHeader,
  InvoiceInternational,
  internationalTransferCountries,
} from "@/entities/peer-to-peer";
import type { User } from "@/entities/user";
import { Card, Divider } from "@/shared/ui-v2";

interface Props {
  bank?: PeerToPeerBank;
  decryptedCard?: DecryptedCard;
  hasConfirmationId: boolean;
  lang: User["lang"];
  response: DepositCreateResponse;
}

const props = defineProps<Props>();

const isInternationalTransfer = computed(() =>
  internationalTransferCountries.includes(props.response.card.country_code),
);
</script>

<template>
  <Card
    :class="$style.root"
    size="l"
  >
    <InvoiceHeader
      :bank="bank"
      :has-confirmation-id="hasConfirmationId"
      :is-international-transfer="isInternationalTransfer"
      :response="response"
    />
    <InvoiceFields
      :decrypted-card="decryptedCard"
      :has-confirmation-id="hasConfirmationId"
      :lang="lang"
      :response="response"
    />
    <template v-if="isInternationalTransfer">
      <Divider />
      <InvoiceInternational :response="response" />
    </template>
  </Card>
</template>

<style module lang="postcss">
.root {
  padding-bottom: var(--spacing-8);
}
</style>
