<script setup lang="ts">
import { storeToRefs } from "pinia";
import { ref } from "vue";
import { useI18n } from "vue-i18n";

import { useUserStore } from "@/entities/user";
import { copyToClipboard } from "@/shared/lib";
import { bridgeService } from "@/shared/services";
import { Button, IconBox, Tooltip } from "@/shared/ui-v2";

interface Props {
  address: string;
}

defineProps<Props>();

const { t } = useI18n();

const { isNativePlatform } = storeToRefs(useUserStore());

const isCopyTooltipVisible = ref(false);

const changeCopyTooltipVisibility = (status: boolean) => {
  isCopyTooltipVisible.value = status;
};

const handleCopy = (copiedMessage: string) => {
  copyToClipboard(copiedMessage);
  changeCopyTooltipVisibility(true);
};
</script>

<template>
  <div :class="$style.root">
    <Tooltip
      :class="$style.tooltip"
      :duration="3_000"
      :is-visible="isCopyTooltipVisible"
      placement="top-center"
      @close="changeCopyTooltipVisibility(false)"
    >
      <template #tooltip>
        {{ t("action.copy") }}
      </template>
      <template #trigger>
        <Button
          :class="$style.copyButton"
          color="neutral"
          :is-disabled="isCopyTooltipVisible"
          @click="handleCopy(address)"
        >
          <IconBox name="copy" />
          {{ t("button.copy") }}
        </Button>
      </template>
    </Tooltip>
    <Button
      v-if="isNativePlatform"
      :class="$style.shareButton"
      color="neutral"
      @click="bridgeService.notify({ sharedMessage: address, messageType: 'onShare' })"
    >
      <IconBox name="share" />
    </Button>
  </div>
</template>

<style module lang="postcss">
.root {
  display: flex;
  align-items: center;
  gap: var(--spacing-8);
  width: 100%;
}

.tooltip {
  flex-grow: 1;
}

.copyButton {
  flex-grow: 1;
}

.shareButton {
  padding: var(--spacing-12);
}
</style>
