<script setup lang="ts">
import { Button, Icon, Typography } from "@/shared/ui";

type Emits = {
  copy: [];
};

interface Props {
  hideCopyButton?: boolean;
}

interface Slots {
  default(props: object): unknown;
  "start-content"(props: object): unknown;
}

const emit = defineEmits<Emits>();

withDefaults(defineProps<Props>(), {
  hideCopyButton: false,
});

defineSlots<Slots>();

const onCopy = () => emit("copy");
</script>

<template>
  <div class="flex items-center justify-between gap-4 rounded-xl bg-gray-100 px-4.5 py-1.5 dark:bg-dove">
    <div class="flex items-center gap-3">
      <slot name="start-content" />
      <Typography
        class="break-all"
        size="lg"
      >
        <slot />
      </Typography>
    </div>
    <Button
      v-if="!hideCopyButton"
      variant="plain"
      @click="onCopy"
    >
      <Icon
        class="text-gray-350 dark:text-pearl"
        name="copy"
      />
    </Button>
  </div>
</template>
