<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, ref } from "vue";

import { transitions } from "@/shared/constants";
import { Caption, IconBox, List, Paragraph } from "@/shared/ui-v2";

type Emits = {
  change: [value: string | number];
};

interface Props {
  isDisabled?: boolean;
  items: {
    text: string;
    value: string | number;
  }[];
  label: string;
  validations?: {
    isExists: boolean;
    message: string;
    regex: string;
  }[];
  value: string | number;
}

interface Slots {
  item(props: { item: Props["items"][number] }): unknown;
}

interface Coords {
  left: string;
  top: string;
}

const emit = defineEmits<Emits>();

const props = withDefaults(defineProps<Props>(), {
  isDisabled: false,
  validations: () => [
    {
      isExists: false,
      message: "",
      regex: "",
    },
  ],
  value: "",
});

defineSlots<Slots>();

const coords = ref<Coords>({
  left: "0px",
  top: "0px",
});

const isOpen = ref(false);
const observer = ref<MutationObserver>();
const triggerRef = ref<HTMLButtonElement>();

const selectedItem = computed(() => props.items.find(({ value }) => value === props.value));
const hasError = computed(() => props.validations.some((validation) => validation.isExists));

const onChange = (value: string | number) => emit("change", value);

const changeCoords = (value: Coords) => {
  coords.value = value;
};

const changeOpenStatus = (value: boolean) => {
  isOpen.value = value;
};

const changeValue = (value: string | number) => {
  changeOpenStatus(false);
  onChange(value);
};

const updateCoords = () => {
  const rect = triggerRef.value?.getBoundingClientRect();

  if (!rect) {
    return;
  }

  changeCoords({
    top: `${rect.bottom + 8}px`,
    left: `${rect.left}px`,
  });
};

onBeforeUnmount(() => {
  observer.value?.disconnect();
});

onMounted(() => {
  if (!triggerRef.value) {
    return;
  }

  observer.value = new MutationObserver(updateCoords);
  observer.value.observe(triggerRef.value, { attributes: true, childList: true, subtree: true });
});
</script>

<template>
  <div :class="$style.root">
    <div :class="$style.wrapper">
      <button
        ref="triggerRef"
        v-click-outside="() => changeOpenStatus(false)"
        :class="[$style.trigger, hasError && $style.error]"
        :disabled="isDisabled"
        type="button"
        @click="changeOpenStatus(!isOpen)"
      >
        <Paragraph
          :class="$style.label"
          color="secondary"
        >
          {{ selectedItem?.text ?? label }}
        </Paragraph>
        <IconBox
          :class="[$style.chevronIcon, isOpen && $style.active]"
          color="secondary"
          name="chevron-down"
        />
      </button>
      <template
        v-for="validation of validations"
        :key="validation.message"
      >
        <Caption
          v-if="validation.isExists"
          color="negative"
        >
          {{ validation.message }}
        </Caption>
      </template>
    </div>
    <Teleport to="body">
      <Transition :name="transitions.positionTop">
        <List
          v-if="isOpen"
          :class="$style.list"
          :items="items"
          :style="coords"
          :value="value"
          @change="changeValue"
        >
          <template #item="{ item }">
            <slot
              :item="item"
              name="item"
            />
          </template>
        </List>
      </Transition>
    </Teleport>
  </div>
</template>

<style module lang="postcss">
.root {
  position: relative;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-4);
}

.trigger {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--spacing-12);
  height: var(--size-dropdown);
  padding: var(--spacing-12) var(--spacing-16);
  border-radius: var(--rounding-input);
  outline: transparent solid var(--spacing-2);
  transition: outline-color 0.25s;
  user-select: none;
  background-color: var(--color-control-neutral) !important;
  color: var(--color-fg-primary);
  -webkit-tap-highlight-color: transparent;

  &:active {
    opacity: var(--opacity-item-active);
  }

  &:disabled {
    opacity: var(--opacity-item-disabled);
  }

  &:focus-visible {
    outline: var(--color-border-focus) solid var(--spacing-2);
    outline-offset: var(--spacing-2);
  }

  &:hover:not(:disabled) {
    background-color: var(--color-control-neutral-hover) !important;
  }

  &.error {
    border: 1px solid var(--color-negative);
  }
}

.label {
  @add-mixin text-ellipsis;
}

.chevronIcon {
  transition: transform 0.25s;
  transform: rotate(0deg);

  &.active {
    transform: rotate(180deg);
  }
}

.list {
  position: fixed;
  z-index: 20;
  width: calc(100vw - var(--size-control-s));
  border-radius: var(--rounding-popover);
  overflow-y: auto;

  & > * {
    &:first-child {
      border-top-left-radius: var(--rounding-popover);
      border-top-right-radius: var(--rounding-popover);
    }

    &:last-child {
      border-bottom-left-radius: var(--rounding-popover);
      border-bottom-right-radius: var(--rounding-popover);
    }
  }
}
</style>
