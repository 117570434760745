<script setup lang="ts">
import { toRef } from "vue";

import { useMask } from "@/features/form";
import { Input } from "@/shared/ui-v2";

type Emits = {
  change: [value: string];
  clear: [];
  delete: [];
  focus: [];
  input: [event: Event];
  paste: [];
};

interface Props {
  hintMessage?: string;
  inputMode?: "text" | "numeric";
  isClearable?: boolean;
  isDisabled?: boolean;
  label?: string;
  mask: Nullable<string>;
  placeholder?: string;
  type?: "text" | "number";
  validations?: {
    isExists: boolean;
    message: string;
    regex: string;
  }[];
  value: string;
}

const emit = defineEmits<Emits>();

const props = withDefaults(defineProps<Props>(), {
  hintMessage: "",
  inputMode: "text",
  isClearable: false,
  isDisabled: false,
  label: "",
  mask: null,
  placeholder: "",
  type: "text",
  validations: () => [
    {
      isExists: false,
      message: "",
      regex: "",
    },
  ],
});

const { masked, changeMaskedValue } = useMask({
  mask: toRef(() => props.mask),
});

const onClear = () => emit("clear");
const onDelete = () => emit("delete");
const onFocus = () => emit("focus");
const onInput = (event: Event) => emit("input", event);
const onPaste = () => emit("paste");

const handleInput = (event: Event) => {
  const target = event.target as HTMLInputElement;

  if (props.mask && props.mask.length < target.value.length) {
    target.value = target.value.slice(0, target.value.length - 1);
  }

  changeMaskedValue(target.value);
  onInput({
    ...event,
    target: {
      ...event.target,
      value: masked.value?.unmaskedValue ?? target.value,
    },
  } as Event);
};
</script>

<template>
  <Input
    :hint-message="hintMessage"
    :input-mode="inputMode"
    :is-clearable="isClearable"
    :is-disabled="isDisabled"
    :label="label"
    :placeholder="placeholder"
    :type="type"
    :validations="validations"
    :value="masked?.value ?? value"
    @clear="onClear"
    @delete="onDelete"
    @focus="onFocus"
    @input="handleInput"
    @paste="onPaste"
  />
</template>
