import { storeToRefs } from "pinia";
import qs from "qs";

import type { Deposit } from "@/entities/deposit";
import { useUserStore } from "@/entities/user";
import type { Withdrawal } from "@/entities/withdrawal";
import { bridgeService } from "@/shared/services";

const buildQuery = (apiResponse: Deposit["apiResponse"] | Withdrawal["apiResponse"]) => {
  const url = new URL(apiResponse.source);
  const query = Object.keys(apiResponse.data).length > 0 ? `?${qs.stringify(apiResponse.data)}` : "";
  return `${url.origin}${url.pathname}${query}${url.hash}`;
};

const buildUrl = (apiResponse: Deposit["apiResponse"] | Withdrawal["apiResponse"]) => {
  const currentUrl = window.location.href;
  const path = "/form";

  const url = new URL(path, currentUrl);

  url.searchParams.append("action", apiResponse.source);
  url.searchParams.append("method", apiResponse.method);

  for (const [key, value] of Object.entries(apiResponse.data)) {
    url.searchParams.append(key, value);
  }

  return url.href;
};

export const openBrowser = (apiResponse: Deposit["apiResponse"] | Withdrawal["apiResponse"]) => {
  const { isNativePlatform } = storeToRefs(useUserStore());

  const url = apiResponse.method === "GET" ? buildQuery(apiResponse) : buildUrl(apiResponse);

  if (isNativePlatform.value) {
    bridgeService.notify({ messageType: "openExtBrowser", url });
    return;
  }

  window.open(url);
};
