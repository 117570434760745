<script setup lang="ts">
import { storeToRefs } from "pinia";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

import type { PaymentGroup } from "@/entities/payment-method";
import { circlePaymentIconsByGroup, defaultLimits, getPaymentIcon } from "@/entities/payment-method";
import { useThemeStore } from "@/entities/theme";
import type { Transaction } from "@/entities/transaction";
import { useUserStore } from "@/entities/user";
import { formatNumber } from "@/shared/lib";
import { Card, Icon, Typography } from "@/shared/ui";

type Emits = {
  select: [paymentGroup: PaymentGroup];
};

interface Props {
  isActive?: boolean;
  paymentGroup: PaymentGroup;
  transaction: Transaction;
}

const emit = defineEmits<Emits>();

const props = withDefaults(defineProps<Props>(), {
  isActive: false,
});

const { t } = useI18n();

const { country, user } = storeToRefs(useUserStore());

const { theme } = storeToRefs(useThemeStore());

const circleIcons = computed(() => circlePaymentIconsByGroup[props.paymentGroup.name] ?? []);

const onSelect = (paymentGroup: PaymentGroup) => emit("select", paymentGroup);

const onClick = () => {
  if (props.isActive) {
    return;
  }

  onSelect(props.paymentGroup);
};
</script>

<template>
  <Card
    class="highlight-none h-full select-none border-0.5 text-center"
    :is-active="isActive"
    is-scalable
    size="lg"
    @click="onClick"
  >
    <div
      v-if="circleIcons.length"
      class="mb-6 flex items-center justify-center"
    >
      <div
        v-for="(icon, index) of circleIcons"
        :key="icon"
        class="relative flex items-center justify-center rounded-full border-2 border-gray-50 dark:border-oxford [&:not(:first-child)]:-ml-2.5"
        :style="{
          zIndex: circleIcons.length - index,
        }"
      >
        <Icon
          class="h-7.5 w-7.5"
          :name="
            getPaymentIcon({
              country,
              name: icon,
              theme,
              transaction,
              type: 'circle',
            })
          "
        />
      </div>
      <div
        class="relative z-0 -ml-2.5 flex h-7.5 w-7.5 items-center justify-center rounded-full bg-gray-100 p-0.5 text-blue-cyan-500 dark:bg-dove dark:text-sapphire"
      >
        <Typography
          size="xs"
          weight="bold"
        >
          {{ paymentGroup.paymentMethods.length }}
        </Typography>
      </div>
    </div>
    <Icon
      v-else
      class="mb-6 h-7.5"
      :name="
        getPaymentIcon({
          country,
          name: paymentGroup.name,
          theme,
          transaction,
          type: 'base',
        })
      "
    />
    <Typography class="overflow-hidden text-ellipsis whitespace-nowrap">
      {{ paymentGroup.label }}
    </Typography>
    <Typography
      class="mt-1 break-all text-gray-350 dark:text-pearl"
      size="sm"
    >
      {{
        t("paymentMethod.upTo", {
          sum: formatNumber(
            { currency: user.currency },
            (paymentGroup.paymentMethods[0]?.amount_rules?.[user.currency] ?? defaultLimits).max,
          ),
        })
      }}
    </Typography>
  </Card>
</template>
