<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

import type { DepositCreateResponse, InternationalTransferBank } from "@/entities/peer-to-peer";
import { routeNamesV2 } from "@/shared/constants";
import { Button, Caption, IconBox, IconContainer } from "@/shared/ui-v2";

interface Props {
  response: DepositCreateResponse;
}

const props = defineProps<Props>();

const { t } = useI18n();
const router = useRouter();

const banks = computed<InternationalTransferBank[]>(() => ["sber", "tinkoff", "gazprom"]);
const countryCode = computed(() => (props.response.card?.country_code ?? "").toLowerCase());
</script>

<template>
  <div :class="$style.root">
    <div :class="$style.header">
      <IconBox
        :class="$style.icon"
        :name="`circle-countries/${countryCode}`"
        size="l"
        :space="false"
      />
      <Caption
        :class="$style.text"
        color="tertiary"
      >
        {{
          t("peerToPeer.invoice.international.instruction.title", {
            country: t(`peerToPeer.invoice.international.countriesByCode.${countryCode}`),
          })
        }}
      </Caption>
    </div>
    <div :class="$style.buttons">
      <Button
        v-for="bank of banks"
        :key="bank"
        :class="$style.button"
        color="neutral"
        @click="
          router.push({
            name: routeNamesV2.depositPeerToPeerInstructionPage,
            query: {
              bank,
            },
          })
        "
      >
        <IconContainer
          color="informative"
          :name="`banks/${bank}`"
          size="xs"
        />
      </Button>
    </div>
    <Caption
      v-sanitize-html="t('peerToPeer.invoice.international.instruction.text')"
      :class="$style.text"
      color="tertiary"
    />
    <Button
      color="neutral"
      size="m"
      @click="router.push({ name: routeNamesV2.depositListPage })"
    >
      {{ t("peerToPeer.invoice.international.instruction.button") }}
    </Button>
  </div>
</template>

<style module lang="postcss">
.root {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-8);
  padding-bottom: var(--spacing-8);
}

.header {
  display: flex;
  align-items: center;
  gap: var(--spacing-12);
  margin-top: var(--spacing-4);
}

.icon {
  margin: var(--spacing-0) var(--spacing-8);
}

.text {
  display: block;
  margin: var(--spacing-8) var(--spacing-0);
  text-align: start;
}

.buttons {
  display: flex;
  justify-content: space-between;
  gap: var(--spacing-8);
}

.button {
  flex-grow: 1;
}
</style>
